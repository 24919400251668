import {
	reclassificationStagesTemplatesRepository,
	ReclassificationStagesTemplatesRepository,
} from '../repositories/reclassification-stages-templates.repository'
import {BaseService} from './base.service'

export class ReclassificationStagesTemplateService extends BaseService<ReclassificationStagesTemplatesRepository> {
	readonly repository: ReclassificationStagesTemplatesRepository = reclassificationStagesTemplatesRepository
}

export const reclassificationStagesTemplateService = new ReclassificationStagesTemplateService()
