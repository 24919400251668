import {BaseController} from './base.controller'
import {CompanyService, companyService} from '../services/company.service'
import {QueryParams} from '../utils/base.query-params'

class CompanyQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`name=~"${q}"=si,description=~"${q}"=si`)
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `(${filterQuery.join(',')})` : null
	}
}

class CompanyController extends BaseController<CompanyService> {
	service = companyService
	query = new CompanyQueryParams()
}

export const companyController = new CompanyController()
