import {Datagrid, DeleteButton, FunctionField, ReferenceField, SimpleShowLayout, Tab, TextField} from 'react-admin'
import React from 'react'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {getFullName} from '../../utils/getters.utils'
import {ProjectEditButton} from '../buttons/ProjectEditButton'

export const FilterPresetsProjectTab = props => (
	<Tab {...props}>
		<SRReferenceManyField
			source="_id"
			reference="filter-presets"
			target="projects"
			sort={{field: 'name', order: 'ASC'}}
			addLabel={false}
			fullWidth
		>
			<SimpleShowLayout>
				<Datagrid>
					<TextField source="name" />
					<TextField source="scope" />
					<ReferenceField label={'Created by'} reference={'users'} source={'createdBy'}>
						<FunctionField render={getFullName} />
					</ReferenceField>
					<ProjectEditButton projectId={props.id} />
					<DeleteButton redirect={false} undoable={false} />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
