import React from 'react'

import {
	AutocompleteInput,
	BooleanInput,
	Create,
	FormDataConsumer,
	FormTab,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
} from 'react-admin'
import {Typography} from '@material-ui/core'
import {SRCreate} from '../SRComponents/SRCreate'

const forgeEngineChoices = [
	{id: 'Revit', name: 'Revit'},
	{id: 'Revit (v3)', name: 'Revit (v3)'},
	{id: 'Navisworks', name: 'Navisworks'},
]

const modelTypeChoices = [
	{id: 'IFC', name: 'IFC'},
	{id: 'Revit', name: 'Revit'},
	{id: 'Navisworks', name: 'Navisworks'},
]

const outputFormatChoices = [
	{id: 'svf', name: 'SVF'},
	{id: 'svf2', name: 'SVF2'},
]

export function ModelSetupJobCreate(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const modelType = new URLSearchParams(props.location.search).get('modelType')
	const redirect = () => `/projects/${projectId}/show/models`
	const transform = data => ({
		...data,
		projectId,
	})
	const initialValues = {
		config: {
			// Global settings
			outputFormat: 'svf',
			generateElementTypeTreeFromName: modelType === 'IFC',
			internalSvfParser: false,
			removeInvalidMeshes: false,
			mergeAssemblies: false,
			modelType,

			// Ifc settings
			forgeEngine: 'Revit',

			// Revit settings
			generateMasterViews: true,
			materialMode: 'auto',

			// Navisworks settings
			hiddenObjects: false,
			basicMaterialProperties: false,
			autodeskMaterialProperties: false,
			timelinerProperties: false,
			parseNwdMetadata: false,
		},
	}
	return (
		<SRCreate {...props} projectId={projectId} listTabName="models" showBreadcrumb transform={transform}>
			<TabbedForm redirect={redirect} initialValues={initialValues}>
				<FormTab label="main">
					<TextInput source="modelName" label="Model Name" validate={[required()]} />
					<SelectInput
						source="config.outputFormat"
						label="Translation Format"
						validate={[required()]}
						choices={outputFormatChoices}
					/>
					<SelectInput
						source="config.modelType"
						label="Model Type"
						validate={[required()]}
						choices={modelTypeChoices}
					/>
					<BooleanInput
						source="config.generateElementTypeTreeFromName"
						label="Generate element type tree from element name"
						validate={[required()]}
					/>
					<BooleanInput source="config.removeInvalidMeshes" label="Remove invalid meshes" validate={[required()]} />
					<BooleanInput source="config.mergeAssemblies" label="Merge assemblies" validate={[required()]} />
					<BooleanInput source="config.internalSvfParser" label="Use internal SVF parser" validate={[required()]} />
					<FormDataConsumer>
						{({formData}) =>
							formData.config?.modelType === 'IFC' && (
								<>
									<ReferenceInput
										label={`IFC file`}
										name="modelPath"
										source="modelPath"
										reference="project-files"
										filterToQuery={q => ({q, modelType: 'IFC', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<SelectInput
										source="config.forgeEngine"
										label="Forge Engine"
										validate={[required()]}
										choices={forgeEngineChoices}
									/>
								</>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({formData}) =>
							formData.config?.modelType === 'Revit' && (
								<>
									<ReferenceInput
										label={`Revit file`}
										name="modelPath"
										source="modelPath"
										reference="project-files"
										filterToQuery={q => ({q, modelType: 'Revit', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<BooleanInput label="Generate Master Views" source="config.generateMasterViews" />
									<SelectInput
										source="config.materialMode"
										label="Material Mode"
										validate={[required()]}
										choices={[
											{id: 'auto', name: 'auto'},
											{id: 'basic', name: 'basic'},
											{id: 'autodesk', name: 'autodesk'},
										]}
									/>
								</>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({formData}) =>
							formData.config?.modelType === 'Navisworks' && (
								<>
									<ReferenceInput
										label={`Navisworks file`}
										name="modelPath"
										source="modelPath"
										reference="project-files"
										filterToQuery={q => ({q, modelType: 'Navisworks', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<BooleanInput label="Parse NWD metadata" source="config.parseNwdMetadata" />
									<BooleanInput label="HiddenObjects" source="config.hiddenObjects" />
									<BooleanInput label="Basic Material Properties" source="config.basicMaterialProperties" />
									<BooleanInput label="Autodesk Material Properties" source="config.autodeskMaterialProperties" />
									<BooleanInput label="Timeline properties" source="config.timelinerProperties" />
								</>
							)
						}
					</FormDataConsumer>
					<Typography variant="subtitle1" component="div" gutterBottom>
						Translation coordinates (in mm)
					</Typography>
					<NumberInput source="coordinatesOffset.x" label="X" validate={[required()]} defaultValue="0" />
					<NumberInput source="coordinatesOffset.y" label="Y" validate={[required()]} defaultValue="0" />
					<NumberInput source="coordinatesOffset.z" label="Z" validate={[required()]} defaultValue="0" />
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
