import React, {useState} from 'react'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Tooltip from '@material-ui/core/Tooltip'
import {Button} from 'react-admin'
import {Typography} from '@material-ui/core'

export function CopyIdToClipboardButton(props) {
	const [status, setStatus] = useState<'idle' | 'copied'>('idle')
	const button = (
		<div style={{display: 'flex'}}>
			<Button
				style={{minWidth: '0px'}}
				startIcon={<FileCopyIcon />}
				disabled={status === 'copied'}
				onClick={() => {
					navigator.clipboard.writeText(props.record._id).then(() => {
						setStatus('copied')
						setTimeout(() => {
							setStatus('idle')
						}, 2000)
					})
				}}
			/>
			<Typography variant="subtitle1" component="div">
				ID
			</Typography>
		</div>
	)
	return (
		<Tooltip open={status === 'copied'} title={'Copied to clipboard!'} arrow placement="top">
			{button}
		</Tooltip>
	)
}
