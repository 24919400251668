import {BaseServiceByProject} from '../services/base.service.by-project'
import {QueryParams} from '../utils/base.query-params'

export abstract class BaseControllerByProject<S extends BaseServiceByProject<any>> {
	abstract readonly service: S

	readonly query = new QueryParams()

	get name() {
		return this.service.name
	}

	projectId(params?: {projectId?: string}) {
		const projectId =
			params?.projectId ||
			new URLSearchParams(document.location.search).get('projectId') ||
			BaseControllerByProject.getProjectIdFromPath()
		if (!projectId) throw new Error('projectId not informed as query param')
		return projectId
	}

	private static getProjectIdFromPath() {
		const matches = document.location.toString().match(/\/projects\/([a-z0-9]+)/)
		if (matches === null) return undefined
		return matches[1]
	}

	async getList(params: any) {
		if (params.enabled === false) return {data: [], total: 0}
		const {filter, sort, skip, limit} = this.query.build(params)
		return this.service.getList(this.projectId(params), filter, sort, skip, limit)
	}

	async getOne(params: any) {
		return this.service.getOne(this.projectId(), params.id)
	}

	async getManyReference(params: any) {
		const {target, id} = params
		const {filter, sort, skip, limit} = this.query.build(params)
		return this.service.getManyReference(target, id, filter, sort, skip, limit)
	}

	async getMany(params: any) {
		return this.service.getMany(this.projectId(), params.ids)
	}

	async create(params: any) {
		const {data} = params
		return await this.service.create(this.projectId(), data)
	}

	async update(params: any) {
		const {id, projectId, data} = params
		return await this.service.update(projectId || this.projectId(), id, data)
	}

	async updateMany(params: any) {
		const {ids, data} = params
		return await this.service.updateMany(this.projectId(), ids, data)
	}

	async delete(params: any) {
		const {id} = params
		return await this.service.delete(this.projectId(), id)
	}

	async deleteMany(params: any) {
		const {ids} = params
		return await this.service.deleteMany(this.projectId(), ids)
	}
}
