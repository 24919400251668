import {ScansRepository, scansRepository} from '../repositories/scans.repository'
import {BaseServiceByProject} from './base.service.by-project'

export class ScansService extends BaseServiceByProject<ScansRepository> {
	repository = scansRepository

	getInputCheckAssetSignedUrls = async (projectId: string, modelId: string, checkName: string) => {
		return this.repository.getInputCheckAssetSignedUrls(projectId, modelId, checkName)
	}
	validateInputCheckCheck = async (projectId: string, modelId: string, checkName: string) => {
		return this.repository.validateInputCheckCheck(projectId, modelId, checkName)
	}
	getScanXml = async (projectId: string, fileId: string) => {
		return this.repository.getScanXml(projectId, fileId)
	}
	getScanFilesSpecificMetadata = async (projectId: string, fileId: string) => {
		return this.repository.getScanFilesSpecificMetadata(projectId, fileId)
	}
}

export const scansService = new ScansService()
