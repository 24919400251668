import {BaseService, encodeCompany, encodeId} from './base.service'
import {workspaceRepository, WorkspaceRepository} from '../repositories/workspaces.repository'

export function encodeParent(data: any): any {
	return {
		...data,
		parent: data.ancestors[data.ancestors.length - 1],
	}
}
export class WorkspaceService extends BaseService<WorkspaceRepository> {
	repository = workspaceRepository

	encodeResult({data, ...rest}: any): any {
		return {data: encodeParent(encodeCompany(encodeId(data))), ...rest}
	}

	encodeResults({data, ...rest}: any): any {
		return {data: data.map(e => encodeParent(encodeCompany(encodeId(e)))), ...rest}
	}
}

export const workspaceService = new WorkspaceService()
