import {conn} from '../../../config/conn.config'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'
import {getResourceApi} from '../../resources_api/resources.apis'
import {ResourceApi} from '../../resources_api/ResourceApi'

export abstract class BaseRepositoryByProject {
	readonly conn = conn
	abstract name: ResourceName

	get api(): ResourceApi {
		return getResourceApi(this.name)
	}

	async getList(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: unknown[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId), {
				params: {filter, sort, skip, limit},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getOne(projectId: string, id: string): Promise<{data: unknown}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, id))
			return {
				data: response.data,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getOne: ${e.message}`)
		}
	}

	async getManyReference(
		target: string,
		id: string,
		filter: string | null,
		sort: string | null,
		skip: any,
		limit: any,
	): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(id), {params: {filter, sort, skip, limit}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getMany(projectId: string, ids: string[]): Promise<{data: unknown[]}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId), {params: {ids}})
			return {
				data: response.data.result,
			}
		} catch (e) {
			throwError(`Error while calling ${this.api.buildProjectUrl}.getMany: ${e.message}`)
		}
	}

	async create(projectId: string, data: any): Promise<{data: unknown}> {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId), data)
		return {
			data: response.data,
		}
	}

	async update(projectId: string, id: string, data: any): Promise<{data: unknown}> {
		const response = await this.conn.put(this.api.buildProjectUrl(projectId, id), data)
		return {
			data: response.data,
		}
	}

	async updateMany(projectId: string, ids: string[], data: any): Promise<{data: unknown[]}> {
		const response = await this.conn.put(this.api.buildProjectUrl(projectId), {ids, data})
		return {
			data: response.data,
		}
	}

	async delete(projectId: string, id: string): Promise<{data: unknown}> {
		const response = await this.conn.delete(this.api.buildProjectUrl(projectId, id))
		return {
			data: response.data,
		}
	}

	async deleteMany(projectId: string, ids: string[]): Promise<{data: unknown[]}> {
		await this.conn.delete(this.api.buildProjectUrl(projectId), {params: {ids}})
		return {data: ids}
	}
}
