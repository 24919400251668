import {ArrayField, Datagrid, SimpleShowLayout, TextField} from 'react-admin'
import {VersionsInAnalysisExpandShow} from '../projects/VersionsInAnalysisExpandShow'
import {AnalysisEditButton} from './AnalysisEditButton'
import React from 'react'

export function AnalysesExpand(props) {
	return (
		<SimpleShowLayout addLabel={false} {...props}>
			<ArrayField addLabel={false} source={'analyses'}>
				<Datagrid expand={<VersionsInAnalysisExpandShow projectId={props.projectId} />}>
					<TextField source="name" />
					<AnalysisEditButton projectId={props.projectId} />
				</Datagrid>
			</ArrayField>
		</SimpleShowLayout>
	)
}
