import React from 'react'
import {addField} from 'react-admin'
import {TextField} from '@material-ui/core'
import {useField} from 'react-final-form'

const JEditor = ({input: {name}, label, rows, fullWidth}: any) => {
	const {
		input: {onChange, value, onBlur},
		meta: {touched, error},
	} = useField(name)
	return (
		<TextField
			multiline
			rows={rows || 4}
			fullWidth={fullWidth}
			value={typeof value === 'object' ? JSON.stringify(value) : value}
			label={label}
			name={name}
			onChange={onChange}
			error={!!(touched && error)}
			onBlur={onBlur}
			helperText={touched && error}
		/>
	)
}

export default addField(JEditor)
