import React from 'react'
import {Datagrid, DateField, DeleteButton, EditButton, ReferenceField, ShowButton, TextField} from 'react-admin'
import WorkspaceFilters from './WorkspaceFilters'
import {SRList} from '../SRComponents/SRList'

export function WorkspaceList(props: any) {
	return (
		<SRList {...props} filters={<WorkspaceFilters />} sort={{field: 'name', order: 'ASC'}} bulkActionButtons={false}>
			<Datagrid>
				<TextField source="name" />
				<ReferenceField source="company" reference="companies" link="show">
					<TextField source="name" />
				</ReferenceField>
				<TextField source="description" />
				<DateField source="createdDate" />
				<ShowButton />
				<EditButton />
				<DeleteButton redirect={false} undoable={false} />
			</Datagrid>
		</SRList>
	)
}
