import {FILE_ADD, FILE_REMOVE, FILE_UPDATE} from '../actions/file.actions'

export function fileReducer(previousState: any[] = [], {type, payload}) {
	if (type === FILE_ADD) {
		return [...previousState, payload]
	} else if (type === FILE_REMOVE) {
		return previousState.filter(el => el._id !== payload._id)
	} else if (type === FILE_UPDATE) {
		return previousState.map(e => {
			if (e._id === payload._id) return payload
			return e
		})
	}
	return previousState
}
