import {BaseController} from './base.controller'
import {teamService, TeamService} from '../services/team.service'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

class TeamController extends BaseController<TeamService> {
	service = teamService
	query = new ByFreeTextKeyQueryParams()
}

export const teamController = new TeamController()
