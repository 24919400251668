import {Datagrid, Filter, SearchInput, SimpleShowLayout, Tab, TextField} from 'react-admin'
import React from 'react'
import {SRReferenceManyField} from '../../pagination/SRReferenceManyField'

export const WatchlistsProjectTab = props => (
	<Tab {...props}>
		<SRReferenceManyField
			source="_id"
			reference="watchlists"
			target="projects"
			sort={{field: 'name', order: 'ASC'}}
			addLabel={false}
			fullWidth
		>
			<SimpleShowLayout>
				<Filter>
					<SearchInput source="q" alwaysOn />
				</Filter>
				<Datagrid>
					<TextField source="name" />
					<TextField source="startDate" />
					<TextField source="endDate" />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
