import {ResourceName} from '../../config/resources.config'
import {ResourceApi} from './ResourceApi'

const resourceApis: Record<ResourceName, ResourceApi> = {
	'filter-presets': new ResourceApi('filter-presets/admin', 'Filter Preset'),
	reclassifications: new ResourceApi('reclassification/reclassification-tasks', 'Reclassification'),
	'reclassification-stages-templates': new ResourceApi(
		'reclassification/reclassification-stages-templates',
		'Reclassification Stages Template',
	),
	companies: new ResourceApi('companies', 'Companies'),
	users: new ResourceApi('users', 'Users'),
	projects: new ResourceApi('projects', 'Project'),
	analyses: new ResourceApi('analyses', 'Analysis'),
	versions: new ResourceApi('versions', 'Version'),
	watchlists: new ResourceApi('watchlists', 'Watchlist'),
	'progress-monitorings': new ResourceApi('progress-monitorings', 'Progress Monitoring'),
	models: new ResourceApi('models', 'Model'),
	'model-upload-jobs': new ResourceApi('model-upload-jobs', 'Model Upload Job'),
	'user-roles': new ResourceApi('user-roles', 'User Role'),
	teams: new ResourceApi('teams', 'Team'),
	'team-members': new ResourceApi('team-members', 'Team member'),
	workspaces: new ResourceApi('workspaces', 'Workspace'),
	auths: new ResourceApi('auths', 'Auth'),
	'by-tenant': new ResourceApi('by-tenant', 'By Tenant'),

	// By domain APIs
	rois: new ResourceApi('rois', 'ROI', 'analysis'),
	scans: new ResourceApi('scans', 'Scan', 'analysis'),
	'model-setup-jobs': new ResourceApi('model-setup-jobs', 'Model Setup Job', 'model'),
	'analysis-processing-jobs': new ResourceApi('analysis-processing-jobs', 'Analysis Processing Job', 'analysis'),
	'analysis-processing-jobs-simple': new ResourceApi('analysis-processing-jobs', 'Analysis Processing Job', 'analysis'),
	'changelogs-phase-1': new ResourceApi('changelogs/phase-1', 'Migrations Phase 1', 'migration'),
	'changelogs-phase-2': new ResourceApi('changelogs/phase-2', 'Migrations Phase 2', 'migration'),
	'changelogs-phase-3': new ResourceApi('changelogs/phase-3', 'Migrations Phase 3', 'migration'),
	'pending-changelogs-phase-1': new ResourceApi('pending/changelogs/phase-1', 'Migrations Phase 1 Pending'),
	'pending-changelogs-phase-2': new ResourceApi('pending/changelogs/phase-2', 'Migrations Phase 2 Pending'),
	'pending-changelogs-phase-3': new ResourceApi('pending/changelogs/phase-3', 'Migrations Phase 3 Pending'),
	'analysis-view': new ResourceApi('analysis-view', 'Analysis', 'analysis'),
	'project-files': new ResourceApi('files', 'Files', 'files'),
	files: new ResourceApi('files', 'File', 'files'),
	masvs: new ResourceApi('masv', 'Masv File', 'masv'),
	'element-types': new ResourceApi('elements/by-model', 'Element Type', 'element'),
	features: new ResourceApi('features', 'Feature', 'features'),
	suggestions: new ResourceApi('suggestions', 'Suggestions', 'eoi'),
}

export const getResourceApi = (resource: ResourceName): ResourceApi => resourceApis[resource]
