import {BaseController} from './base.controller'
import {userService, UserService} from '../services/user.service'
import {QueryParams} from '../utils/base.query-params'

class UserQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`firstName=~"${q}"=si,lastName=~"${q}"=si,email=~"${q}"=si`)
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `(${filterQuery.join(',')})` : null
	}
}

class UserController extends BaseController<UserService> {
	service = userService
	query = new UserQueryParams()

	async getList(params: any): Promise<{data: any[]; total: number}> {
		if (params.filter.company) {
			const {company, ...newFilter} = params.filter
			const query = this.query.build({...params, filter: newFilter})
			return this.service.getManyReference('companies', params.filter.company, query)
		}
		if (params.filter.project) {
			const {project, ...newFilter} = params.filter
			const query = this.query.build({...params, filter: newFilter})
			return this.service.getManyReference('projects', params.filter.company, query)
		}

		return super.getList(params)
	}
}

export const userController = new UserController()
