import React from 'react'
import {Edit, FormTab, maxLength, minLength, required, TabbedForm, TextInput, useRedirect} from 'react-admin'
import {SREdit} from '../SRComponents/SREdit'
import {usePreserveQuerystring} from '../../hooks/UsePreserveQuerystring'

const validateName = [
	required('You must set a name for the analysis!'),
	minLength(1, 'Analysis name must be at least one character long'),
	maxLength(50, 'Analysis name must maximum 50 characters long'),
]

export function AnalysisEdit(props: any) {
	const searchParams = usePreserveQuerystring('projectId')
	const projectId = searchParams.get('projectId')
	const redirect = useRedirect()
	const onSuccess = () => redirect(false)
	return (
		<SREdit
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			listTabName="analyses"
			showBreadcrumb
			mutationMode="pessimistic"
			undoable={false}
			onSuccess={onSuccess}
		>
			<TabbedForm>
				<FormTab label="main">
					<TextInput source="name" validate={validateName} multiline />
				</FormTab>
			</TabbedForm>
		</SREdit>
	)
}
