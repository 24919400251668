import {BaseService} from './base.service'
import {ChangelogRepository} from '../repositories/changelog.repository'

export class ChangelogService extends BaseService<ChangelogRepository> {
	constructor(public repository: ChangelogRepository) {
		super()
	}

	async getPending() {
		return this.encodeResults(await this.repository.getPending())
	}

	async migrateUp() {
		return this.repository.migrateUp()
	}
}
