import {BaseRepositoryByProject} from './base.repositories.by-project'
import {ResourceName} from '../../../config/resources.config'

export class ModelUploadJobRepository extends BaseRepositoryByProject {
	name: ResourceName = 'model-upload-jobs'

	async startModelUploadJobByProjectId(projectId: string, id: string): Promise<{data: any}> {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, id, 'start'))
		return {
			data: response.data,
		}
	}

	async cleanUpModelUploadJobByProjectId(projectId: string, id: string): Promise<{data: any}> {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, id, 'clean-up'))
		return {
			data: response.data,
		}
	}

	async createFromModel(
		projectId: string,
		data: {modelName: string; modelId: string; shallowCopy: boolean},
	): Promise<{data: any}> {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, undefined, 'fromModel'), data)
		return {
			data: response.data,
		}
	}
}

export const modelUploadJobRepository = new ModelUploadJobRepository()
