import {AuthProvider} from 'react-admin'
import {Auth, CognitoUser} from '@aws-amplify/auth'
import {amplifyConfig} from '../config'

const cognitoConfig = {
	aws_project_region: amplifyConfig.aws_project_region,
	aws_cognito_identity_pool_id: amplifyConfig.aws_user_pools_id,
	aws_cognito_region: amplifyConfig.aws_cognito_region,
	aws_user_pools_id: amplifyConfig.aws_user_pools_id,
	aws_user_pools_web_client_id: amplifyConfig.aws_user_pools_web_client_id,
}

Auth.configure(cognitoConfig)

export default {
	login: async ({username, password}: {username: string; password: string}): Promise<CognitoUser> => {
		const r = await Auth.signIn(username, password)
		console.log('XXXX sign', r, await Auth.currentAuthenticatedUser())
		return r
	},
	logout: () => Auth.signOut(),
	checkError: ({status}) => {
		if (status === 401 || status === 403) {
			return Promise.reject()
		}
		return Promise.resolve()
	},
	checkAuth: () => Auth.currentAuthenticatedUser(),
	getPermissions: () => Promise.resolve(),
	completeNewPassword: ({user, password, requiredAttributes}: {user: any; password: any; requiredAttributes: any[]}) =>
		Auth.completeNewPassword(user, password, requiredAttributes),
} as AuthProvider & {
	completeNewPassword: (params: {user: any; password: string; requiredAttributes: any[]}) => Promise<void>
}
