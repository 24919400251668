import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'
import {AnalysisViewService, analysisViewService} from '../services/analysis-view.service'
import {BaseControllerByProject} from './base.controller.by-project'

// TODO validate file contents
async function readMetadataAsJson(file): Promise<object[]> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsText(file.rawFile)
		reader.onload = () => resolve(JSON.parse(reader.result!.toString()))
		reader.onerror = reject
	})
}

class AnalysisViewController extends BaseControllerByProject<AnalysisViewService> {
	service = analysisViewService
	query = new ByFreeTextKeyQueryParams()

	async create(formParams: any): Promise<{data: any}> {
		const metadata = formParams.data.metadata ? await readMetadataAsJson(formParams.data.metadata) : null
		const createDTO = {
			data: {
				percentage: formParams.data.percentage / 100.0,
				modelId: formParams.data.modelId,
				metadata,
			},
			projectId: formParams.data.projectId,
		}
		return this.service.createFakeAnalysis(createDTO)
	}
}

export const analysisViewController = new AnalysisViewController()
