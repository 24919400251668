import {DateField, ReferenceField, SimpleShowLayout, Tab, TextField} from 'react-admin'
import React from 'react'

export const MainProjectTab = props => (
	<Tab {...props}>
		<SimpleShowLayout>
			<TextField source="_id" label="Project ID" />
			<TextField source="name" />
			<TextField source="tenantId" label="Tenant ID" />
			<ReferenceField source="company" reference="companies" link="show">
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField source="parent" reference="workspaces" link="show">
				<TextField source="name" />
			</ReferenceField>
			<TextField source="modelType" />
			<DateField source="createdDate" />
		</SimpleShowLayout>
	</Tab>
)
