import React from 'react'
import {BulkDeleteButton, Datagrid, DateField, DeleteButton, EditButton, ShowButton, TextField} from 'react-admin'
import UsersFilters from './UsersFilters'
import {SRList} from '../SRComponents/SRList'

export function UsersList(props: any) {
	return (
		<SRList
			{...props}
			filters={<UsersFilters />}
			sort={{field: 'email', order: 'ASC'}}
			bulkActionButtons={<BulkDeleteButton undoable={false} />}
		>
			<Datagrid>
				<TextField source="email" />
				<TextField source="username" />
				<DateField source="createdDate" />
				<ShowButton />
				<EditButton />
				<DeleteButton undoable={false} />
			</Datagrid>
		</SRList>
	)
}
