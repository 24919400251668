import {BaseServiceByProject} from './base.service.by-project'
import {ElementTypesRepository, elementTypesRepository} from '../repositories/element-types.repository'

export class ElementTypesService extends BaseServiceByProject<ElementTypesRepository> {
	repository = elementTypesRepository

	public async getElementTypes(projectId: string, modelId: string): Promise<string[][]> {
		return this.repository.getElementTypes(projectId, modelId)
	}
}

export const elementTypesService = new ElementTypesService()
