import {useSelector} from 'react-redux'
import Box from '@material-ui/core/Box'
import {MenuItemLink} from 'react-admin'
import BusinessIcon from '@material-ui/icons/Business'
import BuildIcon from '@material-ui/icons/Build'
import PersonIcon from '@material-ui/icons/Person'
import GroupIcon from '@material-ui/icons/Group'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import {Typography} from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import React from 'react'

export const CustomMenu = ({onMenuClick}) => {
	const open = useSelector<any, boolean>(state => state.admin.ui.sidebarOpen)
	return (
		<Box pt="20px">
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/companies"
				primaryText="Companies"
				leftIcon={<BusinessIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/projects"
				primaryText="Projects"
				leftIcon={<BuildIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/users"
				primaryText="Users"
				leftIcon={<PersonIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/teams"
				primaryText="Teams"
				leftIcon={<GroupIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/workspaces"
				primaryText="Workspaces"
				leftIcon={<FolderSharedIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/files"
				primaryText="Files"
				leftIcon={<FileIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/masvs"
				primaryText="MASV Files"
				leftIcon={<FileIcon />}
			/>
			<MenuItemLink
				onClick={onMenuClick}
				sidebarIsOpen={open}
				to="/analysis-processing-jobs-simple"
				primaryText="Jobs"
				leftIcon={<AssignmentIcon />}
			/>
			{open && (
				<Box pl="16px">
					<Accordion>
						<AccordionSummary>
							<Typography color="textSecondary">Migrations</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box>
								{['phase-1', 'phase-2', 'phase-3'].map(phase => (
									<>
										<MenuItemLink
											onClick={onMenuClick}
											sidebarIsOpen={open}
											to={`changelogs-${phase}`}
											primaryText={`Applied ${phase}`}
										/>
										<MenuItemLink
											onClick={onMenuClick}
											sidebarIsOpen={open}
											to={`pending-changelogs-${phase}`}
											primaryText={`Pending ${phase}`}
										/>
									</>
								))}
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
			)}
		</Box>
	)
}
