import React from 'react'
import {Edit, maxLength, minLength, required, TextInput, TabbedForm, FormTab} from 'react-admin'

const validateName = [
	required('You must set a name for the team!'),
	minLength(1, 'Team name must be at least one character long'),
	maxLength(50, 'Team name must maximum 50 characters long'),
]

export function TeamEdit(props: any) {
	return (
		<Edit {...props} undoable={false}>
			<TabbedForm>
				<FormTab label="main">
					<TextInput source="name" validate={validateName} multiline />
					<TextInput source="description" multiline />
				</FormTab>
			</TabbedForm>
		</Edit>
	)
}
