import React, {cloneElement} from 'react'
import {Button, ExportButton, TopToolbar, useRedirect} from 'react-admin'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft'

export const MasvListActions = props => {
	const redirect = useRedirect()
	const projectId = props.displayedFilters.projectId
	const onCLick = () => {
		redirect(`/projects/${projectId}/show/files`)
	}
	return (
		<TopToolbar>
			{cloneElement(props.filters, {context: 'button'})}
			<ExportButton />
			{projectId && (
				<Button onClick={onCLick} label="Back to project">
					<ArrowBackIcon />
				</Button>
			)}
		</TopToolbar>
	)
}
