import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'
import {BaseController} from './base.controller'
import {
	analysisProcessingJobSimpleService,
	AnalysisProcessingJobSimpleService,
} from '../services/analysis-processing-job.simple.service'

// TODO validate file contents
async function readMetadataAsJson(file): Promise<object[]> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsText(file.rawFile)
		reader.onload = () => resolve(JSON.parse(reader.result!.toString()))
		reader.onerror = reject
	})
}

class AnalysisProcessingJobSimpleController extends BaseController<AnalysisProcessingJobSimpleService> {
	service = analysisProcessingJobSimpleService
	query = new ByFreeTextKeyQueryParams()
}

export const analysisProcessingJobSimpleController = new AnalysisProcessingJobSimpleController()
