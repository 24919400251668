import React from 'react'
import {config} from '../../config'

export function LinkToAnalysis(props) {
	if (!props.record) {
		return null
	}
	const [companySlug, projectSlug] = props.record.tenantId.split('|')
	const analysisId = encodeURIComponent(props.record._id)
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			title="Open in viewer in a new tab"
			href={`${config.sr.reactViewerUrl}/${companySlug}-${projectSlug}/${analysisId}`}
		>
			{props.record.name}
		</a>
	)
}

export function LinkToAnalysisVersion(props) {
	if (!props.record) {
		return null
	}
	const [companySlug, projectSlug] = props.record.tenantId.split('|')
	const analysisViewId = encodeURIComponent(props.record.analysisView)
	const analysisId = encodeURIComponent(props.analysisId)
	const versionNumber = props.record.versionNumber
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			title="Open in viewer in a new tab"
			href={`${config.sr.reactViewerUrl}/${companySlug}-${projectSlug}/${analysisViewId}?${analysisId}=${versionNumber}`}
		>
			{versionNumber}
		</a>
	)
}
