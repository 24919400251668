import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import {Button} from 'react-admin'
import DialogActions from '@material-ui/core/DialogActions'
import {modelService} from '../../data_provider/v3/services/model.service'
import {scansService} from '../../data_provider/v3/services/scans.service'

export const customStyles = makeStyles(
	() => ({
		title: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			fontWeight: 'bold',
		},
		modalContent: {
			fontSize: '16px',
			lineHeight: '1.5',
			letterSpacing: '0.1px',
			paddingBottom: '16px',
		},
		actions: {
			display: 'flex',
			justifyContent: 'end',
			padding: '16px',
		},
		confirmButton: {
			width: '141px',
			height: '36px',
			padding: '0 12px',
			borderRadius: '4px',
			backgroundColor: '#6b717b',
			color: '#fff',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#6b717b',
				color: '#fff',
			},
		},
	}),
	{name: 'CheckAssetsModal'},
)

const CheckAssetsModal = ({showDialog, onClose, props}) => {
	const classes = customStyles()
	const type = props.data.basePath
	const service = type === '/models' ? modelService : scansService
	return (
		<Dialog fullWidth open={showDialog} onClose={onClose}>
			{props.data && (
				<>
					<DialogTitle>
						<div className={classes.title}>
							Check Information
							<IconButton onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</div>
					</DialogTitle>
					<DialogContent>
						<div className={classes.modalContent}>
							<div>{props.data.record.result.message}</div>
						</div>
					</DialogContent>
					{props.data.record.result.assets.length ? (
						<DialogActions className={classes.actions}>
							<Button
								className={classes.confirmButton}
								onClick={async () => {
									const response = await service.getInputCheckAssetSignedUrls(
										props.data.projectId,
										props.data.data.id,
										props.data.record.name,
									)
									response.forEach(signedUrlDTO => {
										window.open(signedUrlDTO.signedUrl)
									})
									onClose()
								}}
								label="Download"
								title="Download"
							></Button>
						</DialogActions>
					) : null}
				</>
			)}
		</Dialog>
	)
}
export default CheckAssetsModal
