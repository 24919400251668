import {useHistory, useLocation} from 'react-router-dom'
import {useState} from 'react'

export const usePreserveQuerystring = (...preservedParams: string[]) => {
	const history = useHistory()
	const location = useLocation()
	const [lastValidQs, setLastValidQs] = useState<URLSearchParams>(new URLSearchParams(location.search))
	const currentQs = new URLSearchParams(location.search)
	preservedParams.forEach(p => {
		!currentQs.has(p) && lastValidQs.get(p) && currentQs.set(p, lastValidQs.get(p)!)
	})
	currentQs.toString() !== lastValidQs.toString() && setLastValidQs(currentQs)
	new URLSearchParams(location.search).toString() !== currentQs.toString() &&
		history.replace({pathname: location.pathname, search: currentQs.toString()})
	return currentQs
}
