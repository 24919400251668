import React from 'react'
import {Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput} from 'react-admin'

import {required, maxLength} from 'react-admin'
import {SREdit} from '../SRComponents/SREdit'

const validateName = [required('Company name cannot be empty!')]
const validateDescription = [maxLength(500, 'Description must be less than 500 characters')]

export function CompanyEdit(props: any) {
	return (
		<SREdit {...props} mutationMode="pessimistic" undoable={false}>
			<SimpleForm>
				<TextInput source="name" validate={validateName} />
				<TextInput source="description" validate={validateDescription} />
				<ReferenceInput name="owner" source="owner" reference="users" label="Owner">
					<AutocompleteInput optionText="email" multiline />
				</ReferenceInput>
			</SimpleForm>
		</SREdit>
	)
}
