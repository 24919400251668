import React from 'react'
import {useSafeSetState} from 'ra-core'
import {handleLoginFlow} from './handleLoginFlow'
import {handleCompleteNewPasswordFlow} from './handleCompleteNewPasswordFlow'
import {CognitoUser} from '@aws-amplify/auth'
//@ts-ignore
import {Login} from 'ra-ui-materialui'
import LoginForm from './LoginForm'
import ChangePasswordForm from './ChangePasswordForm'

export type AuthState = {
	flow: 'LOGIN' | 'PWD_RST'
	cognitoUser: CognitoUser | null
}

export function CognitoLogin(props: any) {
	const authState = useSafeSetState<AuthState>({flow: 'LOGIN', cognitoUser: null})
	const [{flow}] = authState
	const login = handleLoginFlow(authState)
	const changePassword = handleCompleteNewPasswordFlow(authState)

	return (
		<Login {...props}>
			{flow === 'LOGIN' && <LoginForm {...props} onSubmit={login} />}
			{flow === 'PWD_RST' && <ChangePasswordForm {...props} onSubmit={changePassword} />}
		</Login>
	)
}
