import {Button} from 'react-admin'
import React, {useState} from 'react'
import {AnalysisViewImportAnalysisModal} from './AnalysisViewImportAnalysisModal'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'

export function AnalysisViewImportAnalysisButton({projectId, disabled}: any) {
	const [modalOpen, setModalOpen] = useState(false)
	return (
		<>
			<Button
				title={'Import Analysis'}
				label="Import Analysis"
				disabled={disabled}
				onClick={() => setModalOpen(true)}
				style={{margin: 'auto 0'}}
			>
				<SystemUpdateAltIcon />
			</Button>
			{!modalOpen ? null : (
				<AnalysisViewImportAnalysisModal
					showDialog={modalOpen}
					onClose={() => setModalOpen(false)}
					projectId={projectId}
				/>
			)}
		</>
	)
}
