import React from 'react'
import {
	ArrayInput,
	AutocompleteArrayInput,
	AutocompleteInput,
	Create,
	email,
	FormTab,
	maxLength,
	minLength,
	ReferenceInput,
	required,
	SimpleFormIterator,
	TabbedForm,
	TextInput,
	regex,
} from 'react-admin'
import createDecorator from 'final-form-calculate'
import slugify from 'slugify'

const validateEmail = [required('You must give an email'), email('Please input a valid email')]

const validateFirstAndLastName = [
	required("You must fillout user's first and last names"),
	minLength(1, 'Name must be at least one character'),
	maxLength(50, 'Name must be maximum characters'),
]

const validateUsername = [
	required('You must set a Username'),
	minLength(1, 'Username must be at least one character'),
	maxLength(25, 'Username must be maximum characters'),
]

function getUsername(formValues: any): string {
	let name = ''
	if (formValues.firstName && formValues.lastName) {
		name = formValues.firstName + ' ' + formValues.lastName
	} else if (formValues.firstName) {
		name = formValues.firstName
	} else if (formValues.lastName) {
		name = formValues.lastName
	} else {
		name = (formValues.email || '').replace('@', '-')
	}
	return slugify(name, {lower: true})
}

const calculateFieldsOnChange = createDecorator(
	{
		field: 'firstName',
		updates: {
			username: async (userNameValue: string, formValues: any) => {
				return getUsername(formValues)
			},
		},
	},
	{
		field: 'lastName',
		updates: {
			username: async (userNameValue: string, formValues: any) => {
				return getUsername(formValues)
			},
		},
	},
)

export function UserCreate(props: any) {
	const companyId = new URLSearchParams(props.location.search).get('companyId') || null
	const companies = companyId ? [{company: companyId, role: 'READER'}] : []
	const initialValues = {
		company: companyId,
		roles: {root: ['REGISTERED_USER'], companies, projects: []},
	}
	return (
		<Create {...props}>
			<TabbedForm redirect="show" initialValues={initialValues} decorators={[calculateFieldsOnChange]}>
				<FormTab label="main">
					<TextInput source="email" validate={validateEmail} />
					<TextInput name="username" source="username" validate={validateUsername} />
					<TextInput source="firstName" validate={validateFirstAndLastName} />
					<TextInput source="lastName" validate={validateFirstAndLastName} />
				</FormTab>
				<FormTab label="details">
					<TextInput source="cognitoUsername" />
				</FormTab>
			</TabbedForm>
		</Create>
	)
}
