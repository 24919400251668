import {BaseRepository} from './base.repository'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'

export class UserRoleRepository extends BaseRepository {
	name: ResourceName = 'user-roles'

	async getByCompanyIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		try {
			const response = await this.conn.get(this.api.buildCompanyUrl(companyId), {params: {filter, sort, skip, limit}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getByProjectIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		try {
			const response = await this.conn.get(this.api.buildCompanyUrl(companyId), {params: {filter, sort, skip, limit}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getByUserIdWithQuery(companyId: string, filter: string | null, sort: string | null, skip: number, limit: any) {
		try {
			const response = await this.conn.get(this.api.buildCompanyUrl(companyId), {params: {filter, sort, skip, limit}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}
}

export const userRoleRepository = new UserRoleRepository()
