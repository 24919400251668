import {put, call, takeEvery} from 'redux-saga/effects'
import {FILE_REMOVE} from '../actions/file.actions'
import {projectFileService} from '../data_provider/v3/services/files.service'
import {tenantIdToProjectId} from '../data_provider/v3/utils/tenant-projectId'
import {fileUploaderQueueService} from '../components/files/file-uploader.queue.service'

export default function* fileSaga() {
	yield takeEvery(FILE_REMOVE, function* (action: any) {
		const {_id, tenantId} = action.payload
		fileUploaderQueueService.cancelFile(_id)
		const projectId = yield call(tenantIdToProjectId, tenantId)
		if (action.payload.status !== 'uploaded') {
			yield call(projectFileService.delete.bind(projectFileService), projectId, _id)
		}
	})
}
