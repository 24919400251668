import React from 'react'
import {Admin, Title} from 'react-admin'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import BuildIcon from '@material-ui/icons/Build'
import GroupIcon from '@material-ui/icons/Group'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import FileIcon from '@material-ui/icons/InsertDriveFile'

import {Cognito} from './auth_provider'
import {CognitoLogin} from './components/cognitoLogin'
import i18nProvider from './i18nProvider'

import {CompaniesList, CompanyCreate, CompanyEdit, CompanyShow} from './components/companies'

import {UserCreate, UserEdit, UserShow, UsersList} from './components/users'

import {ProjectCreate, ProjectEdit, ProjectShow, ProjectsList} from './components/projects'

import {FilesCreate, FilesList, FilesShow} from './components/files'

import {ProjectFileCreate} from './components/project-files'
import SRDataProvider from './data_provider/SRDataProvider'
import {CustomLayout} from './theme/CustomLayout'
import {FileNotification} from './components/file-notification/FileNotification'
import {fileReducer} from './reducers/file.reducer'
import fileSaga from './sagas/file.saga'
import {ScansCreate} from './components/scans/ScansCreate'
import {AnalysisProcessingJobCreate} from './components/analysis-processing-jobs/AnalysisProcessingJobCreate'
import {CreateAnalysis} from './components/analysis/CreateAnalysis'
import {ShowReclassification} from './components/reclassifications/ShowReclassification'
import {UpdateReclassification} from './components/reclassifications/UpdateReclassification'
import {AuthEdit} from './components/auths/AuthEdit'
import {AuthCreate} from './components/auths/AuthCreate'
import {TeamCreate, TeamEdit, TeamList, TeamShow} from './components/teams'
import {TeamMemberCreate} from './components/teams/TeamMemberCreate'
import {TeamMemberEdit} from './components/teams/TeamMemberEdit'
import {WorkspaceCreate, WorkspaceEdit, WorkspaceList, WorkspaceShow} from './components/workspaces'
import {FilterPresetEdit} from './components/filter-presets/FilterPresetEdit'
import {ModelUploadJobEdit} from './components/model-upload-jobs/ModelUploadJobEdit'
import {ModelUploadJobShow} from './components/model-upload-jobs/ModelUploadJobShow'
import {ScansEdit} from './components/scans/ScansEdit'
import {ROICreate, ROIEdit, ROIShow} from './components/rois'
import {ScansShow} from './components/scans/ScansShow'
import {AnalysisProcessingJobsShow} from './components/analysis-processing-jobs/AnalysisProcessingJobsShow'
import {createMuiTheme} from '@material-ui/core/styles'
import {AnalysesProcessingJobSimpleList} from './components/analysis-processing-job-simple/AnalysesProcessingJobSimpleList'
import {AnalysisEdit} from './components/analysis/AnalysisEdit'
import {AnalysisViewShow} from './components/analysis-view/AnalysisViewShow'
import {ModelsEdit} from './components/models/ModelsEdit'
import {ModelsShow} from './components/models/ModelsShow'
import {MasvList, MasvShow} from './components/masv'
import {history} from './history'
import {AnalysisViewEdit} from './components/analysis-view/AnalysisViewEdit'
import {ChangelogsList} from './components/migrations/changelogs/ChangelogsList'
import {PendingMigrationsList} from './components/migrations/changelogs/PendingMigrationsList'
import {CreateReclassificationWrapper} from './components/reclassifications/CreateReclassificationWrapper'
import {ModelUploadJobCreateWrapper} from './components/model-upload-jobs/ModelUploadJobCreateWrapper'
import {config} from './config'
import {ModelSetupJobCreateWrapper} from './components/model-setup-jobs/ModelSetupJobCreateWrapper'
import {ModelSetupJobEdit} from './components/model-setup-jobs/ModelSetupJobEdit'
import {ModelSetupJobShow} from './components/model-setup-jobs/ModelSetupJobShow'
import {Route, Router, Switch} from 'react-router-dom'
import {StandAloneForgeViewer} from './components/forge-viewer/stand-alone-forge-viewer/StandAloneForgeViewer'
import {SRResource} from './data_provider/SRResource'
import {SuggestionsCreate} from './components/elements-of-interest/SuggestionsCreate'

const referenceFieldStyles = {
	// override the styles of all instances of this component
	link: {
		// Name of the rule
		color: '#1c62dc', // Some CSS
	},
}

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#666',
			dark: '#000',
			main: '#333',
			contrastText: '#FFF',
		},
		secondary: {
			light: '#E8E8E8',
			dark: '#999',
			main: '#FCD116',
			contrastText: '#111',
		},
		background: {
			default: '#E8E8E8',
		},
	},
	overrides: {
		// @ts-ignore
		RaReferenceField: referenceFieldStyles,
	},
})
function App() {
	return (
		<Router history={history}>
			<Switch>
				<Route path={'/view-forge-stand-alone'}>
					<StandAloneForgeViewer />
				</Route>
				<Route path={'/'}>
					<Admin
						theme={theme}
						history={history}
						dataProvider={SRDataProvider}
						authProvider={Cognito}
						i18nProvider={i18nProvider}
						loginPage={CognitoLogin}
						layout={CustomLayout}
						customReducers={{fileReducer}}
						customSagas={[fileSaga]}
					>
						<SRResource
							name="companies"
							icon={BusinessIcon}
							list={CompaniesList}
							edit={CompanyEdit}
							create={CompanyCreate}
							show={CompanyShow}
						/>
						<SRResource
							name="projects"
							icon={BuildIcon}
							list={ProjectsList}
							edit={ProjectEdit}
							create={ProjectCreate}
							show={ProjectShow}
						/>
						<SRResource
							name="users"
							icon={PersonIcon}
							list={UsersList}
							edit={UserEdit}
							create={UserCreate}
							show={UserShow}
						/>
						<SRResource
							name="teams"
							icon={GroupIcon}
							list={TeamList}
							edit={TeamEdit}
							create={TeamCreate}
							show={TeamShow}
						/>
						<SRResource
							name="team-members"
							icon={GroupIcon}
							edit={TeamMemberEdit}
							create={TeamMemberCreate}
							show={TeamShow}
						/>
						<SRResource
							name="workspaces"
							icon={FolderSharedIcon}
							list={WorkspaceList}
							edit={WorkspaceEdit}
							create={WorkspaceCreate}
							show={WorkspaceShow}
						/>
						<SRResource name="files" icon={FileIcon} list={FilesList} create={FilesCreate} show={FilesShow} />
						<SRResource name="teams" icon={GroupIcon} edit={TeamEdit} create={TeamCreate} show={TeamShow} />
						<SRResource name="filter-presets" edit={FilterPresetEdit} />
						<SRResource name="analyses" create={CreateAnalysis} edit={AnalysisEdit} />
						<SRResource name="analysis-view" edit={AnalysisViewEdit} show={AnalysisViewShow} />
						<SRResource name="rois" create={ROICreate} edit={ROIEdit} show={ROIShow} />
						<SRResource name="versions" />
						<SRResource name="element-types" />
						<SRResource name="features" />
						<SRResource name="watchlists" />
						<SRResource name="progress-monitorings" />
						<SRResource name="models" edit={ModelsEdit} show={ModelsShow} />
						<SRResource
							name="reclassifications"
							create={CreateReclassificationWrapper}
							edit={UpdateReclassification}
							show={ShowReclassification}
						/>
						<SRResource name={'reclassification-stages-templates'} />
						<SRResource
							name="model-upload-jobs"
							create={ModelUploadJobCreateWrapper}
							edit={ModelUploadJobEdit}
							show={ModelUploadJobShow}
						/>
						<SRResource
							name="model-setup-jobs"
							create={ModelSetupJobCreateWrapper}
							edit={ModelSetupJobEdit}
							show={ModelSetupJobShow}
						/>
						<SRResource name="scans" create={ScansCreate} edit={ScansEdit} show={ScansShow} />
						<SRResource name="project-files" icon={BuildIcon} create={ProjectFileCreate} show={FilesShow} />
						<SRResource name="auths" create={AuthCreate} edit={AuthEdit} />
						<SRResource
							name="analysis-processing-jobs"
							create={AnalysisProcessingJobCreate}
							show={AnalysisProcessingJobsShow}
						/>
						<SRResource
							name="analysis-processing-jobs-simple"
							options={{label: 'Jobs'}}
							list={AnalysesProcessingJobSimpleList}
						/>
						<SRResource name="masvs" icon={FileIcon} list={MasvList} show={MasvShow} />
						<SRResource name={'changelogs-phase-1'} options={{label: 'Migrations Phase 1'}} list={ChangelogsList} />
						<SRResource name={'changelogs-phase-2'} options={{label: 'Migrations Phase 2'}} list={ChangelogsList} />
						<SRResource name={'changelogs-phase-3'} options={{label: 'Migrations Phase 3'}} list={ChangelogsList} />
						<SRResource
							name={'pending-changelogs-phase-1'}
							options={{label: 'Migrations Phase 1 Pending'}}
							list={PendingMigrationsList}
						/>
						<SRResource
							name={'pending-changelogs-phase-2'}
							options={{label: 'Migrations Phase 2 Pending'}}
							list={PendingMigrationsList}
						/>
						<SRResource
							name={'pending-changelogs-phase-3'}
							options={{label: 'Migrations Phase 3 Pending'}}
							list={PendingMigrationsList}
						/>
						<SRResource name="suggestions" create={SuggestionsCreate} />
						<Title title={` ${config.sr.companyName} Dashboard ${process.env.REACT_APP_STAGE} -- `} />
						<FileNotification />
					</Admin>
				</Route>
			</Switch>
		</Router>
	)
}

export default App
