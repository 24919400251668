import {BaseRepository} from './base.repository'
import {throwError} from '../../../utils/errors.utils'

export class ChangelogRepository extends BaseRepository {
	constructor(public name: 'changelogs-phase-1' | 'changelogs-phase-2' | 'changelogs-phase-3') {
		super()
	}

	async getPending(): Promise<{data: {_id: string; fileName: string}[]}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl('/pending'))
			return {data: response.data.map(fileName => ({_id: fileName, fileName: fileName}))}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getPending: ${e.message}`)
		}
	}

	migrateUp() {
		return this.conn.post(this.api.buildBaseUrl('/migrate-up')).then(response => response.data)
	}
}
