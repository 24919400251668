import {makeStyles, Theme} from '@material-ui/core/styles'
import {Button, required, SelectInput, SimpleForm, useQuery, useRefresh} from 'react-admin'
import React, {useCallback, useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import {analysisViewService} from '../../../data_provider/v3/services/analysis-view.service'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import {AnalysisViewAnalysisAndVersionSelector} from '../shared/AnalysisViewAnalysisAndVersionSelector'

export const modalCustomStyles = makeStyles(
	(theme: Theme) => ({
		description: {color: '#565656', fontSize: '10pt'},
		section: {fontSize: '12pt', padding: '0 0 0 20px', width: '100%'},
		actions: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '16px',
		},
		confirmButton: {
			width: '200px',
			height: '36px',
			padding: '0 12px',
			borderRadius: '4px',
			backgroundColor: '#ffb800',
			color: '#000',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#c99301',
				color: '#000',
			},
		},
	}),
	{name: 'ConfirmActivatingModal'},
)

export const AnalysisViewImportAnalysisModal = ({showDialog, onClose, projectId}) => {
	const refresh = useRefresh()
	const [fromConfig, setFromConfig] = useState<{
		project?: any
		analysisView?: any
		versionMap?: {
			[k in string]: string
		}
	}>({})
	const [targetConfig, setTargetConfig] = useState<{
		targetModel?: string
	}>({})
	const [mappingsCsvFile, setMappingsCsvFile] = useState<string>()
	const classes = modalCustomStyles()
	const [loadingState, setLoadingState] = useState<{loading: boolean; error: string | null; runLog: string | null}>({
		loading: false,
		error: null,
		runLog: null,
	})
	const onImport = async () => {
		setLoadingState({loading: true, error: null, runLog: ''})
		try {
			let log = ''
			await analysisViewService
				.importAnalysis(
					projectId,
					fromConfig.project._id,
					fromConfig.analysisView._id,
					fromConfig.versionMap,
					targetConfig.targetModel!,
					mappingsCsvFile,
				)
				.then(async r => {
					const queuingStrategy = new CountQueuingStrategy({highWaterMark: 1})
					const writableStream = new WritableStream(
						{
							write(chunk) {
								log += new TextDecoder().decode(new Uint8Array(chunk))
								setLoadingState({...loadingState, runLog: log})
							},
							close() {
								setLoadingState({...loadingState, loading: false, runLog: log})
							},
							abort(e) {
								setLoadingState({
									runLog: log,
									loading: false,
									error: e?.message || 'Import analysis action aborted',
								})
							},
						},
						queuingStrategy,
					)
					r.body!.pipeTo(writableStream)
				})
				.catch(e =>
					setLoadingState({
						...loadingState,
						loading: false,
						error: e?.message || 'Import analysis action aborted',
					}),
				)
		} catch (e) {
			setLoadingState({...loadingState, loading: false, error: e.message})
		}
	}
	const onCloseWrapper = useCallback(() => {
		if (!loadingState.loading) {
			if (loadingState.runLog && !loadingState.error) refresh()
			onClose()
		}
	}, [onClose, loadingState, refresh])
	const {data: projects} = useQuery({
		type: 'getList',
		resource: 'projects',
		payload: {pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0}, sort: {field: '_id', order: ''}, filter: {}},
	})
	const {data: targetModels} = useQuery({
		type: 'getList',
		resource: 'models',
		payload: {
			pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0},
			sort: {field: '_id', order: ''},
			filter: {},
			enabled: projectId,
			projectId: projectId,
		},
	})
	const {data: projectCSVFiles} = useQuery({
		type: 'getList',
		resource: 'project-files',
		payload: {
			pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0},
			sort: {field: '_id', order: ''},
			filter: {fileEnd: '.csv', status: 'uploaded'},
			enabled: projectId,
			projectId: projectId,
		},
	})
	const {data: analysisViews} = useQuery({
		type: 'getList',
		resource: 'analysis-view',
		payload: {
			pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0},
			sort: {field: '_id', order: ''},
			filter: {},
			enabled: !!fromConfig.project?.id,
			projectId: fromConfig.project?.id,
		},
	})
	const submitEnabled = useCallback(
		() =>
			fromConfig.analysisView &&
			fromConfig.analysisView.analyses?.every(a => !!fromConfig.versionMap?.[a?._id]) &&
			targetConfig.targetModel,
		[fromConfig, targetConfig],
	)
	const setFromProject = projectId =>
		setFromConfig({
			project: projects?.find(p => p.id === projectId),
		})
	const setFromAnalysisView = useCallback(
		analysisViewId =>
			setFromConfig({
				project: fromConfig.project,
				analysisView: analysisViews?.find(av => av.id === analysisViewId),
			}),
		[fromConfig, analysisViews],
	)
	const setFromVersionMap = useCallback(
		(versionMap: {[k in string]: string}) => setFromConfig({...fromConfig, versionMap}),
		[fromConfig],
	)
	const addToFromVersionMap = useCallback(
		(analysisId: string, version: string) => setFromVersionMap({...fromConfig.versionMap, [analysisId]: version}),
		[setFromVersionMap, fromConfig],
	)
	const setTargetModel = useCallback(
		(targetModel: string) =>
			setTargetConfig({
				targetModel,
			}),
		[setTargetConfig],
	)
	useEffect(() => {
		setFromProject(projectId)
	}, [projects, projectId])
	useEffect(() => {
		setTargetModel(targetModels?.find(m => m.active)?._id)
	}, [targetModels, setTargetModel])
	return (
		<Dialog fullWidth open={showDialog} onClose={onCloseWrapper}>
			<DialogTitle>Import Analysis to this project</DialogTitle>
			<DialogContent>
				{loadingState.error ? (
					<div style={{wordBreak: 'break-word', display: 'block', overflowX: 'auto'}}>
						<h3>There was an error trying to perform this operation, please try again</h3>
						<code>{loadingState.error}</code>
					</div>
				) : loadingState.runLog ? (
					<>
						<h3>Importing analysis</h3>
						<div style={{wordBreak: 'break-word', display: 'block', overflow: 'auto', height: '200px'}}>
							<code>
								<pre>{loadingState.runLog}</pre>
							</code>
						</div>
					</>
				) : (
					projects && (
						<SimpleForm toolbar={<></>}>
							<span className={classes.description}>
								This operation will produce a new analysis with a new version on the project{' '}
								<b>{projects.find(p => p._id === projectId)?.name}</b>. This new analysis version will be mapped to the
								model of your choice in this project. <br />
								<b>Important:</b>
								<br />
								<ul>
									<li>
										For the element mapping you have to provide a csv containing 2 columns:
										<ul>
											<li>
												<b>sourceModelExternalId:</b> The guid in the source analysis model
											</li>
											<li>
												<b>targetModelExternalId:</b> The guid in the target model
											</li>
										</ul>
									</li>
									<li>
										Elements belonging to the source analysis not found in the mapping will be excluded from the
										resulting analysis.
									</li>
									<li>
										If the mapping csv is not provided, this tool will assume that the ids in the source analysis are
										the same than in the target model.
									</li>
									<li>
										<b>PLEASE</b>, double check all the info provided, in particular the mapping csv before proceeding.
									</li>
								</ul>
							</span>
							<h3>1. Import analyses From</h3>
							<div className={classes.section}>
								<div style={{display: 'flex', gap: '50px'}}>
									<SelectInput
										label={'Project'}
										choices={projects}
										source={'projectId'}
										validate={[required()]}
										onChange={ev => setFromProject(ev.target.value)}
										defaultValue={projects ? projectId : undefined}
										disabled={!projects}
									/>
									<SelectInput
										label={'Analysis'}
										choices={analysisViews}
										source={'analysisView'}
										validate={[required()]}
										onChange={ev => setFromAnalysisView(ev.target.value)}
										disabled={!fromConfig.project?.id}
										helperText={!fromConfig.project?.id ? 'Please select a project first' : undefined}
									/>
								</div>
								<AnalysisViewAnalysisAndVersionSelector
									keyPrefix={'from'}
									analysisView={fromConfig.analysisView}
									onVersionSelected={addToFromVersionMap}
									onVersionInitialValues={setFromVersionMap}
									versionMap={fromConfig.versionMap}
								/>
							</div>
							<h3 style={{width: '100%'}}>2. Target model</h3>
							<div className={classes.section}>
								<SelectInput
									label={'Taget Model'}
									choices={targetModels}
									defaultValue={targetConfig.targetModel}
									source={'targetModel'}
									validate={[required()]}
									onChange={ev => setTargetModel(ev.target.value)}
									disabled={!targetModels}
								/>
							</div>
							<h3 style={{width: '100%'}}>
								3. Element id mapping from the model of the source analyses to te target model
							</h3>
							<div className={classes.section}>
								<SelectInput
									label={'Mappings file'}
									choices={projectCSVFiles?.map(f => ({id: f._id, name: f.filename}))}
									source={'modelMapping'}
									onChange={ev => setMappingsCsvFile(ev.target.value)}
									disabled={!projectCSVFiles}
								/>
							</div>
						</SimpleForm>
					)
				)}
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button onClick={onCloseWrapper} disabled={loadingState.loading} label="Close" title="Close" />
				{!loadingState.error && !loadingState.runLog && (
					<Button
						className={classes.confirmButton}
						onClick={onImport}
						label="Import Analysis"
						title="Import Analysis"
						disabled={loadingState.loading || !submitEnabled()}
					>
						{loadingState.loading ? <CircularProgress size={'16px'} /> : <SystemUpdateAltIcon />}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)
}
