import {
	ArrayField,
	BooleanField,
	Datagrid,
	DateField,
	EditButton,
	FunctionField,
	Labeled,
	ReferenceField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	TopToolbar,
	useQuery,
	useRecordContext,
} from 'react-admin'
import React from 'react'
import {getFullName} from '../../utils/getters.utils'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import {StatusChipInputCheckInfo} from '../input-check/StatusChipInputCheckInfo'
import {
	CheckName,
	ConditionalDownloadAssetsButton,
	ConditionalOutputAssetsInfoModal,
	ConditionalValidateButton,
	InfoIconButton,
} from '../input-check/input-check.utilities'
import {usePreserveQuerystring} from '../../hooks/UsePreserveQuerystring'
import {SRShow} from '../SRComponents/SRShow'

const customStyles = makeStyles(theme => ({
	field: {
		display: 'flex',
		width: theme.spacing(32),
	},
}))

function ShowActions({basePath, data, projectId}: any) {
	return !data ? null : (
		<TopToolbar>
			<EditButton to={`${basePath}/${data._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

function ConditionalScansSurveyModeStaticScanner(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.config?.surveyMode === 'staticScanner' ? (
		<>
			<Labeled label="Images source">
				<TextField className={classes.field} {...props} source="config.imagesSource" />
			</Labeled>
			<ArrayField className={classes.field} {...props} source="config.e57ProjectFiles">
				<Labeled label=".e57 project files">
					<Datagrid>
						<TextField source="filename" label="Name" sortable={false} />
					</Datagrid>
				</Labeled>
			</ArrayField>
			<ReferenceField
				{...props}
				className={classes.field}
				reference="files"
				source="urdfFile._id"
				label="URDF file"
				link={false}
			>
				<Labeled label="URDF file">
					<TextField source="filename" />
				</Labeled>
			</ReferenceField>
			<br />
			<Labeled label="isGeoReferenced">
				<BooleanField className={classes.field} {...props} source="config.isGeoReferenced" />
			</Labeled>
		</>
	) : null
}

function ConditionalScansSurveyModeRobotV3(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.config?.surveyMode === 'robotV3' ? (
		<>
			<ReferenceField
				{...props}
				className={classes.field}
				reference="project-files"
				source="config.rosbagProjectFile._id"
				label="Rosbag File"
				link={false}
			>
				<Labeled label="Rosbag File">
					<TextField source="filename" />
				</Labeled>
			</ReferenceField>
			<br />
			<ReferenceField
				{...props}
				className={classes.field}
				reference="files"
				source="urdfFile._id"
				link={false}
				label="URDF file"
			>
				<Labeled label="URDF file">
					<TextField source="filename" />
				</Labeled>
			</ReferenceField>
		</>
	) : null
}

function ConditionalScansSurveyModeNavvisScanner(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.config?.surveyMode === 'navvisScanner' ? (
		<>
			<Labeled label="Images source">
				<TextField className={classes.field} {...props} source="config.imagesSource" />
			</Labeled>
			<ArrayField className={classes.field} {...props} source="config.e57ProjectFiles">
				<Labeled label=".e57 project files">
					<Datagrid>
						<TextField source="filename" label="Name" sortable={false} />
					</Datagrid>
				</Labeled>
			</ArrayField>
			<Labeled label="Images zip">
				<TextField className={classes.field} {...props} source="config.imagesZip.filename" />
			</Labeled>
			<Labeled label="Scan poses">
				<TextField className={classes.field} {...props} source="config.scanPoses.filename" />
			</Labeled>
			<ReferenceField
				{...props}
				className={classes.field}
				reference="files"
				source="urdfFile._id"
				label="URDF file"
				link={false}
			>
				<Labeled label="URDF file">
					<TextField source="filename" />
				</Labeled>
			</ReferenceField>
			<br />
			<Labeled label="isGeoReferenced">
				<BooleanField className={classes.field} {...props} source="config.isGeoReferenced" />
			</Labeled>
		</>
	) : null
}

function ConditionalScansSurveyModeNavvisScannerV2(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.config?.surveyMode === 'navvisScannerV2' ? (
		<>
			<Labeled label="Images source">
				<TextField className={classes.field} {...props} source="config.imagesSource" />
			</Labeled>
			<ArrayField className={classes.field} {...props} source="config.e57ProjectFiles">
				<Labeled label=".e57 project files">
					<Datagrid>
						<TextField source="filename" label="Name" sortable={false} />
					</Datagrid>
				</Labeled>
			</ArrayField>
			<Labeled label="CSV scan poses">
				<TextField className={classes.field} {...props} source="config.scanPoses.filename" />
			</Labeled>
			<ReferenceField
				{...props}
				className={classes.field}
				reference="files"
				source="urdfFile._id"
				label="URDF file"
				link={false}
			>
				<Labeled label="URDF file">
					<TextField source="filename" />
				</Labeled>
			</ReferenceField>
			<br />
			<Labeled label="isGeoReferenced">
				<BooleanField className={classes.field} {...props} source="config.isGeoReferenced" />
			</Labeled>
		</>
	) : null
}

function ConditionalScansSurveyModeBLK2GO(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.config?.surveyMode === 'blk2go' ? (
		<>
			<Labeled label="Images source">
				<TextField className={classes.field} {...props} source="config.imagesSource" />
			</Labeled>
			<ArrayField className={classes.field} {...props} source="config.e57ProjectFiles">
				<Labeled label=".e57 project files">
					<Datagrid>
						<TextField source="filename" label="Name" sortable={false} />
					</Datagrid>
				</Labeled>
			</ArrayField>
			<ReferenceField
				{...props}
				className={classes.field}
				reference="files"
				source="urdfFile._id"
				label="URDF file"
				link={false}
			>
				<Labeled label="URDF file">
					<TextField source="filename" />
				</Labeled>
			</ReferenceField>
			<br />
			<Labeled label="isGeoReferenced">
				<BooleanField className={classes.field} {...props} source="config.isGeoReferenced" />
			</Labeled>
		</>
	) : null
}

export function ScansShow(props: any) {
	const searchParams = usePreserveQuerystring('projectId')
	const projectId = searchParams.get('projectId')
	const {data} = useQuery({
		type: 'getOne',
		resource: props.resource,
		payload: {id: props.id},
	})
	return (
		<SRShow
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<TabbedShowLayout>
				<Tab label="scan properties">
					<TextField label="Name" source="name" />
					<DateField source="createdDate" />
					<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
						<FunctionField render={getFullName} />
					</ReferenceField>
					<TextField source="config.surveyMode" label="Survey mode" />
					<ConditionalScansSurveyModeStaticScanner />
					<ConditionalScansSurveyModeRobotV3 />
					<ConditionalScansSurveyModeNavvisScanner />
					<ConditionalScansSurveyModeNavvisScannerV2 />
					<ConditionalScansSurveyModeBLK2GO />
				</Tab>
				<Tab label="input validation">
					{!data?.inputCheck ? (
						<Typography variant="h5" component="div" style={{padding: '40px 5px'}}>
							This model doesn't have input checks
						</Typography>
					) : (
						<>
							<Typography variant="h6">
								Status <StatusChipInputCheckInfo record={data} label={'Status'} />
							</Typography>
							{data.inputCheck.message?.length ? (
								<Typography variant="body2" component="div">
									{data.inputCheck.message}
								</Typography>
							) : null}
						</>
					)}
					{!data?.inputCheck?.checks?.length ? null : (
						<ArrayField record={data} source="inputCheck.checks" label={<Typography variant="h6">Checks</Typography>}>
							<Datagrid>
								<StatusChipInputCheckInfo label={'Status'} />
								<CheckName label="Check" />
								<ConditionalOutputAssetsInfoModal projectId={projectId} data={data} />
								<ConditionalDownloadAssetsButton projectId={projectId} data={data} />
								<ConditionalValidateButton projectId={projectId} data={data} />
								<InfoIconButton label="Info" />
							</Datagrid>
						</ArrayField>
					)}
				</Tab>
			</TabbedShowLayout>
		</SRShow>
	)
}
