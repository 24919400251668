import {Button, useGetManyReference} from 'react-admin'
import React from 'react'
import {Link} from 'react-router-dom'

function CreateFromVersionButton(props) {
	const {ids, total, loading} = useGetManyReference(
		'reclassifications',
		'version',
		props.record._id,
		{page: 1, perPage: 10},
		{field: '_id', order: 'DESC'},
		{projectId: props.projectId},
		'versions',
	)
	if (loading) return null
	if (ids) {
		return total === 0 ? (
			<Button
				component={Link}
				label={'Start reclassification'}
				to={`/reclassifications/create?&projectId=${props.projectId}&source=${JSON.stringify({
					versionId: props.record._id,
				})}`}
			/>
		) : (
			<Button
				component={Link}
				label={'Show reclassification'}
				to={`/reclassifications/${ids[0]}/show?projectId=${props.projectId}`}
			/>
		)
	} else {
		return null
	}
}

export function CreateOrViewReclassificationFromVersionButton(props) {
	if (props.record === undefined) {
		return null
	} else {
		return <CreateFromVersionButton {...props} />
	}
}
