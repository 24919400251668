import {SimpleShowLayout, Tab} from 'react-admin'
import React from 'react'
import {ShowIfProjectPermission} from '../ShowIfProjectPermission'
import HardDeleteProjectButton from '../HardDeleteProjectButton'

export const DangerProjectTab = props => (
	<Tab {...props}>
		<SimpleShowLayout>
			<ShowIfProjectPermission projectId={props.id} permission={'SR_ADMIN'}>
				<h3>
					<span style={{color: 'red'}}>&#9888;</span> Delete forever
				</h3>
				<h4>Attention:</h4>
				<p>
					<b>
						This will <span style={{color: 'red'}}>permanently remove</span> all contents of this project from the
						database:
					</b>
				</p>
				<ul>
					<li>Viewer and analysis</li>
					<li>Assets</li>
					<li>Inputs, etc.</li>
				</ul>
				<p>
					<b>This action cannot be undone.</b>
				</p>
				<HardDeleteProjectButton {...props} projectId={props.id} />
			</ShowIfProjectPermission>
		</SimpleShowLayout>
	</Tab>
)
