import {
	projectFileRepository,
	ProjectFileRepository,
	RootFileRepository,
	rootFileRepository,
} from '../repositories/files.repository'

import {BaseServiceByProject} from './base.service.by-project'
import {BaseService} from './base.service'

export class RootFileService extends BaseService<RootFileRepository> {
	repository = rootFileRepository

	signedUploadPart = (fileId: string) => {
		return this.repository.signedUploadPart(fileId)
	}

	getSignedUrl = (fileId: string) => {
		return this.repository.getSignedUrl(fileId)
	}
}

export class ProjectFileService extends BaseServiceByProject<ProjectFileRepository> {
	repository = projectFileRepository

	signedUploadPart = async (projectId: string, fileId: string) => {
		return this.repository.signedUploadPart(projectId, fileId)
	}

	getSignedUrl = async (projectId: string, fileId: string) => {
		return this.repository.getSignedUrl(projectId, fileId)
	}
}

export const rootFileService = new RootFileService()
export const projectFileService = new ProjectFileService()
