import {BaseRepository} from './base.repository'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'

export class UserRepository extends BaseRepository {
	name: ResourceName = 'users'

	async getByCompanyIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: unknown[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildCompanyUrl(companyId), {
				params: {filter, sort, skip, limit},
			})
			const {
				data: {result, count},
			} = response
			return {
				data: result,
				total: count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getByCompanyIdWithQuery: ${e.message}`)
		}
	}

	async getByProjectIdWithQuery(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId), {
				params: {filter, sort, skip, limit},
			})
			const {
				data: {result, count},
			} = response
			return {
				data: result,
				total: count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getByProjectIdWithQuery: ${e.message}`)
		}
	}

	async resetPassword(id: string) {
		await this.conn.post(this.api.buildBaseUrl(id, 'reset-password'))
	}

	async getCognitoUser(id: string): Promise<any> {
		const result = await this.conn.get(this.api.buildBaseUrl(id, 'cognito'))
		return result.data
	}
}

export const userRepository = new UserRepository()
