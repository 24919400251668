import React from 'react'
import {Edit, SimpleForm, SelectInput, TextField} from 'react-admin'
import {useGetRoles} from './roles'

export function AuthEdit(props: any) {
	const roles = useGetRoles()
	const redirect = (basePath, id, data) => {
		return props.history.goBack()
	}
	return (
		<Edit {...props} undoable={false}>
			<SimpleForm redirect={redirect}>
				<TextField source="entityName" label="Resource name" />
				<TextField source="email" label="Member email" />
				<TextField source="memberName" label="Member name" />
				<TextField source="memberType" label="Member type" />
				<SelectInput source="role" choices={roles} />
			</SimpleForm>
		</Edit>
	)
}
