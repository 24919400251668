import React from 'react'
import {
	BooleanField,
	BulkDeleteButton,
	Datagrid,
	DateField,
	FunctionField,
	ReferenceField,
	TextField,
} from 'react-admin'
import AnalysesProcessingJobSimpleFilters from './AnalysesProcessingJobSimpleFilters'
import {StartAnalysisProcessingJobButton} from '../projects/StartAnalysisProcessingJobButton'
import {getFullName} from '../../utils/getters.utils'
import {DeleteAnalysisProcessingJobButton} from '../analysis-processing-jobs/DeleteAnalysisProcessingJobButton'
import {ByProjectShowButton} from '../buttons/ByProjectShowButton'
import {DownloadErrorPayloadButton} from '../buttons/DownloadErrorPayloadButton'
import {SRList} from '../SRComponents/SRList'

export function AnalysesProcessingJobSimpleList(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	return (
		<SRList
			{...props}
			entityNameProp={entity => entity.analysisMetaData.name}
			projectId={projectId}
			fileId={props.id}
			listTabName="analyses"
			filters={<AnalysesProcessingJobSimpleFilters />}
			sort={{field: 'createdDate', order: 'DESC'}}
			bulkActionButtons={<BulkDeleteButton undoable={false} />}
		>
			<Datagrid>
				<StartAnalysisProcessingJobButton projectId={props.project} />
				<BooleanField source={'manualMode'} label="Manual" />
				<TextField label="Analysis name" source="analysisMetaData.name" />
				<ReferenceField label="Project" source="project" reference="projects" link={false}>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="status" />
				<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
					<FunctionField render={getFullName} />
				</ReferenceField>
				<DateField source="createdDate" />
				<DownloadErrorPayloadButton />
				<ByProjectShowButton basePath="analysis-processing-jobs" />
				<DeleteAnalysisProcessingJobButton redirect={false} undoable={false} />
			</Datagrid>
		</SRList>
	)
}
