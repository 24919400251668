import React from 'react'
import {
	ArrayInput,
	AutocompleteInput,
	BooleanInput,
	Create,
	FormDataConsumer,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	useQuery,
} from 'react-admin'
import {InputCheckSelect} from '../input-check/InputCheckSelect'
import {SRCreate} from '../SRComponents/SRCreate'

export function ROICreate(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => `/projects/${projectId}/show/rois`
	const transform = data => ({...data, projectId})
	const {data} = useQuery({
		type: 'getList',
		resource: 'models',
		payload: {pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0}, sort: {field: '_id', order: ''}, filter: {}},
	})

	const roiFileTypes = [
		{id: 'geometry', name: 'Geometry'},
		{id: 'reservations', name: 'Reservations'},
		{id: 'alignmentSupport', name: 'Alignment Support'},
	]

	return (
		<SRCreate {...props} projectId={projectId} showBreadcrumb transform={transform}>
			<SimpleForm redirect={redirect}>
				<TextInput source="name" label="Name" validate={[required()]} />
				{data ? <InputCheckSelect data={data} source="model" /> : null}
				<ArrayInput validate={[required()]} source="elementSetSpecifications">
					<SimpleFormIterator>
						<ReferenceInput
							label="Global Ids CSV File"
							source="externalIdsProjectFileId"
							reference="project-files"
							filterToQuery={q => ({q, fileEnd: '.csv', status: 'uploaded'})}
							validate={[required()]}
						>
							<AutocompleteInput optionText="filename" multiline />
						</ReferenceInput>
						<SelectInput label={'Type'} source={'type'} choices={roiFileTypes} validate={[required()]} />
						<FormDataConsumer>
							{({getSource, scopedFormData}) => {
								if (
									getSource &&
									scopedFormData &&
									(scopedFormData.type === 'geometry' || scopedFormData.type === 'reservations')
								) {
									return (
										<NumberInput validate={[required()]} label={'Tolerance (mm)'} source={getSource('tolerance')} />
									)
								} else {
									return null
								}
							}}
						</FormDataConsumer>
					</SimpleFormIterator>
				</ArrayInput>
				<BooleanInput
					label={'Ignore missing global ids'}
					source="ignoreMissingExternalIds"
					validate={[required()]}
					defaultValue={false}
					helperText={'If used, the global ids not existing in the model will be ignored.'}
				/>
			</SimpleForm>
		</SRCreate>
	)
}
