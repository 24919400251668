import React from 'react'
import {Filter, ReferenceInput, AutocompleteInput, SearchInput} from 'react-admin'

export default function WorkspaceFilters(props: any) {
	return (
		<Filter {...props}>
			<SearchInput source="q" alwaysOn />

			<ReferenceInput
				label="Filter by Company"
				source="company"
				reference="companies"
				sort={{field: 'name', order: 'ASC'}}
				alwaysOn
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
		</Filter>
	)
}
