import CachedIcon from '@material-ui/icons/Cached'
import {Typography} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import WarningIcon from '@material-ui/icons/WarningOutlined'
import CheckIcon from '@material-ui/icons/Check'
import React from 'react'
import {required, SelectInput} from 'react-admin'

const getOptionChoices = (target, isInputChecksDisabled) => {
	let icon
	let isDisabled
	switch (target.inputCheck?.status) {
		case 'validating':
		case 'not_started':
		case 'created':
			icon = <CachedIcon htmlColor="#242629" style={{paddingRight: '4px'}} />
			isDisabled = !isInputChecksDisabled
			break
		case 'errored':
		case 'failed':
			icon = <ErrorOutlineIcon color="error" style={{paddingRight: '4px'}} />
			isDisabled = !isInputChecksDisabled
			break
		case 'warning':
		case 'manual':
			icon = <WarningIcon style={{paddingRight: '4px', color: 'ffb800'}} />
			isDisabled = false
			break
		case 'valid':
		case 'passed':
			icon = <CheckIcon htmlColor="#00c781" style={{paddingRight: '4px'}} />
			isDisabled = false
			break
		default:
			icon = <></>
			isDisabled = false
	}

	return {_id: target._id, statusIcon: icon, name: target.name, disabled: isDisabled}
}
const OptionRenderer = ({record}) => (
	<span style={{display: 'flex', alignItems: 'center'}}>
		{record.statusIcon}
		<Typography variant="body2">{record.name}</Typography>
	</span>
)

export const InputCheckSelect = ({data, isInputChecksDisabled = false, source}) => {
	const choices = data.map(element => getOptionChoices(element, isInputChecksDisabled))
	return (
		<SelectInput
			source={source}
			choices={choices}
			optionText={<OptionRenderer record={choices} />}
			optionValue="_id"
			multiline
			disableValue="disabled"
			validate={required()}
		/>
	)
}
