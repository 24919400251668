import {Button, useGetManyReference} from 'react-admin'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {versionsRepository} from '../../data_provider/v3/repositories/versions.repository'
import {useNotify} from 'ra-core'

function CreateFromVersionButton(props) {
	const {ids, total, loading} = useGetManyReference(
		'reclassifications',
		'version',
		props.record._id,
		{page: 1, perPage: 10},
		{field: '_id', order: 'DESC'},
		{projectId: props.projectId},
		'versions',
	)
	if (loading) return null
	if (ids) {
		return total === 0 ? (
			<Button
				component={Link}
				label={'Start reclassification'}
				to={`/reclassifications/create?&projectId=${props.projectId}&source=${JSON.stringify({
					versionId: props.record._id,
				})}`}
			/>
		) : (
			<Button
				component={Link}
				label={'Show reclassification'}
				to={`/reclassifications/${ids[0]}/show?projectId=${props.projectId}`}
			/>
		)
	} else {
		return null
	}
}

export function DuplicateVersionButton(props) {
	const [disabled, setDisabled] = useState(false)
	const notify = useNotify()
	if (props.record === undefined) {
		return null
	} else {
		return (
			<Button
				component={Link}
				label={disabled ? 'Copying...' : 'Duplicate'}
				disabled={disabled}
				onClick={async () => {
					setDisabled(true)
					try {
						await versionsRepository.copyVersion(props.projectId, props.record.id)
					} catch (error: any) {
						notify(error.message, 'warning')
					} finally {
						setDisabled(false)
						props.refresh()
					}
				}}
			/>
		)
	}
}
