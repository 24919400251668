import {Datagrid, DateField, TextField} from 'react-admin'
import React from 'react'
import {SRList} from '../../SRComponents/SRList'

export function ChangelogsList(props: any) {
	return (
		<SRList title={props.options.label} bulkActionButtons={false} sort={{field: 'appliedAt', order: 'DESC'}} {...props}>
			<Datagrid>
				<TextField source="fileName" />
				<DateField source="appliedAt" showTime={true} />
			</Datagrid>
		</SRList>
	)
}
