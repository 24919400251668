import {modelSetupJobRepository, ModelSetupJobRepository} from '../repositories/model-setup-job.repository'
import {BaseServiceByProject} from './base.service.by-project'

export class ModelSetupJobService extends BaseServiceByProject<ModelSetupJobRepository> {
	repository = modelSetupJobRepository

	async startModelSetupJobByProjectId(projectId: string, id: string) {
		return this.encodeResult(await this.repository.startModelSetupJobByProjectId(projectId, id))
	}

	async cleanUpModelSetupJobByProjectId(projectId: string, id: string) {
		return this.encodeResult(await this.repository.cleanUpModelSetupJobByProjectId(projectId, id))
	}

	async createFromModel(projectId: string, data: {modelName: string; modelId: string; shallowCopy: boolean}) {
		return this.encodeResult(await this.repository.createFromModel(projectId, data))
	}
}

export const modelSetupJobService = new ModelSetupJobService()
