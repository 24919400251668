import {EncodeMixin} from './base.service'
import {BaseRepositoryMultiProject} from '../repositories/base.repositories.multi-project'

export abstract class BaseServiceMultiProject<R extends BaseRepositoryMultiProject> extends EncodeMixin {
	abstract readonly repository: R

	get name() {
		return this.repository.name
	}

	async getList(
		projectId: string | null,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: unknown[]; total: number}> {
		return this.encodeResults(await this.repository.getList(projectId, filter, sort, skip, limit))
	}

	async getOne(id: string): Promise<{data: unknown}> {
		return this.encodeResult(await this.repository.getOne(id))
	}

	async getManyReference(
		target: string,
		project: string | null,
		filter: string | null,
		sort: string | null,
		skip: any,
		limit: any,
	) {
		return this.encodeResults(await this.repository.getManyReference(target, project, filter, sort, skip, limit))
	}

	async getMany(ids: string[]): Promise<{data: unknown[]}> {
		return this.encodeResults(await this.repository.getMany(ids))
	}

	async create(data: any): Promise<{data: unknown}> {
		data = this.encodeOutput(data)
		return this.encodeResult(await this.repository.create(data))
	}

	async update(id: string, data: any): Promise<{data: unknown}> {
		data = this.encodeOutput(data)
		return this.encodeResult(await this.repository.update(id, data))
	}

	async updateMany(ids: string[], data: any): Promise<{data: unknown[]}> {
		data = this.encodeOutput(data)
		return this.encodeResults(await this.repository.updateMany(ids, data))
	}

	async delete(id: string): Promise<{data: unknown}> {
		return await this.repository.delete(id)
	}

	async deleteMany(ids: string[]): Promise<{data: unknown[]}> {
		return await this.repository.deleteMany(ids)
	}
}
