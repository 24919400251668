import {BaseControllerByProject} from './base.controller.by-project'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'
import {ModelSetupJobService, modelSetupJobService} from '../services/model-setup-job.service'

function createParamsToDto(params: {
	tenantId: string
	modelName: string
	modelPath: string
	config: {forgeEngine: string}
	coordinatesOffset: {x: number; y: number; z: number}
}) {
	return {
		modelName: params.modelName,
		modelPath: params.modelPath,
		config: params.config,
		coordinatesOffset: params.coordinatesOffset,
	}
}

class ModelSetupJobQueryParams extends ByFreeTextKeyQueryParams {
	protected freeTextKey = 'modelName'
}

class ModelSetupJobController extends BaseControllerByProject<ModelSetupJobService> {
	service = modelSetupJobService
	query = new ModelSetupJobQueryParams()

	async create(params: any): Promise<{data: any}> {
		const {projectId, ...data} = params.data
		if (!projectId) throw new Error('projectId not informed')
		if ('modelId' in data) {
			return this.service.createFromModel(projectId, {
				modelName: data.modelName,
				modelId: data.modelId,
				shallowCopy: data.shallowCopy,
			})
		} else {
			const createDto = createParamsToDto(data)
			return this.service.create(projectId, createDto)
		}
	}

	async update(params: {
		id: string
		data:
			| {
					type: 'start'
					projectId: string
			  }
			| {
					type: 'update'
					projectId: string
					modelName: string
					coordinatesOffset: {x: number; y: number; z: number}
			  }
	}): Promise<{data: any}> {
		if (!params.data.projectId) throw new Error('ProjectId not passed to controller update() method.')
		return params.data.type === 'update'
			? this.service.update(params.data.projectId, params.id, {
					modelName: params.data.modelName,
					coordinatesOffset: params.data.coordinatesOffset,
			  })
			: this.service.startModelSetupJobByProjectId(params.data.projectId, params.id)
	}
}

export const modelSetupJobController = new ModelSetupJobController()
