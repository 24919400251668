import {BaseControllerByProject} from './base.controller.by-project'
import {reclassificationService, ReclassificationService} from '../services/reclassification.service'

export class ReclassificationController extends BaseControllerByProject<ReclassificationService> {
	readonly service: ReclassificationService = reclassificationService

	async update(params: any): Promise<{data: unknown}> {
		const {data} = params
		const newParams = {
			...params,
			data: {
				name: data.name,
				reviewCompanyManager: data.reviewCompanyManager,
				reclassificationCompanyManager: data.reclassificationCompanyManager,
				status: data.status,
			},
		}
		return super.update(newParams)
	}

	async create(params: any): Promise<{data: unknown}> {
		if (params.data.type === 'training') {
			return this.service.createReclassificationTasksForTraining(this.projectId(), params.data)
		} else if (params.data.type === 'createNewAnalysisVersion') {
			return this.service.createNewAnalysisVersionReclassificationTasks(params.projectId, params.data.id)
		}
		return super.create(params)
	}

	async getManyReference(params: any) {
		const {
			target,
			id,
			filter: {projectId},
		} = params
		if (target === 'version' && projectId) {
			return this.service.findByVersionId(projectId, id)
		} else {
			return super.getManyReference(params)
		}
	}
}

export const reclassificationsController = new ReclassificationController()
