export const withCheckStatusData = (check: any, validated?: boolean) => {
	let statusName
	let statusColor
	let statusBackgroundColor
	switch (check?.status) {
		case 'not_started':
		case 'created':
			statusName = 'NOT STARTED'
			statusBackgroundColor = '#6b717b'
			statusColor = '#fff'
			break
		case 'validating':
			statusName = 'VALIDATING'
			statusBackgroundColor = '#6b717b'
			statusColor = '#fff'
			break
		case 'errored':
		case 'failed':
			statusName = 'ERROR'
			statusBackgroundColor = '#e60103'
			statusColor = '#fff'
			break
		case 'warning':
			statusName = 'WARNING'
			statusBackgroundColor = '#fcd116'
			statusColor = '#000'
			break
		case 'valid':
		case 'passed':
			statusName = 'VALIDATED'
			statusBackgroundColor = '#00c781'
			statusColor = '#fff'
			break
		case 'manual':
			statusName = validated ? 'MANUAL-VALIDATED' : 'MANUAL'
			statusBackgroundColor = validated ? '#00c781' : '#1c62dc'
			statusColor = '#fff'
			break
		default:
			throw Error(`Unrecognized the "${check?.status}" input check status.`)
	}
	return {...check, statusName, statusBackgroundColor, statusColor}
}
