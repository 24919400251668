import {BaseController} from './base.controller'
import {ProjectFileService, projectFileService, rootFileService, RootFileService} from '../services/files.service'
import {BaseControllerByProject} from './base.controller.by-project'
import {QueryParams} from '../utils/base.query-params'
import {md5PartialHash} from '../../../utils/file.utils'

async function fileToDTO(file: File): Promise<any> {
	return {
		filename: file.name,
		size: file.size,
		type: file.type,
		hash: await md5PartialHash(file),
	}
}

function modelTypeToFileEnd(modelType: string): string[] {
	switch (modelType) {
		case 'IFC':
			return [`filename=~.ifc=si`]
		case 'Revit':
			return [`filename=~.rvt=si`]
		case 'Navisworks':
			return [`filename=~.nwc=si`, `filename=~.nwd=si`]
		default:
			throw new Error(`Model type ${modelType} not defined for file ending`)
	}
}

class FileQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, fileEnd, modelType, status, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`filename=~"${q}"=si`)
		if (fileEnd) filterQuery.push(`filename=~"${fileEnd}"=si`)
		if (status) filterQuery.push(`status==${status}`)
		if (modelType) {
			const modelTypeFilter = modelTypeToFileEnd(modelType)
			filterQuery.push(modelTypeFilter.length === 1 ? modelTypeFilter[0] : `(${modelTypeFilter.join(',')})`)
		}
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `${filterQuery.join(';')}` : null
	}
}

const createImplementation = async (
	files: any,
	serviceCreate: (dto: any) => Promise<{data: any}>,
): Promise<{data: any}> => {
	const results: any[] = []
	for (const file of files) {
		const dto = await fileToDTO(file.rawFile)
		const {data} = await serviceCreate(dto)
		results.push({entity: data, rawFile: file.rawFile})
	}
	return {data: {results, id: '1'}}
}

class RootFileController extends BaseController<RootFileService> {
	service = rootFileService
	query = new FileQueryParams()

	create({data: {files}}: any): Promise<{data: any}> {
		return createImplementation(files, this.service.create.bind(this.service))
	}
}

class ProjectFileController extends BaseControllerByProject<ProjectFileService> {
	service = projectFileService
	query = new FileQueryParams()

	create({data: {files, projectId}}: any): Promise<{data: any}> {
		return createImplementation(files, this.service.create.bind(this.service, projectId))
	}
}

export const rootFileController = new RootFileController()
export const projectFileController = new ProjectFileController()
