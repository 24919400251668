import React from 'react'
import {Create, SimpleForm, SelectInput, ReferenceInput, FormDataConsumer, AutocompleteInput} from 'react-admin'
import {useGetRoles} from '../auths/roles'

export function TeamMemberCreate(props: any) {
	const params = new URLSearchParams(props.location.search)
	const teamId = params.get('teamId')
	const userId = params.get('userId')

	const roles = useGetRoles()

	const redirect = (basePath, id, data) => {
		return userId ? `/users/${userId}/show/1` : `/teams/${teamId}/show/1`
	}
	const transform = data => {
		return {
			...data,
			...(teamId && {parent: teamId}),
		}
	}
	return (
		<Create {...props} undoable={false} transform={transform}>
			<SimpleForm redirect={redirect} initialValues={{team: teamId}}>
				{!userId && (
					<ReferenceInput
						label="User"
						name="user"
						source="users"
						reference="users"
						sort={{field: 'email', order: 'ASC'}}
					>
						<AutocompleteInput optionText={record => `${record.email}`} multiline />
					</ReferenceInput>
				)}

				<FormDataConsumer>
					{({formData, ...rest}) =>
						!formData.project &&
						!teamId && (
							<ReferenceInput
								label="Team"
								name="team"
								source="teams"
								reference="teams"
								sort={{field: 'name', order: 'ASC'}}
								allowEmpty
							>
								<AutocompleteInput optionText={record => `${record.name || ''}`} multiline />
							</ReferenceInput>
						)
					}
				</FormDataConsumer>
				<SelectInput source="role" choices={roles} initialValue={'REGISTERED_USER'} />
			</SimpleForm>
		</Create>
	)
}
