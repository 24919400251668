import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import React from 'react'

const CreateFakeAnalysisFromModel = props => {
	return !props.record ? null : (
		<Button
			component={Link}
			to={`/analyses/create?context=createFakeAnalysis&tenantId=${props.record.tenantId}&modelId=${props.record._id}&projectId=${props.projectId}`}
			label="Upload fake analysis"
			title="Upload fake analysis"
		/>
	)
}

export {CreateFakeAnalysisFromModel}
