import {AnalysesRepository, analysisRepository} from '../repositories/analysis.repository'
import {BaseServiceMultiProject} from './base.service.multi-project'
import {encodeId} from './base.service'

// this is necessary because Analysis has a field id but encodeId sets id = _id
// because react-admin relies on data entities to have the id field as identifier
// so we set Analysis.id to Analysis.analysisId so we can use it in the views
function encodeWithAnalysisId(e: any) {
	return {...encodeId(e), analysisId: e.id}
}

export class AnalysisService extends BaseServiceMultiProject<AnalysesRepository> {
	repository = analysisRepository

	async createFakeAnalysis(dto: any) {
		return this.encodeResult(await this.repository.createFakeAnalysis(dto))
	}

	encodeResult({data, ...rest}: any): any {
		return {data: encodeWithAnalysisId(data), ...rest}
	}

	encodeResults({data, ...rest}: any): any {
		return {data: data.map(e => encodeWithAnalysisId(e)), ...rest}
	}

	// this is necessary because Analysis has a field id but encodeId sets id = _id
	// because react-admin relies on data entities to have the id field as identifier
	// so we remove Analysis.analysisId before we send it in create / update calls
	encodeOutput(data) {
		const {analysisId, ...rest} = data
		return rest
	}
}

export const analysisService = new AnalysisService()
