import React from 'react'
import {Datagrid, Labeled, SimpleShowLayout, TextField, useRefresh} from 'react-admin'

import {PublishOrUnpublishVersionButton} from './PublishOrUnpublishVersionButton'
import {ArchiveOrUnarchiveVersionButton} from './ArchiveOrUnarchiveVersionButton'
import {CreateOrViewReclassificationFromVersionButton} from '../analysis/CreateOrViewReclassificationFromVersionButton'
import {DuplicateVersionButton} from '../analysis/DuplicateVersionButton'
import {LinkToAnalysisVersion} from '../analysis/LinkToAnalysis'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {CopyIdToClipboardButton} from '../buttons/CopyIdToClipboardButton'

export function VersionsInAnalysisExpandShow(props: any) {
	const refresh = useRefresh()
	return (
		<SimpleShowLayout {...props}>
			<SRReferenceManyField
				filter={{projectId: props.projectId}}
				source="_id"
				reference="versions"
				target="analysis"
				addLabel={false}
				fullWidth
			>
				<SimpleShowLayout>
					<Datagrid>
						<Labeled label={'Id'}>
							<CopyIdToClipboardButton />
						</Labeled>
						<Labeled label={'Publish'}>
							<PublishOrUnpublishVersionButton projectId={props.projectId} />
						</Labeled>
						<Labeled label={'Version'}>
							<LinkToAnalysisVersion analysisId={props.record.id} />
						</Labeled>
						<TextField source="status" />
						<TextField source="message" />
						<ArchiveOrUnarchiveVersionButton projectId={props.projectId} />
						<DuplicateVersionButton projectId={props.projectId} refresh={refresh} />
						<CreateOrViewReclassificationFromVersionButton projectId={props.projectId} />
					</Datagrid>
				</SimpleShowLayout>
			</SRReferenceManyField>
		</SimpleShowLayout>
	)
}
