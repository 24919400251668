import {DownloadAssetsButton} from '../buttons/DownloadAssetsButton'
import {ValidateButton} from '../buttons/ValidateButton'
import {Typography} from '@material-ui/core'
import React, {useState} from 'react'
import CheckInfoModal from './CheckInfoModal'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import CheckAssetsModal from './CheckAssetsModal'
import {Link} from 'react-router-dom'
import {getInputCheckTitle} from './InputChecksMeta'

export const CheckName = props => {
	return <Typography variant={'body2'}>{getInputCheckTitle(props.record.name)}</Typography>
}
export const OutputAssetsInfo = props => {
	const [openCheckAssetsModal, setOpenCheckAssetsModal] = useState<boolean>(false)
	return (
		<>
			<CheckAssetsModal
				props={props}
				showDialog={openCheckAssetsModal}
				onClose={() => setOpenCheckAssetsModal(false)}
			></CheckAssetsModal>
			<Link to="#" onClick={() => setOpenCheckAssetsModal(true)}>
				<Typography variant={'body2'}>See details</Typography>
			</Link>
		</>
	)
}
export function ConditionalDownloadAssetsButton(props) {
	return props.record.result.assets.length ? <DownloadAssetsButton data={props} /> : null
}

export function ConditionalValidateButton(props) {
	return props.record.result.status === 'manual' ? <ValidateButton data={props} /> : null
}

export function ConditionalOutputAssetsInfoModal(props) {
	return props.record.result.message?.length ? (
		<OutputAssetsInfo data={props} />
	) : (
		<Typography variant={'body2'}>-</Typography>
	)
}

export const InfoIconButton = props => {
	const [openCheckInfoModal, setOpenCheckInfoModal] = useState<any>(false)
	return (
		<>
			<CheckInfoModal
				inputCheck={props}
				showDialog={openCheckInfoModal}
				onClose={() => setOpenCheckInfoModal(false)}
			></CheckInfoModal>
			<IconButton color="primary" onClick={() => setOpenCheckInfoModal(true)}>
				<InfoIcon />
			</IconButton>
		</>
	)
}
