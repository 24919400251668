import {Auth} from '@aws-amplify/auth'
import {getMaintenanceMode} from './maintenance-mode.token'
import {config} from './index'
import {buildRequestInstance} from 'sr-request-commons'

export const getIdToken = () =>
	Auth.currentSession().then(session => {
		//it seems this way the token refreshes.
		return session.getIdToken().getJwtToken()
	})

export const conn = buildRequestInstance(config.sr.backendUrl, getIdToken, getMaintenanceMode, () => {
	console.warn('Maintenance mode display not implemented yet!')
})

export async function getForgeAccessToken() {
	return conn.get('/v2/forge/token').then(response => response.data)
}
