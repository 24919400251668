import {CognitoUser} from '@aws-amplify/auth'
import {Cognito as auth} from '../../auth_provider'

export function handleLoginFlow(authState: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [state, setState] = authState
	return async function (
		{
			username,
			password,
		}: {
			username: any
			password: any
		},
		redirectTo: string,
	) {
		const cognitoUser: CognitoUser = await auth.login({username, password})
		console.log('Login Response:', cognitoUser)
		//@ts-ignore
		if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			setState({
				flow: 'PWD_RST',
				cognitoUser,
			})
			return null
		}
		return cognitoUser
	}
}
