import {BaseController} from './base.controller'
import {QueryParams} from '../utils/base.query-params'
import {masvService, MasvService} from '../services/masvs.service'

class MasvQueryParams extends QueryParams {
	filter(filter: any) {
		const {q} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`s3Key=~"${q}"=si`)
		return filterQuery.length ? `(${filterQuery.join(';')})` : null
	}
}

class MasvController extends BaseController<MasvService> {
	service = masvService
	query = new MasvQueryParams()

	async copyToProject(project: string, files: string[]) {
		return this.service.copyToProject(project, files)
	}

	async statusInProject(project: string, files: string[]) {
		return this.service.statusInProject(project, files)
	}
}

export const masvController = new MasvController()
