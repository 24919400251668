import {
	AnalysisProcessingJobRepository,
	analysisProcessingJobRepository,
} from '../repositories/analysis-processing-job.repository'
import {BaseServiceByProject} from './base.service.by-project'

export class AnalysisProcessingJobService extends BaseServiceByProject<AnalysisProcessingJobRepository> {
	repository = analysisProcessingJobRepository

	async startAnalysisProcessingJobByProjectId(projectId: string, id: string) {
		return this.encodeResult(await this.repository.startAnalysisProcessingJobByProjectId(projectId, id))
	}
}

export const analysisProcessingJobService = new AnalysisProcessingJobService()
