import React from 'react'
import {Create} from 'react-admin'
import {CustomBreadcrumb} from '../../theme/CustomBreadcrumb'

export const SRCreate = props => {
	return (
		<>
			<CustomBreadcrumb {...props} />
			<Create
				{...props}
				children={props.children}
				className={
					(props.noAlignActionBTNToBreadcrumb ? '' : 'align-action-btn-to-breadcrumb ') + props.className || ''
				}
			></Create>
		</>
	)
}
