import React, {useCallback, useRef} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import Popper from '@material-ui/core/Popper'
import FileStatusTable from './FileStatusTable'
import {FILE_REMOVE} from '../../actions/file.actions'

export function FileNotification(props: any) {
	const files = useSelector((state: any) => state.fileReducer)
	const dispatch = useDispatch()
	const targetRef = useRef<any>()
	const popperRef = useRef<any>(null)
	const handleToggle = useCallback((value: boolean) => {
		if (popperRef.current) {
			popperRef.current.update()
		}
	}, [])
	const handleCancel = useCallback((file: any) => {
		dispatch({type: FILE_REMOVE, payload: file})
	}, [])
	return (
		<div style={{zIndex: 100}}>
			<div ref={targetRef} style={{position: 'fixed', bottom: 30, right: 30}}></div>
			{
				<Popper
					open={files && files.length > 0}
					popperRef={popperRef}
					anchorEl={targetRef.current}
					style={{zIndex: 100}}
				>
					<FileStatusTable handleToggle={handleToggle} handleCancel={handleCancel} files={files} />
				</Popper>
			}
		</div>
	)
}
