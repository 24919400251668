import React from 'react'
import {Link} from 'react-router-dom'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {Button} from 'react-admin'

const styles = {
	button: {
		marginTop: '1em',
		marginBottom: '1em',
	},
}

const AddEOIButton = ({classes, record}) => (
	<Button
		className={classes.button}
		variant="outlined"
		component={Link}
		to={`/suggestions/create?tenantId=${record?.tenantId}&projectId=${record?.id}`}
		label="Create Eoi set"
		title="Create Eoi set"
	>
		<AddCircleOutlineIcon />
	</Button>
)

export default withStyles(styles)(AddEOIButton)
