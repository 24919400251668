import {Datagrid, DateField, Filter, FunctionField, SearchInput, SimpleShowLayout, Tab, TextField} from 'react-admin'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import React from 'react'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {Divider, Typography} from '@material-ui/core'
import AddModelUploadJobButton from '../model-upload-jobs/AddModelUploadJobButton'
import AddModelUploadJobFromModelButton from '../model-upload-jobs/AddModelUploadJobFromModelButton'
import {StartModelUploadJobButton} from '../model-upload-jobs/StartModelUploadJobButton'
import {EditModelUploadJobButton} from '../model-upload-jobs/EditModelUploadJobButton'
import {CleanUpModelUploadJobButton} from '../model-upload-jobs/CleanUpModelUploadJobButton'
import AddModelSetupJobButton from '../model-setup-jobs/AddModelSetupJobButton'
import AddModelSetupJobFromModelButton from '../model-setup-jobs/AddModelSetupJobFromModelButton'
import {StartModelSetupJobButton} from '../model-setup-jobs/StartModelSetupJobButton'
import {StatusChipOneStepModelSetupInfo} from '../model-setup-jobs/StatusChipOneStepModelSetupInfo'
import {EditModelSetupJobButton} from '../model-setup-jobs/EditModelSetupJobButton'
import {CleanUpModelSetupJobButton} from '../model-setup-jobs/CleanUpModelSetupJobButton'
import {ActiveModelIconInfo} from './ActiveModelIconInfo'
import {StatusChipInputCheckInfo} from '../input-check/StatusChipInputCheckInfo'
import {CopyIdToClipboardButton} from '../buttons/CopyIdToClipboardButton'
import {ProjectEditButton} from '../buttons/ProjectEditButton'
import {CreateFakeAnalysisFromModel} from './CreateFakeAnalysisFromModelButton'
import {useFeatureEnabled} from '../../features/feature-flags/FeatureFlagsProvider'

export const ModelsProjectTab = props => {
	const isModelSetupEnabled = useFeatureEnabled('oneStepModelSetup')
	return (
		<Tab {...props}>
			<Typography variant="h6" component="div" gutterBottom>
				Models Upload Jobs
				{isModelSetupEnabled ? ' (Legacy)' : null}
			</Typography>
			<Divider />
			<AddModelUploadJobButton {...props} />
			<AddModelUploadJobFromModelButton {...props} />
			<SRReferenceManyField
				source="_id"
				reference="model-upload-jobs"
				target="projects"
				sort={{field: 'modelName', order: 'ASC'}}
				addLabel={false}
				fullWidth
			>
				<SimpleShowLayout>
					<Filter>
						<SearchInput source="q" alwaysOn />
					</Filter>
					<Datagrid>
						<StartModelUploadJobButton projectId={props.id} />
						<TextField label="Name" source="modelName" />
						<TextField source="status" />
						<TextField label="Type" source="config.modelType" />
						<TextField style={{wordBreak: 'break-all'}} source="modelPath" />
						<TextField style={{wordBreak: 'break-all'}} source="metadataProjectFileS3Key" />
						<TextField style={{wordBreak: 'break-all'}} source="modelUrn" />
						<DateField source="createdDate" />
						<ProjectShowButton projectId={props.id} />
						<EditModelUploadJobButton projectId={props.id} />
						<CleanUpModelUploadJobButton projectId={props.id} />
					</Datagrid>
				</SimpleShowLayout>
			</SRReferenceManyField>

			{isModelSetupEnabled ? (
				<Typography variant="h6" component="div" gutterBottom>
					Models Setup Jobs
				</Typography>
			) : null}
			{isModelSetupEnabled ? <Divider /> : null}
			{isModelSetupEnabled ? <AddModelSetupJobButton {...props} /> : null}
			{isModelSetupEnabled ? <AddModelSetupJobFromModelButton {...props} /> : null}
			{isModelSetupEnabled ? (
				<SRReferenceManyField
					source="_id"
					reference="model-setup-jobs"
					target="projects"
					sort={{field: 'modelName', order: 'ASC'}}
					addLabel={false}
					fullWidth
				>
					<SimpleShowLayout>
						<Filter>
							<SearchInput source="q" alwaysOn />
						</Filter>
						<Datagrid>
							<StartModelSetupJobButton projectId={props.id} />
							<TextField label="Name" source="modelName" />
							<StatusChipOneStepModelSetupInfo label={'Status'} />
							<TextField label="Type" source="config.modelType" />
							<FunctionField label="File name" render={record => `${record.modelPath.split('/').pop()}`} />
							<DateField source="createdDate" />
							<ProjectShowButton projectId={props.id} />
							<EditModelSetupJobButton projectId={props.id} />
							<CleanUpModelSetupJobButton projectId={props.id} />
						</Datagrid>
					</SimpleShowLayout>
				</SRReferenceManyField>
			) : null}

			<Typography variant="h6" component="div" gutterBottom>
				Models
			</Typography>
			<Divider />
			<SRReferenceManyField
				source="_id"
				reference="models"
				target="projects"
				sort={{field: 'name', order: 'ASC'}}
				addLabel={false}
				fullWidth
			>
				<SimpleShowLayout>
					<Filter>
						<SearchInput source="q" alwaysOn />
					</Filter>
					<Datagrid>
						<ActiveModelIconInfo />
						<StatusChipInputCheckInfo label={'Status'} />
						<TextField source="name" />
						<TextField source="partialModels" />
						<TextField label="Type" source="config.modelType" />
						<DateField source="createdDate" />
						<CopyIdToClipboardButton label="Actions" />
						<ProjectShowButton projectId={props.id} />
						<ProjectEditButton projectId={props.id} />
						<CreateFakeAnalysisFromModel projectId={props.id} />
					</Datagrid>
				</SimpleShowLayout>
			</SRReferenceManyField>
		</Tab>
	)
}
