import {Button} from 'react-admin'
import React, {useState} from 'react'
import ImportExport from '@material-ui/icons/ImportExport'
import {AnalysisViewLabelTransferModal} from './AnalysisViewLabelTransferModal'

export function AnalysisViewLabelTransferButton({record, projectId, disabled}: any) {
	const [modalOpen, setModalOpen] = useState(false)
	return record ? (
		<>
			<Button title={'Import labels'} disabled={disabled} onClick={() => setModalOpen(true)}>
				<ImportExport />
			</Button>
			{!modalOpen ? null : (
				<AnalysisViewLabelTransferModal
					showDialog={modalOpen}
					onClose={() => setModalOpen(false)}
					record={record}
					projectId={projectId}
				/>
			)}
		</>
	) : null
}
