import {BaseControllerByProject} from './base.controller.by-project'
import {versionService, VersionService} from '../services/version.service'

class VersionController extends BaseControllerByProject<VersionService> {
	service = versionService

	async getManyReference(params: any) {
		const {
			target,
			id,
			filter: {projectId, ...restFilter},
		} = params
		if (target === 'analysis' && projectId) {
			const {filter, sort, skip, limit} = this.query.build({...params, filter: restFilter})
			return this.service.findByAnalysisId(projectId, id, filter, sort, skip, limit)
		} else {
			return super.getManyReference(params)
		}
	}
}

export const versionController = new VersionController()
