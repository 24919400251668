import {ResourceName} from '../../../config/resources.config'
import {BaseRepositoryMultiProject} from './base.repositories.multi-project'

export class AnalysesRepository extends BaseRepositoryMultiProject {
	name: ResourceName = 'analyses'

	async createFakeAnalysis(params: {
		projectId: string
		data: {
			modelId: string
			metadata: object[]
			percentage: number
		}
	}): Promise<{data: any}> {
		const result = await this.conn.post(
			this.api.buildProjectUrl(params.projectId, undefined, 'generateFakeAnalysis'),
			params.data,
		)
		return {
			data: result.data,
		}
	}
}

export const analysisRepository = new AnalysesRepository()
