import {conn} from '../../../config/conn.config'
import {throwError} from '../../../utils/errors.utils'
import {BaseRepository} from './base.repository'
import {Query} from './query'
import {ResourceName} from '../../../config/resources.config'
import {getResourceApi} from '../../resources_api/resources.apis'
import {ResourceApi} from '../../resources_api/ResourceApi'

export class TeamMemberRepository extends BaseRepository {
	readonly conn = conn
	name: ResourceName = 'team-members'

	get api(): ResourceApi {
		return getResourceApi(this.name)
	}

	async getListForTeam(teamId: string, query: Query): Promise<{data: unknown[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {params: {parent: teamId, query}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling teams/${teamId}/members.getList: ${e.message}`)
		}
	}

	async getManyReference(target: string, id: string, query: Query): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {params: {...query, parent: id}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}
}

export const teamMemberRepository = new TeamMemberRepository()
