import {RadioButtonGroupInput, required, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin'
import React from 'react'
import {SREdit} from '../SRComponents/SREdit'

export function FilterPresetEdit(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	return (
		<SREdit {...props} entityNameProp="name" showBreadcrumb projectId={projectId} fileId={props.id}>
			<SimpleForm
				redirect={`/projects/${projectId}/show/filter-presets`}
				toolbar={
					<Toolbar {...props}>
						<SaveButton />
					</Toolbar>
				}
			>
				<TextInput source="name" validate={[required('Filter preset name cannot be empty!')]} />
				<RadioButtonGroupInput
					source="scope"
					choices={[
						{id: 'user', name: 'Private'},
						{id: 'project', name: 'Shared'},
					]}
				/>
			</SimpleForm>
		</SREdit>
	)
}
