import {Datagrid, DateField, Filter, ReferenceField, SelectInput, SimpleShowLayout, Tab, TextField} from 'react-admin'
import React from 'react'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {CreateReclassificationsForTrainingButton} from '../projects/CreateReclassificationsForTrainingButton'
import {LinkToReclassificationOverview} from './LinkToReclassificationOverview'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import {ProjectEditButton} from '../buttons/ProjectEditButton'
import AddNewAnalysisVersionReclassificationTaskButton from '../projects/AddNewAnalysisVersionReclassificationTaskButton'

export const ReclassificationsProjectTab = props => (
	<Tab {...props}>
		<SRReferenceManyField
			source="_id"
			sort={{field: 'name', order: 'ASC'}}
			addLabel={false}
			fullWidth
			reference={'reclassifications'}
			target={'projects'}
			filter={{status: 'in progress'}}
		>
			<SimpleShowLayout>
				<CreateReclassificationsForTrainingButton projectId={props.id} />
				<Filter>
					<SelectInput
						alwaysOn
						source="status"
						allowEmpty
						choices={[
							{id: 'not started', name: 'Not started'},
							{id: 'in progress', name: 'In progress'},
							{id: 'finished', name: 'Finished'},
						]}
					/>
				</Filter>
				<Datagrid>
					<ReferenceField label={'Analysis Name'} link={false} source="version" reference="versions">
						<ReferenceField link={false} reference={'analyses'} source={'analysis'}>
							<TextField source="name" />
						</ReferenceField>
					</ReferenceField>
					<ReferenceField link={false} reference={'versions'} source={'version'}>
						<TextField source="versionNumber" />
					</ReferenceField>
					<TextField source="name" />
					<DateField source="createdDate" />
					<TextField source="status" />
					<LinkToReclassificationOverview />
					<ProjectShowButton projectId={props.id} />
					<ProjectEditButton projectId={props.id} />
					<AddNewAnalysisVersionReclassificationTaskButton projectId={props.id} {...props} />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
