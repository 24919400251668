import React, {useEffect, useState} from 'react'
import {
	DeleteButton,
	FunctionField,
	Labeled,
	ReferenceField,
	Tab,
	TabbedShowLayout,
	TextField,
	useQuery,
	useRecordContext,
} from 'react-admin'
import {DownloadFileButton} from '../buttons/DownloadFileButton'
import {getFullName} from '../../utils/getters.utils'
import {SRShow} from '../SRComponents/SRShow'
import Button from '@material-ui/core/Button'
import {usePreserveQuerystring} from '../../hooks/UsePreserveQuerystring'
import GetAppIcon from '@material-ui/icons/GetApp'
import {scansService} from '../../data_provider/v3/services/scans.service'
import {saveAs} from 'file-saver'
import {format} from 'date-fns'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'

export function ConditionalProjectReferenceField(props) {
	const {record} = useRecordContext(props)
	return record.scope === 'project' ? (
		<Labeled label="Project">
			<ReferenceField
				{...props}
				source={'scopeId'}
				reference={'projects'}
				link={record => `/projects/${record.scopeId}/show`}
			>
				<TextField source="name" />
			</ReferenceField>
		</Labeled>
	) : null
}

function ShowSpecificMetadata(props) {
	if (!props.e57MainProps) return null
	return (
		<Table>
			<TableBody>
				<TableRow key="prop1">
					<TableCell>creationDateTime</TableCell>
					<TableCell>{format(new Date(props.e57MainProps.creationDateTime), 'DD/MM/YYYY')}</TableCell>
				</TableRow>
				<TableRow key="prop2">
					<TableCell>numImages</TableCell>
					<TableCell>{props.e57MainProps.numImages}</TableCell>
				</TableRow>
				<TableRow key="prop3">
					<TableCell>numScans</TableCell>
					<TableCell>{props.e57MainProps.numScans}</TableCell>
				</TableRow>
				<TableRow key="prop4">
					<TableCell>numScansWithColor</TableCell>
					<TableCell>{props.e57MainProps.numScansWithColor}</TableCell>
				</TableRow>
				<TableRow key="prop5">
					<TableCell>typeOfScanners</TableCell>
					<TableCell>{props.e57MainProps.typeOfScanners.join(', ')}</TableCell>
				</TableRow>
				<TableRow key="prop6">
					<TableCell>georeferencing</TableCell>
					<TableCell>
						x: {props.e57MainProps.georeferencing.x}, y: {props.e57MainProps.georeferencing.y}, z:{' '}
						{props.e57MainProps.georeferencing.z}
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
}
export function FilesShow(props) {
	const [e57MainProps, setE57MainProps] = useState()
	const searchParams = usePreserveQuerystring('projectId')
	const projectId = searchParams.get('projectId')
	const redirect = () => (projectId ? `/files` : `/projects/${projectId}/show/files`)
	const {data} = useQuery({
		type: 'getOne',
		resource: props.resource,
		payload: {id: props.id},
	})
	const isE57 = data?.filename.slice(-3) === 'e57'

	const downloadXml = async () => {
		const xmlString = await scansService.getScanXml(projectId!, props.id)
		const xmlFile = new Blob([xmlString], {type: 'text/xml; charset=utf-8;'})
		saveAs(xmlFile, `E57-${props.id}-metadata-xml-${format(new Date(), 'yyyyMMdd-HHmm')}.xml`)
	}

	useEffect(() => {
		if (!projectId || !isE57) return
		scansService.getScanFilesSpecificMetadata(projectId, props.id).then(setE57MainProps)
	}, [projectId, props.id, isE57])

	return (
		<SRShow
			{...props}
			entityNameProp="filename"
			showBreadcrumb={projectId !== 'undefined'}
			projectId={projectId}
			fileId={props.id}
			listTabName="files"
		>
			<TabbedShowLayout>
				<Tab label="file properties">
					<ConditionalProjectReferenceField />
					<TextField source="filename" />
					<TextField source="status" />
					<TextField source="contentType" />
					<TextField source="size" />
					<TextField source="createdDate" label={'Upload date'} />
					<ReferenceField label="Uploaded by" source="createdBy" reference="users" link="show">
						<FunctionField render={getFullName} />
					</ReferenceField>
					<TextField source="s3Key" />
					<DownloadFileButton fileId={props.id} />
					<DeleteButton redirect={redirect} undoable={false} />
				</Tab>
				{data && isE57 && projectId ? (
					<Tab label="scan metadata">
						<ShowSpecificMetadata e57MainProps={e57MainProps} />
						<Button
							onClick={() => {
								downloadXml()
							}}
							style={{border: '1px solid black', margin: '40px 15px'}}
							title="Download XML"
							endIcon={<GetAppIcon />}
						>
							Download XML
						</Button>
					</Tab>
				) : null}
			</TabbedShowLayout>
		</SRShow>
	)
}
