import {Button, Datagrid, TextField, TopToolbar, useMutation, useRefresh} from 'react-admin'
import React from 'react'
import {useNotify} from 'ra-core'
import {SRList} from '../../SRComponents/SRList'

function Actions(props: any) {
	const {className, exporter, filters, maxResults, ...rest} = props
	const notify = useNotify()
	const refresh = useRefresh()
	const [migrate, {loading}] = useMutation(
		{
			type: 'custom',
			resource: props.resource,
			payload: {actionName: 'migrateUp'},
		},
		{
			onSuccess: response => {
				notify(`Migrated up ${response.data.length} migrations \n${response.data.join('\n')}`, 'info')
				return refresh()
			},
		},
	)

	return (
		<TopToolbar className={className}>
			<Button onClick={migrate} disabled={loading} label="Migrate up" />
		</TopToolbar>
	)
}

export function PendingMigrationsList(props: any) {
	return (
		<SRList actions={<Actions />} title={props.options.label} bulkActionButtons={false} {...props}>
			<Datagrid>
				<TextField source="fileName" sortable={false} />
			</Datagrid>
		</SRList>
	)
}
