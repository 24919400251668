import {modelRepository, ModelRepository} from '../repositories/models.repository'
import {BaseServiceByProject} from './base.service.by-project'

export class ModelService extends BaseServiceByProject<ModelRepository> {
	repository = modelRepository

	getInputCheckAssetSignedUrls = async (projectId: string, modelId: string, checkName: string) => {
		return this.repository.getInputCheckAssetSignedUrls(projectId, modelId, checkName)
	}
	validateInputCheckCheck = async (projectId: string, modelId: string, checkName: string) => {
		return this.repository.validateInputCheckCheck(projectId, modelId, checkName)
	}
}

export const modelService = new ModelService()
