import React from 'react'

import {
	AutocompleteInput,
	Edit,
	ReferenceField,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	ShowButton,
	SimpleForm,
	TextField,
	TextInput,
	Toolbar,
	TopToolbar,
} from 'react-admin'
import {getFullName} from '../../utils/getters.utils'
import {SREdit} from '../SRComponents/SREdit'

const ReclassificationEditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
)

function ReclassificationEditActions(props) {
	return (
		<TopToolbar key={props.data?._id}>
			<ShowButton to={`${props.basePath}/${props.data?._id}/show?projectId=${props.projectId}`} />
		</TopToolbar>
	)
}

export function UpdateReclassification(props: any) {
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	const redirect = () => `/reclassifications/${props.id}/show?projectId=${projectId}`
	return (
		<SREdit
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			showBreadcrumb
			undoable={false}
			{...props}
			actions={<ReclassificationEditActions projectId={projectId} />}
		>
			<SimpleForm toolbar={<ReclassificationEditToolbar />} redirect={redirect}>
				<ReferenceField label={'Analysis Name'} link={false} source="version" reference="versions">
					<ReferenceField link={false} reference={'analyses'} source={'analysis'}>
						<TextField source="name" />
					</ReferenceField>
				</ReferenceField>
				<ReferenceField link={false} reference={'versions'} source={'version'}>
					<TextField source="versionNumber" />
				</ReferenceField>
				<TextInput label={'Name'} name={'name'} source={'name'} />
				<ReferenceInput
					label="Reclassification Manager"
					name="reclassificationCompanyManager"
					source="reclassificationCompanyManager"
					reference="users"
					validate={[required()]}
					sort={{field: 'email', order: 'ASC'}}
				>
					<AutocompleteInput optionText={getFullName} multiline />
				</ReferenceInput>
				<ReferenceInput
					label="Review Manager"
					name="reviewCompanyManager"
					source="reviewCompanyManager"
					reference="users"
					validate={[required()]}
					sort={{field: 'email', order: 'ASC'}}
				>
					<AutocompleteInput optionText={getFullName} multiline />
				</ReferenceInput>
				<SelectInput
					source="status"
					validate={[required()]}
					choices={[
						{id: 'not started', name: 'not started'},
						{id: 'in progress', name: 'in progress'},
						{id: 'finished', name: 'finished'},
					]}
				/>
			</SimpleForm>
		</SREdit>
	)
}
