import {ResourceName} from '../../../config/resources.config'
import {BaseRepository} from './base.repository'
import {throwError} from '../../../utils/errors.utils'

export class AnalysisProcessingJobSimpleRepository extends BaseRepository {
	name: ResourceName = 'analysis-processing-jobs-simple'

	async errorPayload(id: string): Promise<{data: string}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(id, 'error-payload'))
			return {
				data: response.data,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.errorPayload: ${e.message}`)
		}
	}
}

export const analysisProcessingJobSimpleRepository = new AnalysisProcessingJobSimpleRepository()
