import {BaseService} from './base.service'
import {UserRepository, userRepository} from '../repositories/users.repository'

export class UserService extends BaseService<UserRepository> {
	repository = userRepository

	async getByCompanyIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		return this.encodeResults(await this.repository.getByCompanyIdWithQuery(companyId, filter, sort, skip, limit))
	}

	async getByProjectIdWithQuery(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		return this.encodeResults(await this.repository.getByProjectIdWithQuery(projectId, filter, sort, skip, limit))
	}

	async resetPassword(id: string): Promise<void> {
		return this.repository.resetPassword(id)
	}

	async getCognitoUser(id: string): Promise<any> {
		return this.repository.getCognitoUser(id)
	}
}

export const userService = new UserService()
