import {BaseRepository} from './base.repository'
import {Query} from './query'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'

export class AuthRepository extends BaseRepository {
	name: ResourceName = 'auths'

	async getManyReference(target: string, id: string, query: Query): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {params: {...query, [target]: id}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}
}

export const authRepository = new AuthRepository()
