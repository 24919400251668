import {BaseService} from './base.service'
import {ProjectRepository, projectRepository} from '../repositories/projects.repository'
import {userRoleRepository, UserRoleRepository} from '../repositories/user-role.repository'

export class UserRoleService extends BaseService<UserRoleRepository> {
	repository = userRoleRepository

	async getByCompanyIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		return this.encodeResults(await this.repository.getByCompanyIdWithQuery(companyId, filter, sort, skip, limit))
	}
}

export const userRoleService = new UserRoleService()
