import {BaseService, encodeCompany, encodeId} from './base.service'
import {ProjectRepository, projectRepository} from '../repositories/projects.repository'
import {encodeParent} from './workspace.service'

export class ProjectService extends BaseService<ProjectRepository> {
	repository = projectRepository

	encodeResult({data, ...rest}: any): any {
		return {data: encodeParent(encodeCompany(encodeId(data))), ...rest}
	}

	encodeResults({data, ...rest}: any): any {
		return {data: data.map(e => encodeParent(encodeCompany(encodeId(e)))), ...rest}
	}

	async getByCompanyIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		return this.encodeResults(await this.repository.getByCompanyIdWithQuery(companyId, filter, sort, skip, limit))
	}

	async hasPermission(id: string, permission: string): Promise<boolean> {
		return this.repository.hasPermission(id, permission)
	}

	async hardDelete(id: string): Promise<Response> {
		return this.repository.hardDelete(id)
	}
}

export const projectService = new ProjectService()
