import React from 'react'
import {
	BulkDeleteButton,
	Datagrid,
	DateField,
	DeleteButton,
	EditButton,
	ReferenceField,
	ShowButton,
	TextField,
} from 'react-admin'

import ProjectFilters from './ProjectsFilters'
import {SRList} from '../SRComponents/SRList'

export function ProjectsList(props: any) {
	return (
		<SRList
			{...props}
			noAlignActionBTNToBreadcrumb
			showBreadcrumb
			filters={<ProjectFilters />}
			sort={{field: 'name', order: 'ASC'}}
			bulkActionButtons={props.resource && props.selectedIds && <BulkDeleteButton undoable={false} />}
		>
			<Datagrid>
				<TextField source="name" />
				<ReferenceField source="company" reference="companies">
					<TextField source="name" />
				</ReferenceField>
				<DateField source="createdDate" />
				<ShowButton />
				<EditButton />
				<DeleteButton />
			</Datagrid>
		</SRList>
	)
}
