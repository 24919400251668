export class ResourceApi {
	constructor(
		protected readonly entityPath: string,
		public readonly name: string,
		protected readonly domain?: string,
		protected readonly version: string = 'v3',
	) {}

	protected optional = part => (part ? `${part}/` : '')

	protected get domainUrl(): string {
		return (this.domain ? `${this.domain}/` : '') + `${this.version}/`
	}

	public buildSubdomainUrl(subdomain?: 'projects' | 'companies', subdomainId?: string, id?: string, tail?: string) {
		return `${this.domainUrl}${this.optional(subdomain)}${this.optional(subdomainId)}${this.entityPath}/${this.optional(
			id,
		)}${this.optional(tail)}`
	}

	public buildBaseUrl(id?: string, tail?: string) {
		return this.buildSubdomainUrl(undefined, undefined, id, tail)
	}

	public buildProjectUrl(projectId: string, id?: string, tail?: string) {
		return this.buildSubdomainUrl('projects', projectId, id, tail)
	}

	public buildCompanyUrl(companyId: string, id?: string, tail?: string) {
		return this.buildSubdomainUrl('companies', companyId, id, tail)
	}
}
