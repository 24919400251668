import {
	ArrayInput,
	AutocompleteInput,
	BooleanInput,
	FormDataConsumer,
	maxLength,
	NumberInput,
	ReferenceInput,
	required,
	ShowButton,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	TopToolbar,
} from 'react-admin'
import React, {useState} from 'react'
import {Typography} from '@material-ui/core'
import ConfirmActivatingModal from './ConfirmActivatingModal'
import ConfirmDeactivatingModal from './ConfirmDeactivatingModal'
import {SREdit} from '../SRComponents/SREdit'

export function ModelsEdit(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => `/projects/${projectId}/show/models`
	const transform = data => {
		const {
			name,
			coordinatesOffset,
			partialModels,
			ignoreMissingIds,
			eligibleForUnderConstructionElementIdsCSV,
			active,
			assembliesConfig,
		} = data
		return {
			type: 'update',
			projectId,
			coordinatesOffset,
			name,
			partialModels,
			ignoreMissingIds,
			eligibleForUnderConstructionElementIdsCSV: eligibleForUnderConstructionElementIdsCSV?._id || null,
			active,
			assembliesConfig,
		}
	}
	const [openConfirmActivatingModal, setOpenConfirmActivatingModal] = useState<boolean>(false)
	const [openConfirmDeactivatingModal, setOpenConfirmDeactivatingModal] = useState<boolean>(false)

	function ModelEditActions(props) {
		return !props.data ? null : (
			<TopToolbar key={props.data._id}>
				<ShowButton to={`${props.basePath}/${props.data._id}/show/models?projectId=${props.projectId}`} />
			</TopToolbar>
		)
	}

	return (
		<SREdit
			undoable={false}
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			listTabName="models"
			showBreadcrumb
			transform={transform}
			actions={<ModelEditActions projectId={projectId} />}
		>
			<SimpleForm redirect={redirect}>
				<FormDataConsumer>
					{({formData}) => (
						<BooleanInput
							label={'Active Model'}
							source="active"
							defaultValue={false}
							helperText={
								'Clients will be able to request analysis on this model.\n' +
								'Only one model can be marked as an active model.'
							}
							onClick={
								formData.active
									? () => setOpenConfirmDeactivatingModal(true)
									: () => setOpenConfirmActivatingModal(true)
							}
						/>
					)}
				</FormDataConsumer>
				<ConfirmActivatingModal
					showDialog={openConfirmActivatingModal}
					onClose={() => setOpenConfirmActivatingModal(false)}
				></ConfirmActivatingModal>
				<ConfirmDeactivatingModal
					showDialog={openConfirmDeactivatingModal}
					onClose={() => setOpenConfirmDeactivatingModal(false)}
				></ConfirmDeactivatingModal>
				<TextInput source="name" label="Model Name" validate={[required()]} />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Translation coordinates (in mm)
				</Typography>
				<NumberInput source="coordinatesOffset.x" label="X" validate={[required()]} />
				<NumberInput source="coordinatesOffset.y" label="Y" validate={[required()]} />
				<NumberInput source="coordinatesOffset.z" label="Z" validate={[required()]} />
				<Typography variant="subtitle1" component="div">
					Define elements eligible for under construction label
				</Typography>
				<ReferenceInput
					label={`CSV file`}
					source="eligibleForUnderConstructionElementIdsCSV._id"
					reference="project-files"
					filterToQuery={q => ({q, fileEnd: '.csv', status: 'uploaded'})}
				>
					<AutocompleteInput optionText="filename" multiline />
				</ReferenceInput>
				<BooleanInput
					label={'Ignore missing global ids'}
					source="ignoreMissingIds"
					validate={[required()]}
					defaultValue={false}
					helperText={
						'If used, the global ids from the eligible for under construction element ids file not existing in the model will be ignored.'
					}
				/>
				<Typography variant="subtitle1" component="div">
					Define partial models for incremental delivery
				</Typography>
				<ArrayInput source="partialModels">
					<SimpleFormIterator>
						<TextInput source="name" label="Name" validate={[required()]} />
						<ReferenceInput
							label="Global Ids CSV File"
							source="externalIdsProjectFileId"
							reference="project-files"
							filterToQuery={q => ({q, fileEnd: '.csv'})}
							validate={[required()]}
						>
							<AutocompleteInput optionText="filename" multiline />
						</ReferenceInput>
					</SimpleFormIterator>
				</ArrayInput>
				<Typography variant="subtitle1" component="div">
					Define assemblies configuration
				</Typography>
				<ArrayInput source="assembliesConfig">
					<SimpleFormIterator>
						<TextInput
							source="name"
							label="Code name"
							validate={[required(), maxLength(4, 'The code name can be up to 4 chars long')]}
						/>
						<ReferenceInput
							label="Assembly Config Json File"
							source="configProjectFileId"
							reference="project-files"
							filterToQuery={q => ({q, fileEnd: '.json'})}
							validate={[required()]}
						>
							<AutocompleteInput optionText="filename" multiline />
						</ReferenceInput>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</SREdit>
	)
}
