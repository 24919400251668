import React from 'react'
import {Edit} from 'react-admin'
import {CustomBreadcrumb} from '../../theme/CustomBreadcrumb'
import {SRPagination} from '../pagination/SRPagination'

export const SREdit = props => {
	return (
		<>
			<CustomBreadcrumb {...props} />
			<Edit
				{...props}
				pagination={<SRPagination />}
				children={props.children}
				className={
					(props.noAlignActionBTNToBreadcrumb ? '' : 'align-action-btn-to-breadcrumb ') + props.className || ''
				}
			/>
		</>
	)
}
