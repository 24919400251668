import {Query} from '../repositories/query'

export class QueryParams {
	ignoreFilter = ['ancestor', 'parentOnly'] //this params are special and handled on build()
	filter(filter: any) {
		const filterQuery: string[] = []
		for (const param in filter) {
			if (this.ignoreFilter.includes(param)) continue
			if (Array.isArray(filter[param])) {
				filterQuery.push(`${param}=in=(${filter[param].join(',')})`)
			} else {
				filterQuery.push(`${param}==${filter[param]}`)
			}
		}
		return filterQuery.length ? `(${filterQuery.join(';')})` : null
	}

	sort({field, order}: any) {
		if (field === 'id') field = ''
		let sortItems: string[] = []
		if (field) sortItems.push(`${order === 'DESC' ? '-' : ''}${field}`)
		return sortItems.length ? `${sortItems.join(',')}` : null
	}

	pagination(pagination: any) {
		if (pagination.page <= 0) pagination.page = 1
		if (pagination.perPage <= 0) pagination.page = 10
		const skip = pagination.perPage * (pagination.page - 1)
		const limit = pagination.perPage
		return [skip, limit]
	}

	build(params: any): Query {
		const {filter, sort, pagination} = params
		const _filter = this.filter(filter)
		const _sort = this.sort(sort)
		const [skip, limit] = this.pagination(pagination)
		const ancestor = params.filter.ancestor || null
		const parentOnly = params.filter.parentOnly || null
		return {filter: _filter, sort: _sort, skip, limit, ancestor, parentOnly}
	}
}

export class ByFreeTextKeyQueryParams extends QueryParams {
	protected freeTextKey = 'name'
	filter(filter: any) {
		let {q, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`${this.freeTextKey}=~"${q}"=si`)
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `(${filterQuery.join(';')})` : null
	}
}
