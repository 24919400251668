import React from 'react'
import {ChipField} from 'react-admin'
import {withCheckStatusData} from '../../utils/inputCheck.utils'

export function StatusChipInputCheckInfo(record: any) {
	const check = record?.record?.inputCheck || record?.record?.result
	const validated = record?.record?.validated
	const chipRecord = check && withCheckStatusData(check, validated)
	return (
		<>
			{!check ? (
				'-'
			) : (
				<ChipField
					record={chipRecord}
					source="statusName"
					style={{background: chipRecord.statusBackgroundColor, color: chipRecord.statusColor}}
				/>
			)}
		</>
	)
}
