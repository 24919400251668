import {BaseRepositoryByProject} from './base.repositories.by-project'
import {ResourceName} from '../../../config/resources.config'
import {throwError} from '../../../utils/errors.utils'

export class ModelRepository extends BaseRepositoryByProject {
	name: ResourceName = 'models'

	async getList(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: unknown[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, 'with-checks'), {
				params: {filter, sort, skip, limit},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getOne(projectId: string, id: string): Promise<{data: unknown}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, 'with-checks', id))
			return {
				data: response.data,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getOne: ${e.message}`)
		}
	}

	async getManyReference(
		target: string,
		id: string,
		filter: string | null,
		sort: string | null,
		skip: any,
		limit: any,
	): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(id, 'with-checks'), {
				params: {filter, sort, skip, limit},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getMany(projectId: string, ids: string[]): Promise<{data: unknown[]}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, 'with-checks'), {params: {ids}})
			return {
				data: response.data.result,
			}
		} catch (e) {
			throwError(`Error while calling ${this.api.buildProjectUrl}.getMany: ${e.message}`)
		}
	}

	async getInputCheckAssetSignedUrls(projectId: string, modelId: string, checkName: string) {
		const response = await this.conn.get(this.api.buildProjectUrl(projectId, modelId, `checks/${checkName}/assets`))
		return response.data
	}

	async validateInputCheckCheck(projectId: string, modelId: string, checkName: string) {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, modelId, `checks/${checkName}/validate`))
		return response.data
	}

	async update(projectId: string, id: string, data: any): Promise<{data: unknown}> {
		await this.conn.put(this.api.buildProjectUrl(projectId, id), data)
		return this.getOne(projectId, id)
	}
}

export const modelRepository = new ModelRepository()
