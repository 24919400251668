import {DeleteButton, SaveButton, SimpleForm, SimpleFormProps, Toolbar} from 'react-admin'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
})

const CustomToolbar = (props: any) => (
	<Toolbar {...props} classes={useStyles()}>
		<SaveButton />
		<DeleteButton undoable={false} redirect={props.redirect} />
	</Toolbar>
)

export function SRSimpleForm({children, ...props}: SimpleFormProps) {
	return <SimpleForm children={children} {...props} toolbar={<CustomToolbar />} />
}
