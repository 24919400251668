import {BaseRepositoryByProject} from './base.repositories.by-project'
import {ResourceName} from '../../../config/resources.config'
import {throwError} from '../../../utils/errors.utils'

export class ScansRepository extends BaseRepositoryByProject {
	name: ResourceName = 'scans'

	async getList(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: unknown[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, 'with-checks'), {
				params: {filter, sort, skip, limit},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}
	async getOne(projectId: string, id: string): Promise<{data: unknown}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, 'with-checks', id))
			return {
				data: response.data,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getOne: ${e.message}`)
		}
	}
	async getManyReference(
		target: string,
		id: string,
		filter: string | null,
		sort: string | null,
		skip: any,
		limit: any,
	): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(id, 'with-checks'), {
				params: {filter, sort, skip, limit},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}
	async getMany(projectId: string, ids: string[]): Promise<{data: unknown[]}> {
		try {
			const response = await this.conn.get(this.api.buildProjectUrl(projectId, 'with-checks'), {params: {ids}})
			return {
				data: response.data.result,
			}
		} catch (e) {
			throwError(`Error while calling ${this.api.buildProjectUrl}.getMany: ${e.message}`)
		}
	}
	async getInputCheckAssetSignedUrls(projectId: string, scanId: string, checkName: string) {
		const response = await this.conn.get(this.api.buildProjectUrl(projectId, scanId, `checks/${checkName}/assets`))
		return response.data
	}
	async validateInputCheckCheck(projectId: string, scanId: string, checkName: string) {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, scanId, `checks/${checkName}/validate`))
		return response.data
	}

	async getScanXml(projectId: string, fileId: string) {
		const response = await this.conn.get(this.api.buildProjectUrl(projectId, '', `e57/${fileId}/metadata/xml`))
		return response.data
	}

	async getScanFilesSpecificMetadata(projectId: string, fileId: string) {
		const response = await this.conn.get(this.api.buildProjectUrl(projectId, '', `e57/${fileId}/metadata/main-props`))
		return response.data
	}
}

export const scansRepository = new ScansRepository()
