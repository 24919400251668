import {BaseControllerByProject} from './base.controller.by-project'
import {ElementTypesService, elementTypesService} from '../services/element-types.service'

class ElementTypesController extends BaseControllerByProject<ElementTypesService> {
	service = elementTypesService

	async getElementTypes({modelId}: {modelId: string}): Promise<string[][]> {
		return this.service.getElementTypes(this.projectId(), modelId)
	}
}

export const elementTypesController = new ElementTypesController()
