import React, {useState} from 'react'
import {Datagrid, FunctionField, TextField} from 'react-admin'
import MasvFilters from './MasvFilters'
import {BulkImportToProjectButton} from './BulkImportToProjectButton'
import {MasvListActions} from './MasvListActions'
import {SRList} from '../SRComponents/SRList'

export function MasvList(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId') || null
	const [masvStatus, setMasvStatus] = useState<any[]>([])
	const getStatus = record => {
		const masv = (masvStatus || []).find(e => e._id === record.id)
		return masv?.status || ''
	}
	return (
		<SRList
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			actions={<MasvListActions />}
			filters={<MasvFilters projectId={projectId} setMasvStatus={setMasvStatus} />}
			sort={{field: 's3Key', order: 'ASC'}}
			bulkActionButtons={<BulkImportToProjectButton projectId={projectId} />}
		>
			<Datagrid>
				<TextField source="s3Key" label="filename" />
				<FunctionField label="status" render={getStatus} />
				<TextField source="contentType" />
				<TextField source="size" />
				<TextField source="createdDate" />
			</Datagrid>
		</SRList>
	)
}
