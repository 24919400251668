import {BaseRepositoryByProject} from './base.repositories.by-project'
import {ResourceName} from '../../../config/resources.config'
import {throwError} from '../../../utils/errors.utils'

export class ElementTypesRepository extends BaseRepositoryByProject {
	name: ResourceName = 'element-types'

	async getElementTypes(projectId: string, modelId: string): Promise<string[][]> {
		try {
			return (await this.conn.get(this.api.buildProjectUrl(projectId, modelId, 'element-types'))).data
		} catch (e) {
			// @ts-ignore
			throwError(`Error while calling ${this.api.buildProjectUrl}.getElementTypes: ${e.message}`)
		}
	}
}

export const elementTypesRepository = new ElementTypesRepository()
