import {BaseController} from './base.controller'
import {userRoleService, UserRoleService} from '../services/user-role.service'
import {QueryParams} from '../utils/base.query-params'

class UserRoleQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, n, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`fullName=~"${q}"=si,email=~"${q}"=si`)
		if (n) filterQuery.push(`companyName=~"${n}"=si,projectName=~"${n}"=si`)
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `(${filterQuery.join(',')})` : null
	}
}

class UserRoleController extends BaseController<UserRoleService> {
	service = userRoleService
	query = new UserRoleQueryParams()

	async getList(params: any): Promise<{data: any[]; total: number}> {
		if (params.filter.company) {
			const {company, ...newFilter} = params.filter
			const query = this.query.build({...params, filter: newFilter})
			return this.service.getManyReference('companies', params.filter.company, query)
		}

		return super.getList(params)
	}
}

export const userRoleController = new UserRoleController()
