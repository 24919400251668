import {BaseRepositoryByProject} from './base.repositories.by-project'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'
import {getResourceApi} from '../../resources_api/resources.apis'

export class VersionsRepository extends BaseRepositoryByProject {
	name: ResourceName = 'versions'
	analysesApi = getResourceApi('analyses')

	async findByAnalysisId(
		projectId: string,
		analysisId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: any[]; total: number}> {
		try {
			const {
				data: {result, count},
			} = await this.conn.get(this.analysesApi.buildProjectUrl(projectId, analysisId, 'versions'), {
				params: {filter, sort, skip, limit},
			})
			return {
				data: result,
				total: count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getByAnalysisId: ${e.message}`)
		}
	}

	async copyVersion(projectId: string, versionId: string): Promise<{data: any}> {
		try {
			return this.conn.post(this.api.buildProjectUrl(projectId, versionId, 'copy'))
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.copyVersion: ${e.message}`)
		}
	}
}

export const versionsRepository = new VersionsRepository()
