import {
	BooleanField,
	Datagrid,
	DateField,
	Filter,
	FunctionField,
	Labeled,
	ReferenceField,
	SearchInput,
	SelectArrayInput,
	SimpleShowLayout,
	Tab,
	TextField,
} from 'react-admin'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import React from 'react'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {Divider, Typography} from '@material-ui/core'
import {getFullName} from '../../utils/getters.utils'
import {ProjectEditButton} from '../buttons/ProjectEditButton'
import AddAnalysisProcessingJobButton from '../projects/AddAnalysisProcessingJobButton'
import {StartAnalysisProcessingJobButton} from '../projects/StartAnalysisProcessingJobButton'
import {DownloadErrorPayloadButton} from '../buttons/DownloadErrorPayloadButton'
import {DeleteAnalysisProcessingJobButton} from '../analysis-processing-jobs/DeleteAnalysisProcessingJobButton'
import {AnalysesExpand} from '../analysis-view/AnalysesExpand'
import {LinkToAnalysis} from './LinkToAnalysis'
import {makeStyles} from '@material-ui/core/styles'
import {useFeatureEnabled} from '../../features/feature-flags/FeatureFlagsProvider'
import {AnalysisViewLabelTransferButton} from '../analysis-view/label-transfer/AnalysisViewLabelTransferButton'
import Box from '@material-ui/core/Box'
import {AnalysisViewImportAnalysisButton} from '../analysis-view/import-analysis/AnalysisViewImportAnalysisButton'

export const customStyles = makeStyles(theme => ({
	filterInput: {
		minWidth: theme.spacing(16),
	},
}))

export const AnalysesProjectTab = props => {
	const isLabelTransferEnabled = useFeatureEnabled('labelTransfer')
	const classes = customStyles()
	return (
		<Tab {...props}>
			<Typography variant="h6" component="div" gutterBottom>
				Analysis processing jobs
			</Typography>
			<Divider />
			<AddAnalysisProcessingJobButton projectId={props.id} {...props} />
			<SRReferenceManyField
				source="_id"
				reference="analysis-processing-jobs"
				target="projects"
				sort={{field: 'createdDate', order: 'DESC'}}
				addLabel={false}
				fullWidth
			>
				<SimpleShowLayout>
					<Filter>
						<SearchInput source="q" alwaysOn />
						<SelectArrayInput
							className={classes.filterInput}
							alwaysOn
							source="status"
							allowEmpty
							choices={[
								{id: 'not started', name: 'Not started'},
								{id: 'in progress', name: 'In progress'},
								{id: 'finished', name: 'Finished'},
								{id: 'error', name: 'Error'},
							]}
							initialValue={['not started', 'in progress']}
						/>
					</Filter>
					<Datagrid>
						<StartAnalysisProcessingJobButton projectId={props.id} />
						<BooleanField source={'manualMode'} label="Manual" />
						<TextField label="Analysis name" source="analysisMetaData.name" />
						<ReferenceField label="New version for" source="analysisId" reference="analysis-view" link={false}>
							<TextField source="name" />
						</ReferenceField>
						<TextField source="status" />
						<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
							<FunctionField render={getFullName} />
						</ReferenceField>
						<ReferenceField
							label="Scan"
							source="scanId"
							reference="scans"
							link={(record, reference) => `/${reference}/${record.scanId}/show?projectId=${props.id}`}
						>
							<TextField source="name" />
						</ReferenceField>
						<ReferenceField
							label="ROI"
							source="roiId"
							reference="rois"
							link={(record, reference) => `/${reference}/${record.roiId}/show?projectId=${props.id}`}
						>
							<TextField source="name" />
						</ReferenceField>
						<DownloadErrorPayloadButton />
						<ProjectShowButton projectId={props.id} />
						<DeleteAnalysisProcessingJobButton redirect={false} undoable={false} />
					</Datagrid>
				</SimpleShowLayout>
			</SRReferenceManyField>

			<Typography variant="h6" component="div" gutterBottom>
				Analyses
			</Typography>
			<Divider />
			<SRReferenceManyField
				source="_id"
				reference="analysis-view"
				target="project"
				sort={{field: 'name', order: 'ASC'}}
				addLabel={false}
				fullWidth
			>
				<SimpleShowLayout>
					<Box display={'flex'} justifyContent={'space-between'} flexDirection={'row'} alignContent={'center'}>
						<Filter>
							<SearchInput source="q" alwaysOn />
						</Filter>
						<AnalysisViewImportAnalysisButton projectId={props.id} />
					</Box>
					<Datagrid expand={<AnalysesExpand projectId={props.id} />}>
						<Labeled label={'Analysis Name'}>
							<LinkToAnalysis />
						</Labeled>
						<TextField source="deliveryType" label={'Type'} />
						<DateField source="createdDate" />
						<ReferenceField
							label="Model"
							source="model._id"
							reference="models"
							link={(record, reference) =>
								record.model && `/${reference}/${record.model._id}/show?projectId=${props.id}`
							}
						>
							<TextField source="name" />
						</ReferenceField>
						<ReferenceField
							label="Scan"
							source="scan"
							reference="scans"
							link={(record, reference) => record.scan && `/${reference}/${record.scan}/show?projectId=${props.id}`}
						>
							<TextField source="name" />
						</ReferenceField>
						<DateField source="scanDate" label={'Scan Date'} />
						{!isLabelTransferEnabled ? null : <AnalysisViewLabelTransferButton projectId={props.id} />}
						<ProjectEditButton projectId={props.id} />
						<ProjectShowButton projectId={props.id} />
					</Datagrid>
				</SimpleShowLayout>
			</SRReferenceManyField>
		</Tab>
	)
}
