import React from 'react'
import {
	AutocompleteInput,
	Create,
	FormTab,
	maxLength,
	minLength,
	NullableBooleanInput,
	NumberInput,
	ReferenceInput,
	regex,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
	useQuery,
} from 'react-admin'

import createDecorator from 'final-form-calculate'

import initialValuesCreate from './initialValuesCreate'
import slugify from 'slugify'
import {dataProviderV3} from '../../data_provider/data-provider.v3'
import {modelTypeChoices} from './choices.projects'
import {Typography} from '@material-ui/core'
import {srFeatures} from 'sr-feature-flags'
import {makeStyles} from '@material-ui/core/styles'
import {SRCreate} from '../SRComponents/SRCreate'

const validateProject = () => {
	// in react-admi this always validate. Must return an object with errors to work.
	return undefined
}

const validateName = [
	required('You must set a name for the project!'),
	minLength(1, 'Project name must be at least one character long'),
	maxLength(50, 'Project name must maximum 50 characters long'),
]

const validateCompany = [required('You must select a company!')]

const validateTenantId = [
	required('You must set a Tenant Id!'),
	regex(/^.+\|.+$/, "Tenant ID must be of type 'companySlug|projectSlug'"),
]

const createTenantId = (companyName: string, projectName: string) => {
	const slugifyOptions = {
		replacement: '-',
		remove: undefined,
		lower: true,
		strict: true,
	}
	const companySlug = slugify(companyName, slugifyOptions)
	const projectSlug = slugify(projectName, slugifyOptions)
	return `${companySlug}|${projectSlug}`
}

const getCompanyNameFromId = async (companyId: string) => {
	if (!companyId) return ''
	const response = await dataProviderV3.getOne('companies', {
		id: companyId,
	})
	return response.data.name ? response.data.name : ''
}

const calculateFieldsOnChange = createDecorator(
	{
		field: 'name',
		updates: {
			tenantId: async (projectNameValue: string, formValues: any) => {
				const projectName = projectNameValue || ''
				const companyIdValue = formValues.company || ''
				const companyName = await getCompanyNameFromId(companyIdValue)
				return createTenantId(companyName, projectName)
			},
		},
	},
	{
		field: 'company',
		updates: {
			tenantId: async (companyIdValue: string, formValues: any) => {
				companyIdValue = companyIdValue || ''
				const projectName = formValues.name || ''
				const companyName = await getCompanyNameFromId(companyIdValue)
				return createTenantId(companyName, projectName)
			},
		},
	},
)

const customStyles = makeStyles(() => ({
	input: {
		width: '400px',
	},
}))

export function ProjectCreate(props: any) {
	const companyId = new URLSearchParams(props.location.search).get('companyId') || null
	const classes = customStyles()
	const featureFlagDefaultValuesQuery = useQuery({
		resource: 'features',
		type: 'custom',
		payload: {actionName: 'getGlobal'},
	})
	if (featureFlagDefaultValuesQuery.loading) return null
	return (
		<SRCreate {...props} showBreadcrumb>
			<TabbedForm
				redirect={'show'}
				validate={validateProject}
				decorators={[calculateFieldsOnChange]}
				initialValues={{...initialValuesCreate, company: companyId}}
			>
				<FormTab label="main">
					<ReferenceInput
						name="company"
						source="company"
						reference="companies"
						label="Company"
						validate={validateCompany}
					>
						<AutocompleteInput optionText="name" multiline />
					</ReferenceInput>
					<TextInput name="name" source="name" label="Name" validate={validateName} multiline />
					<TextInput name="tenantId" source="tenantId" label="Tenant ID" validate={validateTenantId} multiline />
					<SelectInput alwaysOn label="Model Type" source="modelType" choices={modelTypeChoices} />
				</FormTab>

				<FormTab label="viewer">
					<Typography variant="h6" component="div" gutterBottom>
						Feature Flags
					</Typography>
					{srFeatures.featureFlags.map(flag => {
						const featureFlagDefault = featureFlagDefaultValuesQuery.data?.find(f => f.id === flag.id)
						return (
							<NullableBooleanInput
								className={classes.input}
								sx={{width: 400}}
								label={flag.id}
								source={`viewerFeatureFlags.${flag.id}`}
								helperText={`${flag.description} (global: ${featureFlagDefault?.value}, source: ${featureFlagDefault?.layer})`}
								nullLabel="Inherit"
							/>
						)
					})}
					<Typography variant="h6" component="div" gutterBottom>
						Config
					</Typography>
					<SelectInput
						alwaysOn
						label="CSV Export Format"
						source="viewerFeatureFlags.csvExportFormat"
						allowEmpty
						choices={[
							{id: 'standard', name: 'standard'},
							{id: 'extended', name: 'extended'},
						]}
					/>
					<NumberInput
						label="Force near radius"
						source={'viewerFeatureFlags.forceNearRadius'}
						helperText={
							"If the viewer has clipping issues in the reclassification viewer, this can be used to set the 'nearRadius' which is passed to the viewer loading options for very large objects. To fix the clipping issues, there are two alternatives: Setting it to 0 will force the viewer to use the near / far plane calculations used for normal sized models, while setting it to a value above 0 will most likely set the near and far values to fix values where far = near*1e4. Which alternative is more reliable usually depends on the model."
						}
					/>
					<NumberInput
						label="Element type tree max depth"
						source={'viewerFeatureFlags.maxElementTypeTreeDepth'}
						helperText={
							"Use this setting to declare how many depth levels the element type tree should have. Leve it empty if you don't want to restrict it and use the full tree instead. Specially useful for huge models where performance gets really degraded rendering the element tree selector in the viewer."
						}
					/>
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
