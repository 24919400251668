import React from 'react'
import {
	EditButton,
	FunctionField,
	NumberField,
	ReferenceField,
	Show,
	SimpleShowLayout,
	TextField,
	TopToolbar,
} from 'react-admin'
import {LinkToReclassificationOverview} from './LinkToReclassificationOverview'
import {SRShow} from '../SRComponents/SRShow'

function ShowActions({basePath, data, projectId}: any) {
	return (
		<TopToolbar>
			<EditButton to={`${basePath}/${data?._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

export function ShowReclassification(props) {
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	return (
		<SRShow
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<SimpleShowLayout>
				<ReferenceField label={'Analysis Name'} link={false} source="version" reference="versions">
					<ReferenceField link={false} reference={'analyses'} source={'analysis'}>
						<TextField source="name" />
					</ReferenceField>
				</ReferenceField>
				<ReferenceField link={false} reference={'versions'} source={'version'}>
					<TextField source="versionNumber" />
				</ReferenceField>
				<ReferenceField link={false} reference={'versions'} source={'versionCreated'}>
					<TextField source="versionNumber" />
				</ReferenceField>
				<TextField source={'name'} />
				<TextField source={'status'} />
				<ReferenceField label="Reclassification Manager" source="reclassificationCompanyManager" reference="users">
					<FunctionField render={user => `${user?.firstName} ${user?.lastName}`} />
				</ReferenceField>
				<ReferenceField label="Review Manager" source="reviewCompanyManager" reference="users">
					<FunctionField render={user => `${user?.firstName} ${user?.lastName}`} />
				</ReferenceField>
				<NumberField source={'confidenceThreshold'} label={'Confidence Threshold'} />
				<NumberField source={'packageSize'} label={'Work Package Size'} />
				<LinkToReclassificationOverview />
			</SimpleShowLayout>
		</SRShow>
	)
}
