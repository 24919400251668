import React from 'react'
import {Link} from 'react-router-dom'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {Button} from 'react-admin'

const styles = {
	button: {
		marginTop: '1em',
	},
}

const AddProjectFileButton = ({classes, id}) => {
	return (
		<Button
			className={classes.button}
			variant="outlined"
			component={Link}
			to={`/project-files/create?projectId=${id}`}
			label="Add file"
			title="Add file"
		>
			<AddCircleOutlineIcon />
		</Button>
	)
}

export default withStyles(styles)(AddProjectFileButton)
