import React from 'react'
import {
	Edit,
	maxLength,
	minLength,
	ReferenceField,
	required,
	TextField,
	TextInput,
	TabbedForm,
	FormTab,
	AutocompleteInput,
	ReferenceInput,
	SimpleShowLayout,
	useGetOne,
} from 'react-admin'

const validateName = [
	required('You must set a name for the team!'),
	minLength(1, 'Team name must be at least one character long'),
	maxLength(50, 'Team name must maximum 50 characters long'),
]

export function WorkspaceEdit(props: any) {
	const {data, loading, error} = useGetOne('workspaces', props.id)
	return (
		<Edit {...props} undoable={false}>
			<TabbedForm>
				<FormTab label="main">
					<ReferenceField source="company" reference="companies">
						<TextField source="name" />
					</ReferenceField>
					<ReferenceInput
						name="parent"
						filter={{ancestor: data?.company}}
						source="workspace"
						reference="workspaces"
						label="Parent"
						allowEmpty
					>
						<AutocompleteInput optionText="name" multiline />
					</ReferenceInput>
					<TextInput source="name" validate={validateName} multiline />
					<TextInput source="description" multiline />
				</FormTab>
			</TabbedForm>
		</Edit>
	)
}
