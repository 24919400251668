import {BaseService} from '../services/base.service'
import {QueryParams} from '../utils/base.query-params'

export abstract class BaseController<S extends BaseService<any>> {
	abstract readonly service: S

	readonly query = new QueryParams()

	get name() {
		return this.service.name
	}

	async getList(params: any): Promise<{data: any[]; total: number}> {
		const query = this.query.build(params)
		return await this.service.getList(query)
	}

	async getOne(params: any) {
		const {id} = params
		return await this.service.getOne(id)
	}

	async getMany(params: any) {
		const {ids} = params
		return await this.service.getMany(ids)
	}

	async getManyReference(params: any) {
		const {target, id} = params
		const query = this.query.build(params)
		return this.service.getManyReference(target, id, query)
	}

	async create(params: any) {
		const {data} = params
		return await this.service.create(data)
	}

	async update(params: any) {
		const {id, data} = params
		return await this.service.update(id, data)
	}

	async updateMany(params: any) {
		const {ids, data} = params
		return await this.service.updateMany(ids, data)
	}

	async delete(params: any) {
		const {id} = params
		return await this.service.delete(id)
	}

	async deleteMany(params: any) {
		const {ids} = params
		return await this.service.deleteMany(ids)
	}
}
