import {VersionsRepository, versionsRepository} from '../repositories/versions.repository'
import {BaseServiceByProject} from './base.service.by-project'

export class VersionService extends BaseServiceByProject<VersionsRepository> {
	repository = versionsRepository

	async findByAnalysisId(
		projectId: string,
		analysisId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: any[]; total: number}> {
		return this.encodeResults(await this.repository.findByAnalysisId(projectId, analysisId, filter, sort, skip, limit))
	}
}

export const versionService = new VersionService()
