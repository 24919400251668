import {BaseRepositoryByProject} from './base.repositories.by-project'
import {ResourceName} from '../../../config/resources.config'

export class AnalysisProcessingJobRepository extends BaseRepositoryByProject {
	name: ResourceName = 'analysis-processing-jobs'

	async startAnalysisProcessingJobByProjectId(projectId: string, id: string): Promise<{data: any}> {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, id, 'start'))
		return {
			data: response.data,
		}
	}
}

export const analysisProcessingJobRepository = new AnalysisProcessingJobRepository()
