import {ModelSetupJobCreateFromModel} from './ModelSetupJobCreateFromModel'
import {ModelSetupJobCreate} from './ModelSetupJobCreate'
import React from 'react'

export function ModelSetupJobCreateWrapper(props) {
	const searchParams = new URLSearchParams(props.location.search)
	const context = searchParams.get('context')
	if (context === 'fromModel') {
		return <ModelSetupJobCreateFromModel {...props} />
	} else {
		return <ModelSetupJobCreate {...props} />
	}
}
