import {conn} from '../../../config/conn.config'
import {getResourceApi} from '../../resources_api/resources.apis'

const cache: Record<string, string> = {}
const byTenantApi = getResourceApi('by-tenant')

export async function tenantIdToProjectId(tenant: string): Promise<string> {
	const projectId = cache[tenant]
	if (projectId) return projectId
	const response = await conn.get(byTenantApi.buildProjectUrl(tenant))
	cache[tenant] = response.data._id
	return response.data._id
}

export function addProjectId(project: any): void {
	cache[project.tenantId] = project._id
}

export function addProjectIdFromProjects(projects: any[]): void {
	projects.forEach(project => addProjectId(project))
}
