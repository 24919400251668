import React from 'react'
import {
	AutocompleteInput,
	Create,
	FormTab,
	maxLength,
	minLength,
	ReferenceInput,
	required,
	TabbedForm,
	TextInput,
} from 'react-admin'
import {goBack} from '../../utils/redirects.utils'

const validateName = [
	required('You must set a name for the team!'),
	minLength(1, 'Team name must be at least one character long'),
	maxLength(50, 'Team name must maximum 50 characters long'),
]

const validateCompany = [required('You must select a company!')]

export function TeamCreate(props: any) {
	const companyId = new URLSearchParams(props.location.search).get('companyId') || null
	return (
		<Create {...props}>
			<TabbedForm redirect={goBack(props)} initialValues={{company: companyId}}>
				<FormTab label="main">
					<ReferenceInput
						name="company"
						source="company"
						reference="companies"
						label="Company"
						validate={validateCompany}
					>
						<AutocompleteInput optionText="name" multiline />
					</ReferenceInput>
					<TextInput name="name" source="name" label="Name" validate={validateName} multiline />
					<TextInput name="description" source="description" label="Description" multiline />
				</FormTab>
			</TabbedForm>
		</Create>
	)
}
