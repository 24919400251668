import {FormTab, SelectInput, TabbedForm, TextInput, useQuery} from 'react-admin'
import {SRCreate} from '../SRComponents/SRCreate'
import React from 'react'

export function SuggestionsCreate(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const {data: analysisViews} = useQuery({
		type: 'getList',
		resource: 'analysis-view',
		payload: {
			pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0},
			sort: {field: '_id', order: ''},
			filter: {},
			enabled: !!projectId,
			projectId,
		},
	})
	const redirect = () => `/projects/${projectId}/show/elements-of-interest`
	const transform = data => {
		const externalElementIds = data.externalElementIds?.trim().split('\n')

		return {
			...data,
			externalElementIds,
			name: 'Initial suggestion',
		}
	}

	return (
		<SRCreate projectId={projectId} showBreadcrumb {...props} listTabName="elements-of-interest" transform={transform}>
			<TabbedForm redirect={redirect}>
				<FormTab label="main">
					<SelectInput
						source="analysisViewId"
						label="Select Analysis"
						choices={analysisViews}
						optionText="name"
						required
					/>
					<TextInput source="externalElementIds" label="Add GlobalIDs" multiline rows={10} rowsMax={25} required />
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
