import {BaseController} from './base.controller'
import {ChangelogService} from '../services/changelog.service'
import {ChangelogRepository} from '../repositories/changelog.repository'

export class ChangelogsController extends BaseController<ChangelogService> {
	constructor(public service: ChangelogService) {
		super()
	}
}

export const changelogControllerPhase1 = new ChangelogsController(
	new ChangelogService(new ChangelogRepository('changelogs-phase-1')),
)
export const changelogControllerPhase2 = new ChangelogsController(
	new ChangelogService(new ChangelogRepository('changelogs-phase-2')),
)
export const changelogControllerPhase3 = new ChangelogsController(
	new ChangelogService(new ChangelogRepository('changelogs-phase-3')),
)
