import {useEffect, useState} from 'react'
import _ from 'lodash'
import {elementTypesController} from '../v3/controllers/element-types.controller'

export type ElementTypeForSelector = {id: string; value: string[]; name: string}

export const useElementTypesForSelector = (modelId?: string, depth?: number): ElementTypeForSelector[] | null => {
	const [elementTypes, setElementTypes] = useState<ElementTypeForSelector[] | null>(null)
	useEffect(() => {
		if (modelId) {
			elementTypesController.getElementTypes({modelId}).then(res => {
				if (depth) {
					res = _.uniqBy(
						res.map(et => et.splice(0, depth)),
						et => et.join(','),
					)
				}
				setElementTypes(res.map(et => ({id: et.join(':'), value: et, name: et.join(' > ')})))
			})
		}
	}, [modelId, setElementTypes, depth])
	return elementTypes
}
