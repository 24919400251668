import {BaseRepository} from './base.repository'
import {throwError} from '../../../utils/errors.utils'
import {Query} from './query'
import {ResourceName} from '../../../config/resources.config'

export class WorkspaceRepository extends BaseRepository {
	name: ResourceName = 'workspaces'

	async getManyReference(target: string, id: string, query: Query): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {
				params: {...query, ancestor: id, parentOnly: true},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}
}

export const workspaceRepository = new WorkspaceRepository()
