import React, {useEffect, useState} from 'react'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {dataProviderV3} from '../data_provider/data-provider.v3'
import {PROJECT_TAB_DEFINITIONS_BY_ID} from '../components/projects/ProjectShow'
import {Button} from 'react-admin'
import {getResourceApi} from '../data_provider/resources_api/resources.apis'

export const CustomBreadcrumb = ({
	location,
	projectId,
	fileId,
	resource,
	entityNameProp,
	listTabName,
	showBreadcrumb,
}) => {
	const [projectName, setProjectName] = useState(null)
	const [fileName, setFileName] = useState(null)
	const pathNameParts = location.pathname.split('/')
	const pathSubpage = pathNameParts[4] || null
	useEffect(() => {
		if (!showBreadcrumb) return
		;(async () => {
			if (!projectId || projectId === 'undefined') return
			const response = await dataProviderV3.getOne('projects', {
				id: projectId,
			})
			setProjectName(response.data.name ? response.data.name : '')
		})()
		;(async () => {
			if (!fileId) return
			const response = await dataProviderV3.getOne(resource, {
				id: fileId,
			})
			setFileName(typeof entityNameProp === 'string' ? response.data[entityNameProp] : entityNameProp(response.data))
		})()
	}, [showBreadcrumb, projectId, fileId, resource, entityNameProp])
	if (!showBreadcrumb) return null
	const BreadCrumbItems = [
		...[{name: 'Projects', href: '/projects'}],
		...(projectName ? [{name: projectName, href: `/projects/${projectId}/show`}] : []),
		...(resource === 'projects' && pathSubpage
			? [
					{
						name: PROJECT_TAB_DEFINITIONS_BY_ID[pathSubpage]?.label || pathSubpage,
						href: `/projects/${projectId}/show/${listTabName || pathSubpage}`,
					},
			  ]
			: []),
		...(resource !== 'projects'
			? [
					{
						name: getResourceApi(resource).name,
						href: `/projects/${projectId}/show/${listTabName || resource}`,
					},
			  ]
			: []),
		...(fileName ? [{name: fileName}] : []),
	]
	return (
		<Box z-Index={100} margin="15px 0" display="flex" alignItems={'center'} className="breadcrumb">
			<Button
				children={<ArrowBackIcon />}
				href={BreadCrumbItems[BreadCrumbItems.length === 1 ? 0 : BreadCrumbItems.length - 2].href}
			/>
			<Link style={{color: '#1c62dc'}} href={`/companies`}>
				Home
			</Link>
			{BreadCrumbItems.map((link, index) => (
				<>
					<Box marginX="3px">{' / '}</Box>
					{link.href ? (
						<Link style={{color: '#1C62DC'}} href={link.href}>
							{link.name}
						</Link>
					) : (
						<Box>{link.name}</Box>
					)}
				</>
			))}
		</Box>
	)
}
