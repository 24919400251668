import React from 'react'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import {analysisProcessingJobSimpleService} from '../../data_provider/v3/services/analysis-processing-job.simple.service'

export const DownloadErrorPayloadButton = (props: any) => (
	<Button
		color="primary"
		aria-label="Download Error Payload"
		disabled={(props.record?.errorPayload || null) === null}
		onClick={async () => {
			const response = await analysisProcessingJobSimpleService.errorPayload(props.record._id)

			const link = document.createElement('a')
			const file = new Blob([response], {type: 'text/plain'})
			link.href = URL.createObjectURL(file)
			link.download = `error-payload-${new Date().toISOString()}.log`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}}
		title="Download Error Payload"
		startIcon={<GetAppIcon />}
	>
		Download
	</Button>
)
