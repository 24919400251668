import React from 'react'
import {config} from '../../config'

export function LinkToReclassificationOverview(props) {
	if (!props.record) {
		return null
	}
	const [companySlug, projectSlug] = props.record.tenantId.split('|')
	const reclassificationId = props.record._id
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`${config.sr.adminViewerUrl}/reclassifications/manage/${companySlug}/${projectSlug}/${reclassificationId}/overview`}
		>
			Manage and view progress
		</a>
	)
}
