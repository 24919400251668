import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

export function ActiveModelIconInfo({record}: any) {
	return (
		<div style={{paddingTop: '4px'}}>
			<Tooltip title={record.active ? 'Active model' : 'Inactive model'} arrow placement="top">
				<FiberManualRecordIcon style={{color: record.active ? '#00c781' : '#e1e3e5'}} />
			</Tooltip>
		</div>
	)
}
