import {ResourceName} from '../../../config/resources.config'
import {BaseRepository} from './base.repository'

export class MasvRepository extends BaseRepository {
	name: ResourceName = 'masvs'

	async copyToProject(project: string, files: string[]) {
		const response = await this.conn.post(this.api.buildBaseUrl('copy-to-project'), {project, files})
		return response.data
	}

	async statusInProject(project: string, files: string[]) {
		const response = await this.conn.post(this.api.buildBaseUrl('status-in-project'), {project, files})
		return response.data
	}
}

export const masvRepository = new MasvRepository()
