import {Create, FileField, FileInput, NumberInput, SimpleForm, Toolbar} from 'react-admin'
import React from 'react'

export function CreateFakeAnalysis({modelId, tenantId, projectId, ...props}) {
	const transform = data => ({
		...data,
		modelId,
		projectId,
	})

	const redirect = () => `/projects/${projectId}/show/analyses`

	return (
		<Create {...props} transform={transform}>
			<SimpleForm redirect={redirect} toolbar={<Toolbar alwaysEnableSaveButton />}>
				<FileInput accept={'application/json'} label={'Metadata.json File'} source={'metadata'}>
					<FileField source="src" title="title" />
				</FileInput>
				<NumberInput
					min={0}
					max={100}
					label={'Percentage of elements to classify'}
					source={'percentage'}
					initialValue={100}
				/>
			</SimpleForm>
		</Create>
	)
}
