import React from 'react'
import {
	DateField,
	EditButton,
	ReferenceField,
	TopToolbar,
	Show,
	SimpleShowLayout,
	Tab,
	TabbedShowLayout,
	TextField,
	Datagrid,
	SearchInput,
	DeleteButton,
	Filter,
} from 'react-admin'
import AddWorkspaceToParentButton from '../buttons/AddWorkspaceToParentButton'
import {WorkspaceShowButton} from '../buttons/WorkspaceShowButton'
import {WorkspaceEditButton} from '../buttons/WorkspaceEditButton'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import {ProjectEditButton} from '../buttons/ProjectEditButton'
import AddAuthButton from '../buttons/AddAuthButton'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'

const TeamShowActions = ({basePath, data}: any) => {
	return <TopToolbar>{data && <EditButton to={`${basePath}/${data.id}?companyId=${data.company}`} />}</TopToolbar>
}

export function WorkspaceShow(props: any) {
	return (
		<Show actions={<TeamShowActions />} {...props}>
			<TabbedShowLayout>
				<Tab label="main">
					<SimpleShowLayout>
						<TextField source="name" />
						<TextField source="description" />
						<ReferenceField source="company" reference="companies" link="show">
							<TextField source="name" />
						</ReferenceField>
						<ReferenceField source="parent" reference="workspaces" link="show">
							<TextField source="name" />
						</ReferenceField>
						<TextField source="_id" />
						<DateField source="createdDate" />
					</SimpleShowLayout>
				</Tab>

				<Tab label="subworkspaces">
					<AddWorkspaceToParentButton {...props} />
					<SRReferenceManyField
						source={'_id'}
						reference="workspaces"
						target={'companies'}
						sort={{field: 'name', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="name" />
								<WorkspaceShowButton parentId={props.id} />
								<WorkspaceEditButton parentId={props.id} />
								<DeleteButton undoable={false} redirect={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
				<Tab label="projects">
					<SRReferenceManyField
						source={'_id'}
						reference="projects"
						target={'workspaces'}
						sort={{field: 'name', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="name" />
								<ProjectShowButton projectId={props.id} />
								<ProjectEditButton projectId={props.id} />
								<DeleteButton undoable={false} redirect={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>

				<Tab label="security">
					<AddAuthButton {...props} entityType={'workspace'} />
					<SRReferenceManyField
						source="_id"
						reference="auths"
						target="entity"
						sort={{field: 'memberName', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="memberName" />
								<TextField source="email" />
								<TextField source="memberType" label={'Member type'} />
								<TextField source="role" />
								<EditButton />
								<DeleteButton redirect={false} undoable={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
}
