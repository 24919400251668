import React from 'react'
import {Link} from 'react-router-dom'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {Button} from 'react-admin'

const styles = {
	button: {
		marginTop: '1em',
	},
}

const AddWorkspaceToParentButton = ({classes, record}) => (
	<Button
		className={classes.button}
		variant="outlined"
		component={Link}
		to={`/workspaces/create?parentId=${record?.id}`}
		label="Add workspace"
		title="Add workspace"
	>
		<AddCircleOutlineIcon />
	</Button>
)

export default withStyles(styles)(AddWorkspaceToParentButton)
