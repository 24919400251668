import React from 'react'
import {DateField, Datagrid, ReferenceField, TextField, EditButton, DeleteButton, ShowButton} from 'react-admin'
import TeamFilters from './TeamFilters'
import {SRList} from '../SRComponents/SRList'

export function TeamList(props: any) {
	return (
		<SRList {...props} filters={<TeamFilters />} sort={{field: 'name', order: 'ASC'}}>
			<Datagrid>
				<TextField source="name" />
				<ReferenceField source="company" reference="companies">
					<TextField source="name" />
				</ReferenceField>
				<DateField source="createdDate" />
				<ShowButton />
				<EditButton />
				<DeleteButton />
			</Datagrid>
		</SRList>
	)
}
