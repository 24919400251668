import React from 'react'
import {BulkDeleteButton, Datagrid, DeleteButton, TextField} from 'react-admin'
import FilesFilters from './FilesFilters'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import {DownloadFileButton} from '../buttons/DownloadFileButton'
import {SRList} from '../SRComponents/SRList'

export function FilesList(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	return (
		<SRList
			{...props}
			entityNameProp="filename"
			projectId={projectId}
			fileId={props.id}
			listTabName="files"
			showBreadcrumb={!!projectId}
			filters={<FilesFilters />}
			sort={{field: 'name', order: 'ASC'}}
			bulkActionButtons={<BulkDeleteButton undoable={false} />}
		>
			<Datagrid>
				<TextField source="filename" />
				<TextField source="status" />
				<TextField source="contentType" />
				<TextField source="size" />
				<TextField source="createdDate" />
				<ProjectShowButton projectId={props.id} />
				<DownloadFileButton fileId={props.id} />
				<DeleteButton redirect={false} undoable={false} />
			</Datagrid>
		</SRList>
	)
}
