import {BaseController} from './base.controller'
import {
	reclassificationStagesTemplateService,
	ReclassificationStagesTemplateService,
} from '../services/reclassification-stages-template.service'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

export class ReclassificationStagesTemplateController extends BaseController<ReclassificationStagesTemplateService> {
	readonly service: ReclassificationStagesTemplateService = reclassificationStagesTemplateService
	readonly query = new ByFreeTextKeyQueryParams()
}

export const reclassificationStagesTemplateController = new ReclassificationStagesTemplateController()
