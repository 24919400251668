import {
	DateField,
	EditButton,
	ReferenceField,
	ShowProps,
	SimpleShowLayout,
	TextField,
	TopToolbar,
	useQuery,
} from 'react-admin'

import React from 'react'
import {SRShow} from '../SRComponents/SRShow'

function ShowActions({basePath, data, projectId}: any) {
	return !data ? null : (
		<TopToolbar>
			<EditButton to={`${basePath}/${data._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

export function AnalysisViewShow(props: ShowProps) {
	const searchParams = new URLSearchParams(props.location?.search)
	const projectId = searchParams.get('projectId')
	const {data} = useQuery({
		type: 'getOne',
		resource: props.resource,
		payload: {id: props.id},
	})
	return (
		<SRShow
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			listTabName="analyses"
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<SimpleShowLayout>
				<TextField source="name" label="Analysis name" />
				<TextField source="id" label="Database id" />
				<TextField source="analysisId" label="Analysis id" />
				<DateField source="analysisDate" label="Analysis date" />
				<DateField source="createdDate" label="Created date" />
				<TextField source="deliveryType" label="Delivery type" />
				<TextField source="status" label="Status" />
				<DateField source="statusUpdateTime" label="Status Update Time" />
				<ReferenceField
					label="Scan"
					source="scan"
					reference="scans"
					link={(record, reference) => `/${reference}/${record.scan}/show?projectId=${projectId}`}
				>
					<TextField source="name" />
				</ReferenceField>
				<DateField source="scanDate" label="Scan date" />
			</SimpleShowLayout>
		</SRShow>
	)
}
