import {ROIRepository, roiRepository} from '../repositories/roi.repository'
import {BaseServiceByProject} from './base.service.by-project'

function convertTolerance(data, isOut) {
	return !data.elementSetSpecifications
		? data
		: {
				...data,
				elementSetSpecifications: data.elementSetSpecifications.map(e => {
					return !e.tolerance ? e : {...e, tolerance: isOut ? e.tolerance / 1000 : e.tolerance * 1000}
				}),
		  }
}

export class ROIService extends BaseServiceByProject<ROIRepository> {
	repository = roiRepository

	encodeResult({data, ...rest}: any): any {
		data = convertTolerance(data, false)
		return super.encodeResult({data, ...rest})
	}

	encodeResults({data, ...rest}: any): any {
		data = data.map(e => convertTolerance(e, false))
		return super.encodeResults({data, ...rest})
	}

	encodeOutput(data: any): any {
		data = convertTolerance(data, true)
		return super.encodeOutput(data)
	}
}

export const roiService = new ROIService()
