import {TeamMemberRepository, teamMemberRepository} from '../repositories/team-members.repository'
import {BaseService, EncodeMixin} from './base.service'
import {TeamRepository} from '../repositories/teams.repository'
import {Query} from '../repositories/query'

export class TeamMemberService extends BaseService<TeamMemberRepository> {
	repository = teamMemberRepository

	get name() {
		return this.repository.name
	}

	async getListForTeam(teamId: string, query: Query): Promise<{data: unknown[]; total: number}> {
		return this.encodeResults(await this.repository.getListForTeam(teamId, query))
	}
}

export const teamMemberService = new TeamMemberService()
