export const oneStepModelSetupStatusData = (modelSetup: any) => {
	let statusName
	let statusColor
	let statusBackgroundColor
	switch (modelSetup.status) {
		case 'not_started':
			statusName = 'NOT STARTED'
			statusBackgroundColor = '#BDC0C5'
			statusColor = '#242629'
			break
		case 'started':
			statusName = 'STARTED'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'downloading_data':
			statusName = 'DOWNLOADING DATA'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'forge_upload':
			statusName = 'FORGE UPLOAD'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'forge_translation':
			statusName = 'FORGE TRANSLATION'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'obj_translation':
			statusName = 'OBJ TRANSLATION'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'translated':
			statusName = 'TRANSLATED'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'populating':
			statusName = 'POPULATING'
			statusBackgroundColor = '#1C62DC'
			statusColor = '#fff'
			break
		case 'finished':
			statusName = 'FINISHED'
			statusBackgroundColor = '#00C781'
			statusColor = '#fff'
			break
		case 'errored':
			statusName = 'ERROR'
			statusBackgroundColor = '#E60103'
			statusColor = '#fff'
			break
		default:
			throw Error(`Unrecognized the "${modelSetup.status}" one step model setup status.`)
	}
	return {...modelSetup, statusName, statusBackgroundColor, statusColor}
}
