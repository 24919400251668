import React, {useEffect, useState} from 'react'
import './StandAloneForgeViewer.css'

let log: (msg: string) => void = msg => undefined

const getQueryParamOrFail = (name: string): string => {
	const value = new URLSearchParams(window.location.search).get(name)
	if (!value) {
		const errorMessage = `The parameter ${name} was not specified`
		log(errorMessage)
		throw Error(errorMessage)
	}
	return value
}

const initializeViewer = (viewerElement: HTMLElement) => {
	const options = {
		env: 'AutodeskProduction2',
		api: 'streamingV2',
		accessToken: getQueryParamOrFail('accessToken'),
	}

	Autodesk.Viewing.Initializer(options, async function () {
		const start = new Date().getTime()
		const viewer = new Autodesk.Viewing.GuiViewer3D(viewerElement)
		viewer.start()
		loadModel(viewer, getQueryParamOrFail('urn'))
		log('Model load has started for urn: ' + getQueryParamOrFail('urn'))
		viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () =>
			log(`OBJECT_TREE_CREATED in ${(new Date().getTime() - start) / 1000}s`),
		)
		viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () =>
			log(`GEOMETRY_LOADED in ${(new Date().getTime() - start) / 1000}s`),
		)
	})
	const loadModel = (viewer, urn) => {
		function onDocumentLoadSuccess(doc) {
			viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry())
		}
		function onDocumentLoadFailure(code, message) {
			console.error('Could not load the model', code, message)
		}
		Autodesk.Viewing.Document.load('urn:' + urn, onDocumentLoadSuccess, onDocumentLoadFailure)
	}
}

export const StandAloneForgeViewer = () => {
	const [logs, setLogs] = useState<string[]>([])
	log = (msg: string) => {
		setLogs([...logs, msg])
		console.log(msg)
	}
	useEffect(() => {
		const viewerElement = document.getElementById('viewer')!
		initializeViewer(viewerElement)
	}, [])

	return (
		<div id={'stand-alone-viewer'}>
			<div id="viewer"></div>
			<div id="sidebar">
				<h3>Log</h3>
				<div id="log">
					{logs.map(log => (
						<div>{log}</div>
					))}
				</div>
			</div>
		</div>
	)
}
