import {BaseController} from '../../data_provider/v3/controllers/base.controller'
import {BaseService} from '../../data_provider/v3/services/base.service'
import {BaseRepository} from '../../data_provider/v3/repositories/base.repository'
import {ResourceName} from '../../config/resources.config'
export class FeatureFlagsRepository extends BaseRepository {
	name: ResourceName = 'features'
	async getGlobal() {
		const response = await this.conn.get(this.api.buildBaseUrl(undefined, 'with-layers'))
		return {
			data: response.data,
		}
	}
	async getFeatureFlagsForProject(projectId: string) {
		const response = await this.conn.get(this.api.buildProjectUrl(projectId, '', ''))
		return {
			data: response.data,
		}
	}
}
const featureFlagsRepository = new FeatureFlagsRepository()
export class FeatureFlagsService extends BaseService<FeatureFlagsRepository> {
	repository = featureFlagsRepository
	getGlobal() {
		return this.repository.getGlobal()
	}
	getFeatureFlagsForProject(projectId: string) {
		return this.repository.getFeatureFlagsForProject(projectId)
	}
}
const featureFlagsService = new FeatureFlagsService()
export class FeatureFlagsController extends BaseController<FeatureFlagsService> {
	service = featureFlagsService
	getGlobal() {
		return featureFlagsService.getGlobal()
	}

	getFeatureFlagsForProject(props: {actionName: string; projectId: string}) {
		return this.service.getFeatureFlagsForProject(props.projectId)
	}
}
export const featureFlagsController = new FeatureFlagsController()
