import React from 'react'
import {
	DateField,
	EditButton,
	ReferenceField,
	TopToolbar,
	Show,
	SimpleShowLayout,
	Tab,
	TabbedShowLayout,
	TextField,
	Datagrid,
	SearchInput,
	DeleteButton,
	Filter,
} from 'react-admin'
import AddMemberToTeamButton from './AddMemberToTeamButton'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'

const TeamShowActions = ({basePath, data, resource}: any) => {
	return <TopToolbar>{data && <EditButton to={`${basePath}/${data.id}?companyId=${data.company}`} />}</TopToolbar>
}

export function TeamShow(props: any) {
	return (
		<Show actions={<TeamShowActions />} {...props}>
			<TabbedShowLayout>
				<Tab label="main">
					<SimpleShowLayout>
						<TextField source="name" />
						<TextField source="description" />
						<ReferenceField source="company" reference="companies" link="show">
							<TextField source="name" />
						</ReferenceField>
						<TextField source="_id" />
						<DateField source="createdDate" />
					</SimpleShowLayout>
				</Tab>

				<Tab label="members">
					<SimpleShowLayout>
						<AddMemberToTeamButton {...props} />
						<SRReferenceManyField
							source={'_id'}
							reference="team-members"
							target={'id'}
							sort={{field: 'user', order: 'ASC'}}
							addLabel={false}
							fullWidth
						>
							<SimpleShowLayout>
								<Datagrid>
									<ReferenceField source="user" reference="users" link="show">
										<TextField source="email" />
									</ReferenceField>
									<TextField source="role" />
									<EditButton />
									<DeleteButton undoable={false} redirect={false} />
								</Datagrid>
							</SimpleShowLayout>
						</SRReferenceManyField>
					</SimpleShowLayout>
				</Tab>

				<Tab label="security">
					<SRReferenceManyField source="_id" reference="auths" target="team" addLabel={false} fullWidth>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="n" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="entityName" label={'Name'} />
								<TextField source="entityType" label={'Type'} />
								<TextField source="role" />
								<EditButton />
								<DeleteButton redirect={false} undoable={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
}
