import Button from '@material-ui/core/Button'
import Done from '@material-ui/icons/Done'
import React from 'react'
import {modelService} from '../../data_provider/v3/services/model.service'
import {useRefresh} from 'react-admin'
import {scansService} from '../../data_provider/v3/services/scans.service'

export const ValidateButton = props => {
	const refresh = useRefresh()
	const type = props.data.basePath
	const service = type === '/models' ? modelService : scansService
	return (
		<Button
			color="primary"
			aria-label="validate"
			onClick={async () => {
				await service.validateInputCheckCheck(props.data.projectId, props.data.data.id, props.data.record.name)
				refresh()
			}}
			title="validate"
			variant="outlined"
			startIcon={<Done />}
			disabled={props.data.record?.validated}
		>
			VALIDATE
		</Button>
	)
}
