import React from 'react'
import {
	ArrayField,
	BooleanField,
	Datagrid,
	DateField,
	EditButton,
	FunctionField,
	NumberField,
	ReferenceField,
	SimpleShowLayout,
	TextField,
	TopToolbar,
} from 'react-admin'
import {SRShow} from '../SRComponents/SRShow'

const getFullName = record => record && `${record.firstName} ${record.lastName}`

function ShowActions({basePath, data, projectId}: any) {
	return !data ? null : (
		<TopToolbar>
			<EditButton to={`${basePath}/${data._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

export function ROIShow(props) {
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	return (
		<SRShow
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<SimpleShowLayout>
				<TextField source="name" label="ROI Name" />
				<ReferenceField label="Model" source="model" reference="models" link="show">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
					<FunctionField render={getFullName} />
				</ReferenceField>
				<DateField source="createdDate" label="Date Created" />
				<ArrayField source="elementSetSpecifications" label={'CSV Files'}>
					<Datagrid>
						<ReferenceField label="File" source="externalIdsProjectFile._id" reference="project-files" link={false}>
							<TextField source="filename" />
						</ReferenceField>
						<TextField source="type" label={'Type'} />
						<NumberField source="tolerance" label={'Tolerance (mm)'} />
					</Datagrid>
				</ArrayField>
				<BooleanField label={'Ignore missing global ids'} source="config.ignoreMissingExternalIds" />
			</SimpleShowLayout>
		</SRShow>
	)
}
