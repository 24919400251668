import React from 'react'
import {Edit, SimpleForm, SelectInput, TextField, TextInput, ReferenceField} from 'react-admin'
import {useGetRoles} from '../auths/roles'
import {goBack} from '../../utils/redirects.utils'

export function TeamMemberEdit(props: any) {
	const roles = useGetRoles()
	return (
		<Edit {...props} undoable={false}>
			<SimpleForm redirect={goBack(props)}>
				<ReferenceField source="user" reference="users" link="show">
					<TextField source="email" />
				</ReferenceField>
				<SelectInput source="role" choices={roles} />
			</SimpleForm>
		</Edit>
	)
}
