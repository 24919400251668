import {BaseRepository} from '../repositories/base.repository'
import {Query} from '../repositories/query'

export function encodeId(entity: any): any {
	return {...entity, id: entity._id}
}

export class EncodeMixin {
	encodeResult({data, ...rest}: any): any {
		return {data: encodeId(data), ...rest}
	}

	encodeResults({data, ...rest}: any): any {
		return {data: data.map(e => encodeId(e)), ...rest}
	}

	encodeOutput(data) {
		return data
	}
}

export abstract class BaseService<R extends BaseRepository> extends EncodeMixin {
	abstract readonly repository: R

	get name() {
		return this.repository.name
	}

	async getList(query: Query): Promise<{data: unknown[]; total: number}> {
		return this.encodeResults(await this.repository.getList(query))
	}

	async getOne(id: string): Promise<{data: unknown}> {
		return this.encodeResult(await this.repository.getOne(id))
	}

	async getManyReference(target: string, id: string, query: Query) {
		return await this.encodeResults(await this.repository.getManyReference(target, id, query))
	}

	async getMany(ids: string[]): Promise<{data: unknown[]}> {
		return this.encodeResults(await this.repository.getMany(ids))
	}

	async create(data: any): Promise<{data: unknown}> {
		data = this.encodeOutput(data)
		return this.encodeResult(await this.repository.create(data))
	}

	async update(id: string, data: any): Promise<{data: unknown}> {
		data = this.encodeOutput(data)
		return this.encodeResult(await this.repository.update(id, data))
	}

	async updateMany(ids: string[], data: any): Promise<{data: unknown[]}> {
		data = this.encodeOutput(data)
		return this.encodeResults(await this.repository.updateMany(ids, data))
	}

	async delete(id: string): Promise<{data: unknown}> {
		return await this.repository.delete(id)
	}

	async deleteMany(ids: string[]): Promise<{data: unknown[]}> {
		return await this.repository.deleteMany(ids)
	}
}

export function encodeCompany(data: any): any {
	return {...data, company: data.ancestors[1]}
}
