import React from 'react'
import {CreateReclassificationsForTraining} from './CreateReclassificationsForTraining'
import {CreateReclassification} from './CreateReclassification'

export function CreateReclassificationWrapper(props) {
	const searchParams = new URLSearchParams(props.location.search)
	const context = searchParams.get('context')
	if (context === 'createForTraining') {
		return <CreateReclassificationsForTraining {...props} />
	} else {
		return <CreateReclassification {...props} />
	}
}
