import {SimpleShowLayout, Tab} from 'react-admin'
import React from 'react'
import {JSONField} from '../../ra-extensions/fields/JSONField'

export const ViewerProjectTab = props => (
	<Tab {...props}>
		<SimpleShowLayout>
			<JSONField source="viewerState" />
			<JSONField source="viewerFeatureFlags" />
			<JSONField source="floorMapping" />
			<JSONField source="elementTypeMapping" />
			<JSONField source="elementsToHide" />
			<JSONField source="elementViewerTypesToHide" />
		</SimpleShowLayout>
	</Tab>
)
