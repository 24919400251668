import React, {ReactNode} from 'react'
import {useFeatureFlagsImport} from '../FeatureFlagsProvider'
import {useQuery} from 'react-admin'

export function ProjectFeatureFlagsWrapper(props: {children: ReactNode; projectId: string}) {
	const importToLayer = useFeatureFlagsImport()

	const {data} = useQuery({
		resource: 'features',
		type: 'custom',
		payload: {actionName: 'getFeatureFlagsForProject', projectId: props.projectId},
	})
	if (data) {
		importToLayer(Object.fromEntries(data.map(({id, value}) => [id, value])), 'server')
	}
	return <>{data ? props.children : null}</>
}
