import {modelService, ModelService} from '../services/model.service'
import {BaseControllerByProject} from './base.controller.by-project'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

class ModelController extends BaseControllerByProject<ModelService> {
	service = modelService
	query = new ByFreeTextKeyQueryParams()

	async update(params: {
		id: string
		data: {
			projectId: string
			name: string
			coordinatesOffset: {x: number; y: number; z: number}
			partialModels: any[]
			eligibleForUnderConstructionElementIdsCSV: object | null
			ignoreMissingIds: boolean
			active: boolean
			assembliesConfig: any[]
		}
	}): Promise<{data: any}> {
		if (!params.data.projectId) throw new Error('ProjectId not passed to controller update() method.')
		return this.service.update(params.data.projectId, params.id, {
			name: params.data.name,
			coordinatesOffset: params.data.coordinatesOffset,
			partialModels: params.data.partialModels,
			eligibleForUnderConstructionElementIdsCSV: params.data.eligibleForUnderConstructionElementIdsCSV || null,
			ignoreMissingIds: params.data.ignoreMissingIds,
			active: params.data.active,
			assembliesConfig: params.data.assembliesConfig,
		})
	}
}

export const modelController = new ModelController()
