import {
	ArrayField,
	BooleanField,
	Datagrid,
	DateField,
	EditButton,
	ReferenceField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	TopToolbar,
	useQuery,
} from 'react-admin'

import React from 'react'
import {Typography} from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import {StatusChipInputCheckInfo} from '../input-check/StatusChipInputCheckInfo'

import {
	CheckName,
	ConditionalDownloadAssetsButton,
	ConditionalOutputAssetsInfoModal,
	ConditionalValidateButton,
	InfoIconButton,
} from '../input-check/input-check.utilities'
import {usePreserveQuerystring} from '../../hooks/UsePreserveQuerystring'
import {SRShow} from '../SRComponents/SRShow'
import {OpenStandAloneForgeViewer} from '../forge-viewer/stand-alone-forge-viewer/OpenStandAloneForgeViewer'

function ShowActions({basePath, data, projectId}: any) {
	return !data ? null : (
		<TopToolbar>
			<EditButton to={`${basePath}/${data._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

export function ModelsShow(props) {
	const searchParams = usePreserveQuerystring('projectId')
	const projectId = searchParams.get('projectId')
	const {data} = useQuery({
		type: 'getOne',
		resource: props.resource,
		payload: {id: props.id},
	})
	const config = data?.config
	return (
		<SRShow
			{...props}
			entityNameProp="name"
			projectId={projectId}
			listTabName="models"
			fileId={props.id}
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<TabbedShowLayout>
				<Tab label="model properties">
					{data?.source.type === 'clone' ? (
						<Typography component="div">
							<ReportProblemOutlinedIcon color={'secondary'} />
							&nbsp; This model is a shallow clone. If the reference model gets removed from Forge, this clone will not
							be working anymore.
						</Typography>
					) : null}
					<TextField source="name" label="Model name" />
					<TextField source="config.outputFormat" label="Output Format" />
					<TextField source="config.modelType" label="Model type" />
					<BooleanField
						source="config.generateElementTypeTreeFromName"
						label="Generate element type tree from element name"
					/>
					{config?.forgeEngine && <TextField source="config.forgeEngine" label="Forge engine" />}
					{config?.generateMasterViews && (
						<BooleanField source="config.generateMasterViews" label="Generate master views" />
					)}
					{config?.materialMode && <TextField source="config.materialMode" label="Material mode" />}
					{config?.hiddenObjects && <BooleanField source="config.hiddenObjects" label="Hidden objects" />}
					{config?.basicMaterialProperties && (
						<BooleanField source="config.basicMaterialProperties" label="Basic material properties" />
					)}
					{config?.autodeskMaterialProperties && (
						<BooleanField source="config.autodeskMaterialProperties" label="Autodesk material properties" />
					)}
					{config?.timelinerProperties && (
						<BooleanField source="config.timelinerProperties" label="Timeliner properties" />
					)}
					<DateField source="createdDate" />
					<TextField style={{wordBreak: 'break-all'}} source="modelUrn" />
					<OpenStandAloneForgeViewer />
					<Typography variant="subtitle1" component="div" gutterBottom>
						Translation coordinates (in mm)
					</Typography>
					<TextField source="coordinatesOffset.x" label="X:" />
					<TextField source="coordinatesOffset.y" label="Y:" />
					<TextField source="coordinatesOffset.z" label="Z:" />
					<TextField source="source.type" label="Model Source" />
					{data?.source.type === 'clone' ? <TextField source="source.referenceModel" /> : null}
					{!data?.partialModels?.length ? null : (
						<Typography variant="subtitle1" component="div">
							Partial Models
						</Typography>
					)}
					{!data?.partialModels?.length ? null : (
						<ArrayField source="partialModels" label={''}>
							<Datagrid>
								<TextField source="name" label={'Partial model name'} />
								<ReferenceField
									label="CSV file name"
									source="externalIdsProjectFileId"
									reference="project-files"
									link={false}
								>
									<TextField source="filename" />
								</ReferenceField>
							</Datagrid>
						</ArrayField>
					)}
					{!data?.eligibleForUnderConstructionElementIdsCSV ? null : (
						<Typography variant="subtitle1" component="div">
							Eligible for Under Construction
						</Typography>
					)}
					{!data?.eligibleForUnderConstructionElementIdsCSV ? null : (
						<ReferenceField
							label="CSV file name"
							source="eligibleForUnderConstructionElementIdsCSV._id"
							reference="project-files"
							link={false}
						>
							<TextField source="filename" />
						</ReferenceField>
					)}
				</Tab>
				<Tab label="input validation">
					{!data?.inputCheck ? (
						<Typography variant="h5" component="div" style={{padding: '40px 5px'}}>
							This model doesn't have input checks
						</Typography>
					) : (
						<>
							<Typography variant="h6">
								Status <StatusChipInputCheckInfo record={data} label={'Status'} />
							</Typography>
							{data.inputCheck.message?.length ? (
								<Typography variant="body2" component="div">
									{data.inputCheck.message}
								</Typography>
							) : null}
						</>
					)}
					{!data?.inputCheck?.checks?.length ? null : (
						<ArrayField record={data} source="inputCheck.checks" label={<Typography variant="h6">Checks</Typography>}>
							<Datagrid>
								<StatusChipInputCheckInfo label={'Status'} />
								<CheckName label="Check" />
								<ConditionalOutputAssetsInfoModal projectId={projectId} data={data} />
								<ConditionalDownloadAssetsButton projectId={projectId} data={data} />
								<ConditionalValidateButton projectId={projectId} data={data} />
								<InfoIconButton label="Info" />
							</Datagrid>
						</ArrayField>
					)}
				</Tab>
			</TabbedShowLayout>
		</SRShow>
	)
}
