import * as React from 'react'
import {FunctionComponent} from 'react'
import {Field, Form} from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useTranslate, useNotify, useSafeSetState} from 'ra-core'
import {useLocation, useHistory} from 'react-router-dom'

interface Props {
	onSubmit: (values: any, redirectTo?: string) => Promise<unknown | null>
	redirectTo?: string
}

interface FormData {
	username: string
	password: string
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			width: '100%',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
	}),
	{name: 'RaLoginForm'},
)

const Input = ({
	meta: {touched, error}, // eslint-disable-line react/prop-types
	input: inputProps, // eslint-disable-line react/prop-types
	...props
}: any) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />

const LoginForm: FunctionComponent<Props> = props => {
	const {redirectTo, onSubmit} = props
	const [loading, setLoading]: [boolean, any] = useSafeSetState(false)
	const location = useLocation()
	const locationState = location.state as any
	const history = useHistory()
	const nextPathName = locationState && locationState.nextPathname
	const translate = useTranslate()
	const notify = useNotify()
	const classes = useStyles(props)

	const validate = (values: FormData) => {
		const errors: any = {username: undefined, password: undefined}

		if (!values.username) {
			errors.username = translate('ra.validation.required')
		}
		if (!values.password) {
			errors.password = translate('ra.validation.required')
		}
		return errors
	}

	const submit = async (values: any) => {
		setLoading(true)
		try {
			const response = await onSubmit(values)
			setLoading(false)
			if (response) history.push(nextPathName || redirectTo || '/')
		} catch (error: any) {
			setLoading(false)
			notify(
				typeof error === 'string'
					? error
					: typeof error === 'undefined' || !error.message
					? 'ra.auth.sign_in_error'
					: error.message,
				'warning',
			)
		}
	}

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<div className={classes.input}>
							<Field
								autoFocus
								id="username"
								name="username"
								component={Input}
								label={translate('ra.auth.username')}
								disabled={loading}
							/>
						</div>
						<div className={classes.input}>
							<Field
								id="password"
								name="password"
								component={Input}
								label={translate('ra.auth.password')}
								type="password"
								disabled={loading}
								autoComplete="current-password"
							/>
						</div>
					</div>
					<CardActions>
						<Button variant="contained" type="submit" color="primary" disabled={loading} className={classes.button}>
							{loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
							{translate('ra.auth.sign_in')}
						</Button>
					</CardActions>
				</form>
			)}
		/>
	)
}

export default LoginForm
