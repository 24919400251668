import {BaseRepositoryByProject} from './base.repositories.by-project'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'

export class ReclassificationsRepository extends BaseRepositoryByProject {
	name: ResourceName = 'reclassifications'

	async findByVersionId(projectId: string, versionId: string): Promise<{data: any[]; total: number}> {
		try {
			const {
				data: {result, count},
			} = await this.conn.get(
				`${this.api.buildProjectUrl(projectId)}?filter=${encodeURIComponent(`(version==${versionId})`)}`,
			)
			return {
				data: result,
				total: count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getByVersionId: ${e.message}`)
		}
	}

	async createNewAnalysisVersionReclassificationTasks(projectId: string, id: string): Promise<{data: any}> {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, id, 'version'))
		return {
			data: response.data,
		}
	}

	async createReclassificationTasksForTraining(projectId: string, params: any) {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, undefined, 'forTraining'), params)
		return {
			data: response.data,
		}
	}
}

export const reclassificationsRepository = new ReclassificationsRepository()
