import {BaseController} from './base.controller'
import {workspaceService, WorkspaceService} from '../services/workspace.service'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

class WorkspaceController extends BaseController<WorkspaceService> {
	service = workspaceService
	query = new ByFreeTextKeyQueryParams()
}

export const workspaceController = new WorkspaceController()
