import {BaseController} from './base.controller'
import {ChangelogService} from '../services/changelog.service'
import {ResourceName} from '../../../config/resources.config'
import {ChangelogRepository} from '../repositories/changelog.repository'

export class PendingMigrationsController extends BaseController<ChangelogService> {
	constructor(public service: ChangelogService) {
		super()
	}

	get name(): ResourceName {
		return `pending-${this.service.name}` as ResourceName
	}

	async getList(params: any): Promise<{data: any[]; total: number}> {
		return this.service.getPending().then(({data}) => {
			return {data, total: data.length}
		})
	}

	async migrateUp(): Promise<{data: string[]}> {
		return this.service.migrateUp().then(migrated => ({data: migrated}))
	}
}

export const pendingMigrationsPhase1Controller = new PendingMigrationsController(
	new ChangelogService(new ChangelogRepository('changelogs-phase-1')),
)
export const pendingMigrationsPhase2Controller = new PendingMigrationsController(
	new ChangelogService(new ChangelogRepository('changelogs-phase-2')),
)
export const pendingMigrationsPhase3Controller = new PendingMigrationsController(
	new ChangelogService(new ChangelogRepository('changelogs-phase-3')),
)
