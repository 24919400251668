import React from 'react'
import {ChipField} from 'react-admin'
import {oneStepModelSetupStatusData} from '../../utils/modelSetupJobs.utils'

export function StatusChipOneStepModelSetupInfo(record: any) {
	const modelSetup = record.record
	const chipRecord = modelSetup && oneStepModelSetupStatusData(modelSetup)
	return (
		<>
			{!modelSetup ? (
				'-'
			) : (
				<ChipField
					record={chipRecord}
					source="statusName"
					style={{background: chipRecord.statusBackgroundColor, color: chipRecord.statusColor}}
				/>
			)}
		</>
	)
}
