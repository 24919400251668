import React from 'react'
import {Filter, SearchInput} from 'react-admin'

export default function FilesFilters(props: any) {
	return (
		<Filter {...props}>
			<SearchInput source="q" alwaysOn />
		</Filter>
	)
}
