import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {Button} from 'react-admin'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import React from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'

export const customStyles = makeStyles(
	(theme: Theme) => ({
		title: {},
		modalContent: {
			display: 'flex',
			gap: '25px',
			justifyContent: 'space-between',
		},
		alertIcon: {
			marginTop: '1em',
			height: '45px',
			width: '45px',
			color: '#ffb800',
		},
		text: {
			fontSize: '16px',
			lineHeight: '1.5',
			letterSspacing: '0.1px',
		},
		actions: {
			display: 'flex',
			justifyContent: 'end',
			padding: '16px',
		},
		confirmButton: {
			width: '141px',
			height: '36px',
			padding: '0 12px',
			borderRadius: '4px',
			backgroundColor: '#6b717b',
			color: '#fff',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#6b717b',
				color: '#fff',
			},
		},
	}),
	{name: 'ConfirmActivatingModal'},
)

const ConfirmDeactivatingModal = ({showDialog, onClose}) => {
	const classes = customStyles()
	return (
		<Dialog fullWidth open={showDialog} onClose={onClose}>
			<DialogTitle className={classes.title}>Active model</DialogTitle>
			<DialogContent>
				<div className={classes.modalContent}>
					<ReportProblemOutlinedIcon className={classes.alertIcon} />
					<div className={classes.text}>
						<h3>
							<b>Deactivating model</b>
						</h3>
						<div>If there is no active model, users won’t be able to create analysis request.</div>
						<div>Please remember to activate a model.</div>
					</div>
				</div>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button
					className={classes.confirmButton}
					onClick={onClose}
					label="Ok, I understand"
					title="Ok, I understand"
				></Button>
			</DialogActions>
		</Dialog>
	)
}
export default ConfirmDeactivatingModal
