import {throwError} from '../utils/errors.utils'
import {BaseController} from './v3/controllers/base.controller'
import {companyController} from './v3/controllers/company.controller'
import {projectController} from './v3/controllers/project.controller'
import {userController} from './v3/controllers/user.controller'
import {analysisController} from './v3/controllers/analysis.controller'
import {watchlistController} from './v3/controllers/watchlist.controller'
import {versionController} from './v3/controllers/version.controller'
import {modelController} from './v3/controllers/model.controller'
import {modelUploadJobController} from './v3/controllers/model-upload-job.controller'
import {scansController} from './v3/controllers/scans.controller'
import {reclassificationsController} from './v3/controllers/reclassification.controller'
import {userRoleController} from './v3/controllers/user-role.controller'
import {teamController} from './v3/controllers/team.controller'
import {reclassificationStagesTemplateController} from './v3/controllers/reclassification-stages-template.controller'
import {filterPresetController} from './v3/controllers/filter-preset.controller'
import {teamMemberController} from './v3/controllers/team-member.controller'
import {workspaceController} from './v3/controllers/workspace.controller'
import {authController} from './v3/controllers/auth.controller'
import {analysisProcessingJobController} from './v3/controllers/analysis-processing-job.controller'
import {roiController} from './v3/controllers/roi.controller'
import {projectFileController, rootFileController} from './v3/controllers/files.controller'
import {ResourceName} from '../config/resources.config'
import {analysisProcessingJobSimpleController} from './v3/controllers/analysis-processing-job.simple.controller'
import {masvController} from './v3/controllers/masvs.controller'
import {analysisViewController} from './v3/controllers/analysis-view.controller'
import {
	changelogControllerPhase1,
	changelogControllerPhase2,
	changelogControllerPhase3,
} from './v3/controllers/changelogs.controller'
import {
	pendingMigrationsPhase1Controller,
	pendingMigrationsPhase2Controller,
	pendingMigrationsPhase3Controller,
} from './v3/controllers/pending-migrations.controller'
import {nonNull} from '../config/'
import {elementTypesController} from './v3/controllers/element-types.controller'
import {modelSetupJobController} from './v3/controllers/model-setup-job.controller'
import {featureFlagsController} from '../features/feature-flags/features.data-provider'
import {suggestionController} from './v3/controllers/suggestion.controller'

class DataProvider<C extends BaseController<any>> {
	constructor(protected controllers: C[]) {}

	get(name: ResourceName) {
		const result = this.controllers.find(controller => controller.name === name)
		return result ? result : throwError(`No controller found for ${name}`)
	}

	async custom(resource: ResourceName, params: {actionName: string}) {
		const controller = this.get(resource)
		const method = nonNull(
			controller[params.actionName],
			`Resource ${resource} does not have method ${params.actionName}`,
		).bind(controller)
		return method(params)
	}

	async getList(resource: ResourceName, params): Promise<any> {
		return this.get(resource).getList(params)
	}

	async getOne(resource: ResourceName, params): Promise<any> {
		return this.get(resource).getOne(params)
	}

	async getMany(resource: ResourceName, params): Promise<any> {
		return this.get(resource).getMany(params)
	}

	async getManyReference(resource: ResourceName, params): Promise<any> {
		return this.get(resource).getManyReference(params)
	}

	async create(resource: ResourceName, params): Promise<any> {
		return this.get(resource).create(params)
	}

	async update(resource: ResourceName, params): Promise<any> {
		return this.get(resource).update(params)
	}

	async updateMany(resource: ResourceName, params): Promise<any> {
		return this.get(resource).updateMany(params)
	}

	async delete(resource: ResourceName, params): Promise<any> {
		return this.get(resource).delete(params)
	}

	async deleteMany(resource: ResourceName, params): Promise<any> {
		return this.get(resource).deleteMany(params)
	}
}

export const dataProviderV3 = new DataProvider([
	companyController,
	projectController,
	userController,
	analysisController,
	watchlistController,
	versionController,
	projectFileController,
	rootFileController,
	modelController,
	modelUploadJobController,
	modelSetupJobController,
	reclassificationsController,
	userRoleController,
	teamController,
	reclassificationStagesTemplateController,
	teamMemberController,
	workspaceController,
	authController,
	filterPresetController,
	scansController,
	analysisProcessingJobController,
	roiController,
	rootFileController,
	analysisProcessingJobSimpleController,
	masvController,
	analysisViewController,
	changelogControllerPhase1,
	changelogControllerPhase2,
	changelogControllerPhase3,
	pendingMigrationsPhase1Controller,
	pendingMigrationsPhase2Controller,
	pendingMigrationsPhase3Controller,
	elementTypesController,
	featureFlagsController,
	suggestionController,
])
