import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import {modelUploadJobService} from '../../data_provider/v3/services/model-upload-job.service'

export function CleanUpModelUploadJobButton({record, projectId}: any) {
	let clicked = false
	return !record ? null : (
		<Button
			component={Link}
			disabled={clicked || !['populating', 'error'].includes(record?.status)}
			onClick={async () => {
				clicked = true
				await modelUploadJobService.cleanUpModelUploadJobByProjectId(projectId, record!._id)
				window.location.reload()
			}}
			label="Clean up"
			title="Clean up"
		/>
	)
}
