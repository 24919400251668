import React from 'react'
import {DeleteButton, Show, SimpleShowLayout, TextField} from 'react-admin'
import {DownloadFileButton} from '../buttons/DownloadFileButton'

export function MasvShow(props) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => (projectId ? `/files` : `/projects/${projectId}/show/files`)
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="s3Key" label="filename" />
				<TextField source="contentType" />
				<TextField source="size" />
				<TextField source="createdDate" />
				<TextField source="s3Key" />
			</SimpleShowLayout>
		</Show>
	)
}
