import {ModelUploadJobRepository, modelUploadJobRepository} from '../repositories/model-upload-job.repository'
import {BaseServiceByProject} from './base.service.by-project'

export class ModelUploadJobService extends BaseServiceByProject<ModelUploadJobRepository> {
	repository = modelUploadJobRepository

	async startModelUploadJobByProjectId(projectId: string, id: string) {
		return this.encodeResult(await this.repository.startModelUploadJobByProjectId(projectId, id))
	}

	async cleanUpModelUploadJobByProjectId(projectId: string, id: string) {
		return this.encodeResult(await this.repository.cleanUpModelUploadJobByProjectId(projectId, id))
	}

	async createFromModel(projectId: string, data: {modelName: string; modelId: string; shallowCopy: boolean}) {
		return this.encodeResult(await this.repository.createFromModel(projectId, data))
	}
}

export const modelUploadJobService = new ModelUploadJobService()
