import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
	FormTab,
	required,
	TabbedForm,
	TextInput,
	DateTimeInput,
	SelectInput,
	FormDataConsumer,
	BooleanInput,
	ReferenceArrayInput,
	ReferenceInput,
	AutocompleteInput,
	AutocompleteArrayInput,
} from 'react-admin'
import {SRCreate} from '../SRComponents/SRCreate'

const customStyles = makeStyles(theme => ({
	input: {
		display: 'flex',
		width: theme.spacing(32),
	},
}))

export function ScansCreate(props: any) {
	const classes = customStyles()
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => `/projects/${projectId}/show/scans`
	const transform = data => {
		return {
			...data,
			projectId,
		}
	}

	return (
		<SRCreate projectId={projectId} showBreadcrumb {...props} transform={transform}>
			<TabbedForm redirect={redirect}>
				<FormTab label="main">
					<TextInput source="name" label="Name" validate={[required()]} />
					<DateTimeInput source="scanDate" label="Scan Date" />
					<SelectInput
						source="surveyMode"
						validate={[required()]}
						defaultValue={{id: 'staticScanner', name: 'staticScanner'}}
						choices={[
							{id: 'staticScanner', name: 'staticScanner'},
							{id: 'robotV3', name: 'robotV3'},
							{id: 'navvisScanner', name: 'navvisScanner'},
							{id: 'navvisScannerV2', name: 'navvisScannerV2'},
							{id: 'blk2go', name: 'blk2go'},
						]}
					/>
					<FormDataConsumer>
						{({formData}) =>
							formData.surveyMode === 'staticScanner' && (
								<>
									<SelectInput
										className={classes.input}
										source="imagesSource"
										validate={[required()]}
										choices={[
											{id: 'e57', name: 'e57'},
											{id: 'synthetic', name: 'synthetic'},
											{id: 'none', name: 'none'},
										]}
									/>
									<div className={classes.input}>
										<ReferenceArrayInput
											label=".e57 Files"
											source="e57ProjectFileIds"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.e57', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteArrayInput optionText="filename" />
										</ReferenceArrayInput>
									</div>
									<ReferenceInput
										className={classes.input}
										label="URDF File"
										source="urdfFileId"
										reference="files"
										filterToQuery={q => ({q, fileEnd: '.urdf', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<BooleanInput source="isGeoReferenced" validate={[required()]} defaultValue={false} />
								</>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({formData}) =>
							formData.surveyMode === 'robotV3' && (
								<>
									<ReferenceInput
										className={classes.input}
										label="Rosbag File"
										source="rosbagProjectFileId"
										reference="project-files"
										filterToQuery={q => ({q, fileEnd: '.bag', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<ReferenceInput
										className={classes.input}
										label="URDF File"
										source="urdfFileId"
										reference="files"
										filterToQuery={q => ({q, fileEnd: '.urdf', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
								</>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({formData}) =>
							formData.surveyMode === 'navvisScanner' && (
								<>
									<SelectInput
										className={classes.input}
										source="imagesSource"
										validate={[required()]}
										choices={[
											{id: 'files', name: 'files'},
											{id: 'none', name: 'none'},
										]}
									/>
									<div className={classes.input}>
										<ReferenceArrayInput
											label=".e57 Files"
											source="e57ProjectFileIds"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.e57', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteArrayInput optionText="filename" />
										</ReferenceArrayInput>
									</div>
									<div className={classes.input}>
										<ReferenceInput
											label=".zip file"
											source="imagesZip"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.zip', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteInput optionText="filename" />
										</ReferenceInput>
									</div>
									<div className={classes.input}>
										<ReferenceInput
											label="scan poses"
											source="scanPoses"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.csv', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteInput optionText="filename" />
										</ReferenceInput>
									</div>
									<ReferenceInput
										className={classes.input}
										label="URDF File"
										source="urdfFileId"
										reference="files"
										filterToQuery={q => ({q, fileEnd: '.urdf', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<BooleanInput source="isGeoReferenced" validate={[required()]} defaultValue={false} />
								</>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({formData}) =>
							formData.surveyMode === 'navvisScannerV2' && (
								<>
									<SelectInput
										className={classes.input}
										source="imagesSource"
										validate={[required()]}
										choices={[
											{id: 'e57', name: 'e57'},
											{id: 'none', name: 'none'},
										]}
									/>
									<div className={classes.input}>
										<ReferenceArrayInput
											label=".e57 Files"
											source="e57ProjectFileIds"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.e57', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteArrayInput optionText="filename" />
										</ReferenceArrayInput>
									</div>
									<div className={classes.input}>
										<ReferenceInput
											label="scan poses"
											source="scanPoses"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.csv', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteInput optionText="filename" />
										</ReferenceInput>
									</div>
									<ReferenceInput
										className={classes.input}
										label="URDF File"
										source="urdfFileId"
										reference="files"
										filterToQuery={q => ({q, fileEnd: '.urdf', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<BooleanInput source="isGeoReferenced" validate={[required()]} defaultValue={false} />
								</>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({formData}) =>
							formData.surveyMode === 'blk2go' && (
								<>
									<SelectInput
										className={classes.input}
										source="imagesSource"
										validate={[required()]}
										choices={[
											{id: 'e57', name: 'e57'},
											{id: 'none', name: 'none'},
										]}
									/>
									<div className={classes.input}>
										<ReferenceArrayInput
											label=".e57 Files"
											source="e57ProjectFileIds"
											reference="project-files"
											filterToQuery={q => ({q, fileEnd: '.e57', status: 'uploaded'})}
											validate={[required()]}
										>
											<AutocompleteArrayInput optionText="filename" />
										</ReferenceArrayInput>
									</div>
									<ReferenceInput
										className={classes.input}
										label="URDF File"
										source="urdfFileId"
										reference="files"
										filterToQuery={q => ({q, fileEnd: '.urdf', status: 'uploaded'})}
										validate={[required()]}
									>
										<AutocompleteInput optionText="filename" multiline />
									</ReferenceInput>
									<BooleanInput source="isGeoReferenced" validate={[required()]} defaultValue={false} />
								</>
							)
						}
					</FormDataConsumer>
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
