import {config} from '../../config'
import {getIdToken} from '../../config/conn.config'
import {useEffect, useState} from 'react'

async function fetchRoles() {
	const url = `${config.sr.backendUrl}v3/roles/all`
	const accesstoken = await getIdToken()
	const headers = new Headers({accesstoken})
	const result = await fetch(url, {headers, method: 'GET'}).then(r => r.json())
	return result.map(role => {
		return {id: role, name: role}
	})
}

export const useGetRoles = () => {
	const [roles, setRoles] = useState([])
	useEffect(() => {
		fetchRoles().then(r => setRoles(r))
	}, [])
	return roles
}
