import {conn} from '../../../config/conn.config'
import {throwError} from '../../../utils/errors.utils'
import {Query} from './query'
import {ResourceName} from '../../../config/resources.config'
import {getResourceApi} from '../../resources_api/resources.apis'
import {ResourceApi} from '../../resources_api/ResourceApi'

export abstract class BaseRepository {
	readonly conn = conn
	abstract readonly name: ResourceName

	get api(): ResourceApi {
		return getResourceApi(this.name)
	}

	async getList(query: Query): Promise<{data: unknown[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {params: query})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getOne(id: string): Promise<{data: unknown}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(id))
			return {
				data: response.data,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getOne: ${e.message}`)
		}
	}

	async getManyReference(target: string, id: string, query: Query): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {params: {...query, ancestor: id}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getMany(ids: string[]): Promise<{data: unknown[]}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {params: {ids, limit: ids.length}})
			return {
				data: response.data.result,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getMany: ${e.message}`)
		}
	}

	async create(data: any): Promise<{data: unknown}> {
		const response = await this.conn.post(this.api.buildBaseUrl(), data)
		return {
			data: response.data,
		}
	}

	async update(id: string, data: any): Promise<{data: unknown}> {
		const response = await this.conn.put(this.api.buildBaseUrl(id), data)
		return {
			data: response.data,
		}
	}

	async updateMany(ids: string[], data: any): Promise<{data: unknown[]}> {
		const response = await this.conn.put(this.api.buildBaseUrl(), {ids, data})
		return {
			data: response.data,
		}
	}

	async delete(id: string): Promise<{data: unknown}> {
		const response = await this.conn.delete(this.api.buildBaseUrl(id))
		return {
			data: response.data,
		}
	}

	async deleteMany(ids: string[]): Promise<{data: string[]}> {
		await this.conn.delete(this.api.buildBaseUrl(), {params: {ids}})
		return {
			data: ids,
		}
	}
}
