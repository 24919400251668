import React from 'react'
import {Link} from 'react-router-dom'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {Button} from 'react-admin'

const styles = {
	button: {
		marginTop: '1em',
	},
}

const AddAnalysisProcessingJobButton = ({classes, record, newVersion, projectId}) => {
	if (!record) return null
	if (newVersion && record.scanDate === undefined) {
		throw new Error('New version = true can only be used within a ReferenceManyField for analyses.')
	}

	const sourceString = newVersion ? `&source=${JSON.stringify({newAnalysisVersion: true, analysisId: record.id})}` : ''

	return (
		<Button
			className={classes.button}
			variant="outlined"
			component={Link}
			to={`/analysis-processing-jobs/create?tenantId=${record?.tenantId}&projectId=${projectId}${sourceString}`}
			label="Create analysis processing job"
			title="Create analysis processing job"
		>
			<AddCircleOutlineIcon />
		</Button>
	)
}

export default withStyles(styles)(AddAnalysisProcessingJobButton)
