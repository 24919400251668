import React, {useEffect, useState} from 'react'
import {withStyles, makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorIcon from '@material-ui/icons/Error'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {truncate} from '../../utils/string'
import {Link} from 'react-router-dom'

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow)

const useStyles = makeStyles({
	table: {
		minWidth: 300,
		verticalAlign: 'bottom',
	},
})

export default function FileStatusTable({files, handleToggle, handleCancel}) {
	const classes = useStyles()

	const [open, setOpen] = React.useState(true)

	const rows = open ? files : []

	useEffect(() => {
		handleToggle(open)
	}, [open])

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell>Files</StyledTableCell>
						<StyledTableCell align="right">
							<IconButton aria-label="expand row" size="small" onClick={handleClick}>
								{open ? (
									<KeyboardArrowDownIcon style={{fill: 'white'}} />
								) : (
									<KeyboardArrowUpIcon style={{fill: 'white'}} />
								)}
							</IconButton>
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<StyledTableRow key={row._id}>
							<StyledTableCell component="th" scope="row">
								{truncate(row.filename, 30)}
							</StyledTableCell>
							<StyledTableCell align="right">
								{row.status === 'uploading' && (
									<ProgressWithCancel
										progress={row.progress}
										handleCancel={() => {
											handleCancel(row)
										}}
									/>
								)}
								{row.status === 'error' && (
									<Link
										to="#"
										onClick={e => {
											e.preventDefault()
											handleCancel(row)
										}}
									>
										<ErrorIcon style={{height: 20, fill: 'red'}} />
									</Link>
								)}
								{row.status === 'uploaded' && (
									<Link
										to="#"
										onClick={e => {
											e.preventDefault()
											handleCancel(row)
										}}
									>
										<CheckCircleIcon style={{height: 20, fill: 'green'}} />
									</Link>
								)}
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

function ProgressWithCancel({progress, handleCancel}) {
	const [hover, setHover] = useState(false)
	return (
		<div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
			{!hover && <CircularProgress thickness={5} variant="static" size={20} value={progress} />}
			{hover && <CancelIcon onClick={handleCancel} style={{height: 20, fill: 'black'}} />}
		</div>
	)
}
