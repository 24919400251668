import {DataProvider} from 'react-admin'
import {dataProviderV3} from './data-provider.v3'

//strange bug in react-admin, need to wrap dataProvider in an object
export default {
	getList: async (resource: any, params: any) => dataProviderV3.getList(resource, params),

	getOne: async (resource: any, params: any) => dataProviderV3.getOne(resource, params),

	getMany: async (resource: any, params: any) => dataProviderV3.getMany(resource, params),

	getManyReference: async (resource: any, params: any) => dataProviderV3.getManyReference(resource, params),

	update: async (resource: any, params: any) => dataProviderV3.update(resource, params),

	updateMany: async (resource: any, params) => dataProviderV3.updateMany(resource, params),

	create: async (resource: any, params: any) => dataProviderV3.create(resource, params),

	delete: async (resource: any, params: any) => dataProviderV3.delete(resource, params),

	deleteMany: async (resource: any, params: any) => dataProviderV3.deleteMany(resource, params),
	custom: async (resource: any, params: any) => dataProviderV3.custom(resource, params),
} as DataProvider
