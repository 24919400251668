import {BulkActionProps, Button, useListContext, useRedirect} from 'react-admin'
import React, {ReactElement, useEffect} from 'react'
import {masvController} from '../../data_provider/v3/controllers/masvs.controller'

type BulkImportToProjectProps = BulkActionProps & {projectId: string | null}
export const BulkImportToProjectButton = ({
	resource,
	selectedIds,
	projectId,
}: BulkImportToProjectProps): ReactElement | null => {
	const redirect = useRedirect()
	const len = selectedIds?.length || 0

	const {total, filterValues, displayedFilters, setFilters} = useListContext()
	useEffect(() => {
		if (projectId && filterValues && displayedFilters && setFilters) {
			setFilters(filterValues, {...displayedFilters, projectId})
		}
	}, [projectId, filterValues, displayedFilters, setFilters])

	const onClick = async () => {
		if (displayedFilters.projectId && selectedIds) {
			try {
				await masvController.copyToProject(
					displayedFilters.projectId,
					selectedIds.map(s => s.toString()),
				)
				redirect('/projects/' + displayedFilters.projectId + '/show/files')
			} catch (e) {}
		}
	}

	return <Button disabled={!displayedFilters.projectId || len === 0} label="Copy to project" onClick={onClick} />
}
