import React from 'react'
import {Edit, TabbedForm, FormTab, TextInput} from 'react-admin'

import {required, email, minLength, maxLength} from 'react-admin'

const validateEmail = [required('You must give an email'), email('Please input a valid email')]

const validateFirstAndLastName = [
	required("You must fillout user's first and last names"),
	minLength(1, 'Name must be at least one character'),
	maxLength(50, 'Name must be maximum characters'),
]

const validateUsername = [
	required('You must fillout username'),
	minLength(1, 'Username must be at least one character'),
	maxLength(25, 'Username must be maximum characters'),
]

export function UserEdit(props: any) {
	return (
		<Edit {...props}>
			<TabbedForm redirect="list">
				<FormTab label="main">
					<TextInput source="email" validate={validateEmail} />
					<TextInput source="username" validate={validateUsername} />
					<TextInput source="firstName" validate={validateFirstAndLastName} />
					<TextInput source="lastName" validate={validateFirstAndLastName} />
				</FormTab>
				<FormTab label="details">
					<TextInput source="cognitoUsername" />
				</FormTab>
			</TabbedForm>
		</Edit>
	)
}
