import {BaseControllerByProject} from './base.controller.by-project'
import {scansService, ScansService} from '../services/scans.service'
import _ from 'lodash'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

type StaticScannerDTO = {
	name: string
	scanDate: Date
	surveyMode: 'staticScanner'
	imagesSource: string
	e57ProjectFileIds: string[]
	urdfFileId: string
	isGeoReferenced: boolean
}

type NavvisScannerDTO = {
	name: string
	scanDate: Date
	surveyMode: 'navvisScanner'
	imagesSource: string
	e57ProjectFileIds: string[]
	imagesZip: string
	scanPoses: string
	urdfFileId: string
	isGeoReferenced: boolean
}

type Blk2goScannerDTO = {
	name: string
	scanDate: Date
	surveyMode: 'blk2go'
	imagesSource: string
	e57ProjectFileIds: string[]
	urdfFileId: string
	isGeoReferenced: boolean
}

type RobotV3ScannerDTO = {
	name: string
	scanDate: Date
	surveyMode: 'robotV3'
	urdfFileId: string
	rosbagProjectFileId: string
}

type NavvisScannerV2DTO = {
	name: string
	scanDate: Date
	surveyMode: 'navvisScannerV2'
	imagesSource: string
	e57ProjectFileIds: string[]
	scanPoses: string
	urdfFileId: string
	isGeoReferenced: boolean
}

type ScanParamsDTO = StaticScannerDTO | NavvisScannerDTO | RobotV3ScannerDTO | Blk2goScannerDTO | NavvisScannerV2DTO

function createParamsToDto(params: ScanParamsDTO) {
	switch (params.surveyMode) {
		case 'staticScanner':
			return {
				..._.pick(params, 'name', 'surveyMode', 'imagesSource', 'e57ProjectFileIds', 'urdfFileId', 'isGeoReferenced'),
				scanDate: new Date(params.scanDate).toISOString(),
			}
		case 'navvisScanner':
			return {
				..._.pick(
					params,
					'name',
					'surveyMode',
					'imagesSource',
					'e57ProjectFileIds',
					'imagesZip',
					'scanPoses',
					'urdfFileId',
					'isGeoReferenced',
				),
				scanDate: new Date(params.scanDate).toISOString(),
			}
		case 'blk2go':
			return {
				..._.pick(params, 'name', 'surveyMode', 'imagesSource', 'e57ProjectFileIds', 'urdfFileId', 'isGeoReferenced'),
				scanDate: new Date(params.scanDate).toISOString(),
			}
		case 'robotV3':
			return {
				..._.pick(params, 'name', 'surveyMode', 'urdfFileId', 'rosbagProjectFileId'),
				scanDate: new Date(params.scanDate).toISOString(),
			}
		case 'navvisScannerV2':
			return {
				..._.pick(
					params,
					'name',
					'surveyMode',
					'imagesSource',
					'e57ProjectFileIds',
					'scanPoses',
					'urdfFileId',
					'isGeoReferenced',
				),
				scanDate: new Date(params.scanDate).toISOString(),
			}
		default:
			throw new Error(`Unknown survey mode: ${JSON.stringify(params)}`)
	}
}

class ScansController extends BaseControllerByProject<ScansService> {
	service = scansService
	query = new ByFreeTextKeyQueryParams()

	async create(params: any): Promise<{data: any}> {
		const {projectId, ...data} = params.data
		if (!projectId) throw new Error('projectId not informed')
		const createDto = createParamsToDto(data)
		return this.service.create(projectId, createDto)
	}
}

export const scansController = new ScansController()
