import {BaseControllerByProject} from './base.controller.by-project'
import {analysisProcessingJobService, AnalysisProcessingJobService} from '../services/analysis-processing-job.service'
import {QueryParams} from '../utils/base.query-params'

class AnalysisProcessingJobQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, status, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`analysisMetaData.name=~"${q}"=si`)
		if (status) filterQuery.push(`status=in=(${status.join(',')})`)
		return filterQuery.length ? `(${filterQuery.join(';')})` : null
	}
}

class AnalysisProcessingJobController extends BaseControllerByProject<AnalysisProcessingJobService> {
	service = analysisProcessingJobService
	query = new AnalysisProcessingJobQueryParams()

	async create(params: any): Promise<{data: any}> {
		const {projectId, ...data} = params.data
		if (!projectId) throw new Error('projectId not informed')
		return this.service.create(projectId, data)
	}

	async update(params: {
		id: string
		data: {
			type: 'start'
			projectId: string
		}
	}): Promise<{data: any}> {
		if (!params.data.projectId) throw new Error('ProjectId not passed to controller start() method.')
		if (params.data.type !== 'start')
			throw new Error(`No update method implemented for type ${params.data.type} in AnalysisProcessingJobController.`)
		return this.service.startAnalysisProcessingJobByProjectId(params.data.projectId, params.id)
	}
}

export const analysisProcessingJobController = new AnalysisProcessingJobController()
