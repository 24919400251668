import {Datagrid, FunctionField, SimpleShowLayout, Tab, TextField} from 'react-admin'
import React from 'react'
import {Divider, Typography} from '@material-ui/core'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import AddEOIButton from './AddEOIButton'

const lineSeparatedIds = record => record.externalElementIds.map(id => <div key={id}>{id}</div>)

export const ElementsOfInterestProjectTab = props => {
	return (
		<Tab {...props}>
			<Typography variant="h6" component="div" gutterBottom>
				Create elements of interest set
			</Typography>
			<Divider />
			<AddEOIButton {...props} />
			<Typography variant="h6" component="div" gutterBottom>
				Elements of interest sets
			</Typography>

			<SRReferenceManyField source="_id" reference="suggestions" target="projects" addLabel={false} fullWidth>
				<SimpleShowLayout>
					<Datagrid>
						<TextField label="Analysis name" source="analysisViewName" />
						<FunctionField label="Elements of interest: Global IDs" render={lineSeparatedIds}></FunctionField>
					</Datagrid>
				</SimpleShowLayout>
			</SRReferenceManyField>
		</Tab>
	)
}
