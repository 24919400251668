import {ResourceName} from '../../../config/resources.config'
import {BaseRepositoryByProject} from './base.repositories.by-project'
import {getIdToken} from '../../../config/conn.config'
import {throwError} from '../../../utils/errors.utils'
import {config} from '../../../config'

export class AnalysisViewRepository extends BaseRepositoryByProject {
	name: ResourceName = 'analysis-view'

	async createFakeAnalysis(params: {
		projectId: string
		data: {
			modelId: string
			metadata: object[]
			percentage: number
		}
	}): Promise<{data: any}> {
		const result = await this.conn.post(
			this.api.buildProjectUrl(params.projectId, undefined, 'generateFakeAnalysis'),
			params.data,
		)
		return {
			data: result.data,
		}
	}

	transferLabels = async (
		projectId: string,
		analysisViewId: string,
		sourceProjectId: string,
		sourceAnalysisViewId: string,
		sourceVersionMap: any,
		targetVersionMap: any,
	) => {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, analysisViewId, `transfer-labels`), {
			sourceProjectId,
			sourceAnalysisViewId,
			sourceVersionMap,
			targetVersionMap,
		})
		return response.data
	}

	importAnalysis = async (
		projectId: string,
		sourceProjectId: string,
		sourceAnalysisViewId: string,
		sourceVersionMap: any,
		targetModel: string,
		elementIdsMappingFileId?: string,
	) => {
		try {
			const url = `${config.sr.backendUrl}${this.api.buildProjectUrl(projectId, `import-analysis`)}`
			const accesstoken = await getIdToken()
			const headers = new Headers({
				accesstoken,
				'transfer-encoding': 'chunked',
				'Content-Type': 'application/json',
			})
			return fetch(url, {
				headers,
				method: 'POST',
				body: JSON.stringify({
					sourceProjectId,
					sourceAnalysisViewId,
					sourceVersionMap,
					targetModel,
					elementIdsMappingFileId,
				}),
			})
		} catch (e) {
			throwError(`Error while calling ${this.name}.importAnalysis: ${e.message}`)
		}
	}
}

export const analysisViewRepository = new AnalysisViewRepository()
