import {BaseServiceByProject} from './base.service.by-project'
import {reclassificationsRepository, ReclassificationsRepository} from '../repositories/reclassifications.repository'

export class ReclassificationService extends BaseServiceByProject<ReclassificationsRepository> {
	readonly repository: ReclassificationsRepository = reclassificationsRepository

	async create(projectId: string, data: any): Promise<{data: unknown}> {
		return super.create(projectId, {
			...data,
			confidenceThreshold: data.confidenceThreshold ? Number(data.confidenceThreshold) / 100.0 : undefined,
			bypassSpecifications: data.bypassSpecifications?.map(spec => ({
				...spec,
				...(spec.confidenceThreshold ? {confidenceThreshold: Number(spec.confidenceThreshold) / 100.0} : {}),
			})),
		})
	}

	async findByVersionId(projectId: string, versionId: string): Promise<{data: any[]; total: number}> {
		return this.encodeResults(await this.repository.findByVersionId(projectId, versionId))
	}

	async createNewAnalysisVersionReclassificationTasks(projectId: string, id: string) {
		return this.encodeResult(await this.repository.createNewAnalysisVersionReclassificationTasks(projectId, id))
	}

	async createReclassificationTasksForTraining(projectId: string, params: any) {
		return this.encodeResult(
			await this.repository.createReclassificationTasksForTraining(projectId, {
				...params,
				confidenceThreshold: params.confidenceThreshold ? Number(params.confidenceThreshold) / 100.0 : undefined,
			}),
		)
	}
}

export const reclassificationService = new ReclassificationService()
