import {Datagrid, DeleteButton, EditButton, Filter, SearchInput, SimpleShowLayout, Tab, TextField} from 'react-admin'
import React from 'react'
import AddAuthButton from '../buttons/AddAuthButton'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'

export const SecurityProjectTab = props => (
	<Tab {...props}>
		<AddAuthButton {...props} entityType={'project'} />
		<SRReferenceManyField
			source="_id"
			reference="auths"
			target="entity"
			sort={{field: 'memberName', order: 'ASC'}}
			addLabel={false}
			fullWidth
		>
			<SimpleShowLayout>
				<Filter>
					<SearchInput source="q" alwaysOn />
				</Filter>
				<Datagrid>
					<TextField source="memberName" />
					<TextField source="email" />
					<TextField source="memberType" label={'Member type'} />
					<TextField source="role" />
					<EditButton />
					<DeleteButton redirect={false} undoable={false} />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
