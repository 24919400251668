import React from 'react'
import {
	AutocompleteInput,
	Create,
	FormTab,
	maxLength,
	minLength,
	ReferenceField,
	ReferenceInput,
	required,
	SimpleShowLayout,
	TabbedForm,
	TextField,
	TextInput,
} from 'react-admin'
import {goBack} from '../../utils/redirects.utils'

const validateName = [
	required('You must set a name for the team!'),
	minLength(1, 'Team name must be at least one character long'),
	maxLength(50, 'Team name must maximum 50 characters long'),
]

const validateCompany = [required('You must select a company!')]

export function WorkspaceCreate(props: any) {
	const companyId = new URLSearchParams(props.location.search).get('companyId') || null
	const parentId = new URLSearchParams(props.location.search).get('parentId') || null

	const transform = (data: any): any => {
		const parent = parentId ? data.workspace : data.company
		return {
			...data,
			parent,
		}
	}
	return (
		<Create {...props} transform={transform}>
			<TabbedForm redirect={goBack(props)} initialValues={{company: companyId, workspace: parentId}}>
				<FormTab label="main">
					{!parentId && (
						<ReferenceInput
							name="company"
							source="company"
							reference="companies"
							label="Company"
							validate={validateCompany}
						>
							<AutocompleteInput optionText="name" multiline />
						</ReferenceInput>
					)}
					{parentId && (
						<ReferenceInput name="workspace" source="parent" reference="workspaces" label="Workspace">
							<AutocompleteInput optionText="name" multiline />
						</ReferenceInput>
					)}
					<TextInput name="name" source="name" label="Name" validate={validateName} multiline />
					<TextInput name="description" source="description" label="Description" multiline />
				</FormTab>
			</TabbedForm>
		</Create>
	)
}
