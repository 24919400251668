import React from 'react'

import {useNotify, useRedirect, useRefresh, useMutation, Button} from 'react-admin'

import DomainIcon from '@material-ui/icons/Domain'
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled'

export function PublishOrUnpublishVersionButton(props: any) {
	const notify = useNotify()
	const redirect = useRedirect()
	const refresh = useRefresh()

	const payload = {
		id: props.record?.id,
		projectId: props.projectId,
		data: {
			tenantId: props.record?.tenantId,
			message: props.record?.message,
			status: props.record?.status === 'stable' ? 'dev' : 'stable',
		},
	}

	const [action, {loading}] = useMutation(
		{
			type: 'update',
			resource: 'versions',
			payload,
		},
		{
			undoable: false,
			onSuccess: ({data}: any) => {
				notify('ra.notification.updated', 'info', {smart_count: 1}, false)
				redirect(false)
				refresh()
			},
			onFailure: (error: any) => {
				notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
			},
		},
	)
	return (
		<Button
			{...props}
			onClick={action}
			label={props.record?.status === 'stable' ? 'Unpublish' : 'Publish'}
			redirect={false}
			disabled={loading}
		>
			{props.record?.status === 'stable' ? <DomainDisabledIcon /> : <DomainIcon />}
		</Button>
	)
}
