import React, {useState} from 'react'
import {
	ArrayField,
	AutocompleteInput,
	BooleanInput,
	Create,
	Datagrid,
	FormDataConsumer,
	FormTab,
	NumberInput,
	ReferenceField,
	ReferenceInput,
	required,
	SelectInput,
	TabbedForm,
	TextField,
	TextInput,
	useGetOne,
	useQuery,
} from 'react-admin'
import {Typography} from '@material-ui/core'
import Joi from 'typesafe-joi'
import JEditor from '../projects/JEditor'
import {InputCheckSelect} from '../input-check/InputCheckSelect'
import {FeatureFlagsProvider, useFeatureEnabled} from '../../features/feature-flags/FeatureFlagsProvider'
import {ProjectFeatureFlagsWrapper} from '../../features/feature-flags/components/ProjectFeatureFlagsWrapper'
import {SRCreate} from '../SRComponents/SRCreate'

const validateTransform = (value: string) => {
	const TransformDTO = Joi.array().items(Joi.array().items(Joi.number()).length(4)).length(4).required()

	try {
		const jsonTransform = JSON.parse(value)
		const {error} = TransformDTO.validate(jsonTransform)
		if (!value || error) {
			return 'Transform Matrix does not have a valid format'
		}
	} catch (error) {
		return 'Transform Matrix is not valid json'
	}
}

const getAnalysisDeliveryChoices = (hasPartialModels: boolean | null) => {
	if (hasPartialModels === null) return null
	return hasPartialModels
		? [
				{id: 'Normal', name: 'Normal'},
				{id: 'Incremental', name: 'Incremental'},
		  ]
		: [{id: 'Normal', name: 'Normal'}]
}

function useGetModelData(formData: any) {
	const roiId = formData.roiId
	// TODO: validate if it’s necessary to use “useGetManyReference” instead of “useGetOne”
	const {data: roiData} = useGetOne('rois', roiId, {enabled: !!roiId})
	const {data: modelData} = useGetOne('models', roiData?.model, {enabled: !!roiData?.model})
	return (roiData?.model && modelData) || null
}

function useGetAnalysisView(analysisId: string | undefined) {
	const {data: analysisViewData} = useGetOne('analysis-view', analysisId!, {enabled: analysisId !== undefined})
	return analysisId !== undefined ? analysisViewData : null
}

function DeliveryTypeInput({formData}: any) {
	const modelData = useGetModelData(formData)
	const analysisDeliveryChoices = getAnalysisDeliveryChoices(!modelData ? null : modelData.partialModels?.length)
	return (
		analysisDeliveryChoices && (
			<>
				<Typography variant="subtitle1" component="div" gutterBottom>
					Analysis delivery
				</Typography>
				<SelectInput
					defaultValue="Normal"
					label={'Type'}
					source={'deliveryType'}
					choices={analysisDeliveryChoices}
					validate={[required()]}
				/>
			</>
		)
	)
}

function DeliveryTypeDetails({formData, ...props}: any) {
	const modelData = useGetModelData(formData)
	return formData.deliveryType !== 'incremental' || !modelData?.partialModels?.length ? null : (
		<>
			<Typography variant="subtitle1" component="div" gutterBottom>
				The following partial models will be delivered incrementally:
			</Typography>
			<ArrayField source="partialModels" label={''} {...props} record={modelData}>
				<Datagrid>
					<TextField source="name" label={'Partial model name'} />
					<ReferenceField
						label="CSV file name"
						source="externalIdsProjectFileId"
						reference="project-files"
						link={false}
					>
						<TextField source="filename" />
					</ReferenceField>
				</Datagrid>
			</ArrayField>
		</>
	)
}

export function AnalysisProcessingJobCreate(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	return projectId ? (
		<FeatureFlagsProvider>
			<ProjectFeatureFlagsWrapper projectId={projectId}>
				<AnalysisProcessingJobCreateWrapper {...props} />
			</ProjectFeatureFlagsWrapper>
		</FeatureFlagsProvider>
	) : null
}

export function AnalysisProcessingJobCreateWrapper(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const [analysisId, setAnalysisId] = useState(undefined)
	const analysisView = useGetAnalysisView(analysisId)
	const isScanInputChecksDisabled = useFeatureEnabled('scanInputChecksDisabled')
	const {data} = useQuery({
		type: 'getList',
		resource: 'scans',
		payload: {pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0}, sort: {field: '_id', order: ''}, filter: {}},
	})
	const redirect = () => `/projects/${projectId}/show/analyses`
	const transform = data => ({
		name: data.name,
		scanId: data.scanId,
		roiId: data.roiId,
		deliveryType: data.deliveryType,
		analysisId: data.analysisId,
		alignmentConfig: {
			mode: data.mode,
			transform: ['known', 'approximate'].includes(data.mode) ? JSON.parse(data.transform) : undefined,
		},
		analysisConfig: {
			scanMinRadius: data.scanMinRadius,
			scanMaxRadius: data.scanMaxRadius,
		},
		pipelineRuntimeConfig: {
			instanceMemory: data.instanceMemory,
		},
		manualMode: data.manualMode,
		projectId,
	})
	const matrix: number[][] = [
		[1, 0, 0, 0],
		[0, 1, 0, 0],
		[0, 0, 1, 0],
		[0, 0, 0, 1],
	]
	return (
		<SRCreate {...props} projectId={projectId} listTabName="analyses" showBreadcrumb transform={transform}>
			<TabbedForm redirect={redirect} initialValues={{transform: JSON.stringify(matrix)}}>
				<FormTab label="main">
					<BooleanInput label="Create a new version of an analysis" source="newAnalysisVersion" />
					<FormDataConsumer>
						{({formData}) => {
							setAnalysisId(formData.analysisId)
							return formData.newAnalysisVersion ? (
								<ReferenceInput
									label="Analysis"
									name="analysisId"
									source="analysisId"
									reference="analysis-view"
									validate={[required()]}
								>
									<AutocompleteInput optionText="name" multiline />
								</ReferenceInput>
							) : (
								<TextInput source="name" label="Analysis Name" validate={[required()]} />
							)
						}}
					</FormDataConsumer>
					{data ? (
						<InputCheckSelect data={data} isInputChecksDisabled={isScanInputChecksDisabled} source="scanId" />
					) : null}
					<ReferenceInput
						label="ROI"
						name="roiId"
						source="roiId"
						filter={{model: analysisView?.model?._id}}
						reference="rois"
						validate={[required()]}
					>
						<AutocompleteInput optionText="name" multiline />
					</ReferenceInput>
					<FormDataConsumer>{({formData}) => <DeliveryTypeInput formData={formData} />}</FormDataConsumer>
					<FormDataConsumer>{({formData}) => <DeliveryTypeDetails formData={formData} {...props} />}</FormDataConsumer>
				</FormTab>
				<FormTab label="configuration">
					<BooleanInput source="manualMode" validate={[required()]} defaultValue={false} />
					<Typography variant="subtitle1" component="div" gutterBottom>
						Alignment Configuration
					</Typography>
					<SelectInput
						source="mode"
						validate={[required()]}
						choices={[
							{id: 'unsupervised', name: 'unsupervised'},
							{id: 'known', name: 'known'},
							{id: 'approximate', name: 'approximate'},
							{id: 'georeferenced', name: 'georeferenced'},
						]}
					/>
					<FormDataConsumer>
						{({formData}) =>
							(formData.mode === 'known' || formData.mode === 'approximate') && (
								<JEditor validate={[validateTransform]} source={'transform'} name="transform" label="Transform" />
							)
						}
					</FormDataConsumer>

					<Typography variant="subtitle1" component="div" gutterBottom>
						Analysis Configuration
					</Typography>
					<NumberInput source="scanMinRadius" validate={[required()]} />
					<NumberInput source="scanMaxRadius" validate={[required()]} />

					<Typography variant="subtitle1" component="div" gutterBottom>
						Pipeline Runtime Configuration
					</Typography>
					<SelectInput
						source="instanceMemory"
						validate={[required()]}
						choices={[
							{id: 'M32', name: 'M32'},
							{id: 'M64', name: 'M64'},
							{id: 'M128', name: 'M128'},
							{id: 'M192', name: 'M192'},
							{id: 'M256', name: 'M256'},
							{id: 'M384', name: 'M384'},
						]}
					/>
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
