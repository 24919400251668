import React, {useState} from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles'
import {Button, useGetOne, useNotify, useRedirect} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {projectController} from '../../data_provider/v3/controllers/project.controller'

const customStyles = makeStyles(
	(theme: Theme) => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
			width: '100%',
			borderRadius: 6,
		},
		dangerButton: {
			color: 'white',
			background: 'red',
			'&:hover, &:focus': {
				background: '#AA0000',
			},
			'&:active': {
				background: 'red',
			},
		},
		deactivatedDangerButton: {
			color: 'white',
			background: '#FF9999',
		},
		buttonSeparator: {
			flex: '1 0 0',
		},
		console: {
			color: 'white',
			background: 'black',
			height: 300,
			overflow: 'scroll',
		},
	}),
	{name: 'HardDeleteDialog'},
)

type DeleteStatus = 'not started' | 'running' | 'ended' | 'error'

const HardDeleteDialog = ({projectId, projectName, showDialog, handleCloseClick}) => {
	const classes = customStyles()
	const redirect = useRedirect()
	const [deleteLog, setDeleteLog] = useState<string>('')
	const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>('not started')
	const [input, setInput] = useState<string>('')
	const notify = useNotify()
	const disabled = input !== 'delete forever' || deleteStatus !== 'not started'
	const onClose = () => {
		setDeleteLog('')
		setDeleteStatus('not started')
		setInput('')
		handleCloseClick()
		if (deleteStatus === 'ended') redirect('/projects')
	}
	const handleDelete = () => {
		let log = ''
		setDeleteStatus('running')
		projectController
			.hardDelete(projectId)
			.then(async r => {
				const queuingStrategy = new CountQueuingStrategy({highWaterMark: 1})
				const writableStream = new WritableStream(
					{
						// Implement the sink
						write(chunk) {
							const text = new TextDecoder().decode(new Uint8Array(chunk))
							log += text
								.split('\\n')
								.map(text => `<div>${text}</div>`)
								.join('')
							setDeleteLog(log)
						},
						close() {
							setDeleteStatus('ended')
						},
						abort(err) {
							setDeleteStatus('error')
							notify(err?.message || 'deleted action aborted')
						},
					},
					queuingStrategy,
				)
				r.body!.pipeTo(writableStream)
			})
			.catch(e => notify(e.message))
	}
	return (
		<Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create post">
			<DialogTitle>Delete Forever</DialogTitle>
			<DialogContent>
				<h3>Do you want to permanently delete this project?</h3>
				The project {projectName} will be <b>permanently removed</b> from our database. This includes:
				<ul>
					<li>Viewer and analysis</li>
					<li>Assets</li>
					<li>Inputs, etc.</li>
				</ul>
				<div>
					<b>This action cannot be undone.</b>
				</div>
				<br />
				<div>Type "delete forever" to confirm.</div>
				<br />
				<input
					value={input}
					className={classes.input}
					onChange={e => setInput(e.target.value)}
					placeholder={'delete forever'}
				/>
				{deleteLog.length === 0 ? null : (
					<p>
						<div className={classes.console} dangerouslySetInnerHTML={{__html: deleteLog}} />
						<br />
						<div>{deleteStatus === 'ended' ? 'Delete Action finished' : ''}</div>
					</p>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					label={deleteStatus === 'ended' ? 'ra.action.close' : 'ra.action.cancel'}
					onClick={onClose}
					disabled={deleteStatus === 'running'}
				>
					<IconCancel />
				</Button>
				<div className={classes.buttonSeparator} />
				<Button
					className={disabled ? classes.deactivatedDangerButton : classes.dangerButton}
					onClick={handleDelete}
					label="Delete Forever"
					title="Delete Forever"
					disabled={disabled}
				>
					<DeleteForeverIcon />
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const HardDeleteProjectButton = props => {
	const classes = customStyles()
	const projectId = props.projectId
	const project = useGetOne('projects', projectId)?.data
	const [openModal, setOpenModal] = useState<boolean>(false)
	const toggleModal = () => setOpenModal(!openModal)

	return (
		projectId && (
			<>
				<Button className={classes.dangerButton} onClick={toggleModal} label="Delete Forever" title="Delete Forever">
					<DeleteForeverIcon />
				</Button>
				<HardDeleteDialog
					projectId={projectId}
					projectName={project?.name}
					showDialog={openModal}
					handleCloseClick={toggleModal}
				/>
			</>
		)
	)
}

export default HardDeleteProjectButton
