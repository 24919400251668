import React, {FunctionComponent} from 'react'
import VerticalTabbedShowLayout from '../ra-extensions/layouts/VerticalTabbedShowLayout'
import {FeatureFlagsProvider} from '../../features/feature-flags/FeatureFlagsProvider'
import {ProjectFeatureFlagsWrapper} from '../../features/feature-flags/components/ProjectFeatureFlagsWrapper'
import {SRShow} from '../SRComponents/SRShow'
import {FilesProjectTab} from '../project-files/FilesProjectTab'
import {MainProjectTab} from './projectTabs/MainProjectTab'
import {ModelsProjectTab} from '../models/ModelsProjectTab'
import {RoisProjectTab} from '../rois/RoisProjectTab'
import {ScansProjectTab} from '../scans/ScansProjectTab'
import {AnalysesProjectTab} from '../analysis/AnalysesProjectTab'
import {ReclassificationsProjectTab} from '../reclassifications/ReclassificationsProjectTab'
import {WatchlistsProjectTab} from './projectTabs/WatchlistsProjectTab'
import {SecurityProjectTab} from '../auths/SecurityProjectTab'
import {FilterPresetsProjectTab} from '../filter-presets/FilterPresetsProjectTab'
import {ViewerProjectTab} from './projectTabs/ViewerProjectTab'
import {DangerProjectTab} from './projectTabs/DangerProjectTab'
import _ from 'lodash'
import {ElementsOfInterestProjectTab} from '../elements-of-interest/ElementsOfInterestProjectTab'

export function ProjectShow(props: any) {
	return (
		<FeatureFlagsProvider>
			<ProjectFeatureFlagsWrapper projectId={props.id}>
				<ProjectShowWrapper {...props} />
			</ProjectFeatureFlagsWrapper>
		</FeatureFlagsProvider>
	)
}

export const PROJECT_TAB_DEFINITIONS: {id: string; label: string; Component: FunctionComponent}[] = [
	{id: '', label: 'Main', Component: MainProjectTab},
	{id: 'files', label: 'Files', Component: FilesProjectTab},
	{id: 'models', label: 'Models', Component: ModelsProjectTab},
	{id: 'rois', label: 'Rois', Component: RoisProjectTab},
	{id: 'scans', label: 'Scans', Component: ScansProjectTab},
	{id: 'analyses', label: 'Analyses', Component: AnalysesProjectTab},
	{id: 'reclassifications', label: 'Reclassifications', Component: ReclassificationsProjectTab},
	{id: 'watchlists', label: 'Watchlists', Component: WatchlistsProjectTab},
	{id: 'security', label: 'Security', Component: SecurityProjectTab},
	{id: 'filter-presets', label: 'Filter Presets', Component: FilterPresetsProjectTab},
	{id: 'elements-of-interest', label: 'Elements of interest', Component: ElementsOfInterestProjectTab},
	{id: 'viewer', label: 'Viewer', Component: ViewerProjectTab},
	{id: 'danger', label: 'Danger', Component: DangerProjectTab},
]
export const PROJECT_TAB_DEFINITIONS_BY_ID = _.keyBy(PROJECT_TAB_DEFINITIONS, 'id')

export function ProjectShowWrapper(props: any) {
	return (
		<SRShow {...props} projectId={props.id} showBreadcrumb>
			<VerticalTabbedShowLayout>
				{PROJECT_TAB_DEFINITIONS.map(ptd => (
					<ptd.Component label={ptd.label} path={ptd.id} {...props} />
				))}
			</VerticalTabbedShowLayout>
		</SRShow>
	)
}
