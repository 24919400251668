import {SuggestionService, suggestionService} from '../services/suggestion.service'
import {QueryParams} from '../utils/base.query-params'
import {BaseControllerByProject} from './base.controller.by-project'

class SuggestionController extends BaseControllerByProject<SuggestionService> {
	service = suggestionService
	query = new QueryParams()
}

export const suggestionController = new SuggestionController()
