import {BaseController} from './base.controller'
import {TeamMemberService, teamMemberService} from '../services/team-member.service'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

class TeamMemberController extends BaseController<TeamMemberService> {
	service = teamMemberService
	query = new ByFreeTextKeyQueryParams()

	static getTeamIdFromPath() {
		const matches = document.location.toString().match(/\/teams\/([a-z0-9]+)/)
		return matches ? matches[1] : undefined
	}

	teamId() {
		const teamId =
			new URLSearchParams(document.location.search).get('teamId') || TeamMemberController.getTeamIdFromPath()
		if (!teamId) throw new Error('teamId not informed as query param')
		return teamId
	}

	async getList(params: any) {
		const query = this.query.build(params)
		return this.service.getListForTeam(this.teamId(), query)
	}
}

export const teamMemberController = new TeamMemberController()
