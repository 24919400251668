import {BaseService} from './base.service'
import {
	AnalysisProcessingJobSimpleRepository,
	analysisProcessingJobSimpleRepository,
} from '../repositories/analysis-processing-job.simple.repository'

export class AnalysisProcessingJobSimpleService extends BaseService<AnalysisProcessingJobSimpleRepository> {
	repository = analysisProcessingJobSimpleRepository

	async errorPayload(id: string): Promise<string> {
		const {data} = await this.repository.errorPayload(id)
		return data
	}
}

export const analysisProcessingJobSimpleService = new AnalysisProcessingJobSimpleService()
