import {teamRepository, TeamRepository} from '../repositories/teams.repository'
import {BaseService, encodeCompany, encodeId} from './base.service'

export class TeamService extends BaseService<TeamRepository> {
	repository = teamRepository

	encodeResult({data, ...rest}: any): any {
		return {data: encodeCompany(encodeId(data)), ...rest}
	}

	encodeResults({data, ...rest}: any): any {
		return {data: data.map(e => encodeCompany(encodeId(e))), ...rest}
	}
}

export const teamService = new TeamService()
