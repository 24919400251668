import React from 'react'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import {projectFileService, rootFileService} from '../../data_provider/v3/services/files.service'

export const DownloadFileButton = (fileData: any) => (
	<Button
		color="primary"
		aria-label="Download File"
		onClick={async () => {
			const response =
				fileData.record.scope === 'project'
					? await projectFileService.getSignedUrl(fileData.record.scopeId, fileData.record._id)
					: await rootFileService.getSignedUrl(fileData.record._id)

			window.open(response.signedUrl)
		}}
		title="Download File"
		startIcon={<GetAppIcon />}
	>
		Download
	</Button>
)
