import {
	ArrayField,
	BooleanField,
	BooleanFieldProps,
	Datagrid,
	DateField,
	FunctionField,
	Labeled,
	NumberField,
	ReferenceField,
	ShowProps,
	SimpleShowLayout,
	TextField,
	TextFieldProps,
	useRecordContext,
} from 'react-admin'

import React from 'react'
import {Divider, Typography} from '@material-ui/core'
import {getFullName} from '../../utils/getters.utils'
import {makeStyles} from '@material-ui/core/styles'
import {SRShow} from '../SRComponents/SRShow'

const customStyles = makeStyles(theme => ({
	field: {
		display: 'flex',
		width: theme.spacing(32),
	},
	divider: {
		margin: '10px 0',
	},
}))

function IsNewVersionField(props: BooleanFieldProps) {
	const {record} = useRecordContext(props)
	return record ? (
		<BooleanField {...props} record={{...record, isNewVersion: !!record.analysisId}} source="isNewVersion" />
	) : null
}

IsNewVersionField.defaultProps = {
	addLabel: true,
	label: 'Creates a new version for an existing analysis',
}

function ConditionalNameField(props: TextFieldProps) {
	const {record} = useRecordContext(props)
	return record && !record.analysisId ? (
		<Labeled label="New analysis name">
			<TextField {...props} source="analysisMetaData.name" />
		</Labeled>
	) : null
}

function ConditionalAnalysisReferenceField(props: {record?: any}) {
	const {record} = useRecordContext(props)
	return record && !!record.analysisId ? (
		<Labeled label="New version for">
			<ReferenceField {...props} source="analysisId" reference="analysis-view" link={false}>
				<TextField source="name" />
			</ReferenceField>
		</Labeled>
	) : null
}

function ConditionalAnalysisStatusError(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record && record.status === 'error' ? (
		<>
			<Labeled label="Execution Artifacts S3 Bucket" className={classes.field}>
				<TextField {...props} label="Execution Artifacts S3 Bucket" source="errorPayload.executionArtifacts.bucket" />
			</Labeled>
			<Labeled label="Execution Artifacts S3 Key" className={classes.field}>
				<TextField {...props} label="Execution Artifacts S3 Key" source="errorPayload.executionArtifacts.key" />
			</Labeled>
			<ArrayField {...props} className={classes.field} source="errorPayload.failedStages">
				<Labeled label="Failed Stages">
					<Datagrid>
						<TextField source="name" label="Name" sortable={false} />
					</Datagrid>
				</Labeled>
			</ArrayField>
		</>
	) : null
}

export function AnalysisProcessingJobsShow(props: ShowProps) {
	const searchParams = new URLSearchParams(props.location?.search)
	const projectId = searchParams.get('projectId')
	const classes = customStyles()
	return (
		<SRShow
			{...props}
			entityNameProp={entity => entity.analysisMetaData.name}
			projectId={projectId}
			fileId={props.id}
			listTabName="analyses"
			showBreadcrumb
		>
			<SimpleShowLayout>
				<TextField source="_id" label="Transaction Id" />
				<Typography variant="h5" component="div">
					Configuration
				</Typography>
				<IsNewVersionField />
				<ConditionalAnalysisReferenceField />
				<ConditionalNameField />
				<ReferenceField
					label="Scan"
					source="scanId"
					reference="scans"
					link={(record, reference) => `/${reference}/${record.scanId}/show?projectId=${projectId}`}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="ROI"
					source="roiId"
					reference="rois"
					link={(record, reference) => `/${reference}/${record.roiId}/show?projectId=${projectId}`}
				>
					<TextField source="name" />
				</ReferenceField>
				<DateField source="createdDate" />
				<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
					<FunctionField render={getFullName} />
				</ReferenceField>
				<BooleanField source="manualMode" />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Alignment Configuration
				</Typography>
				<TextField source="alignmentConfig.mode" label="Mode" />
				<TextField source="alignmentConfig.transform" label="Transform" />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Analysis Configuration
				</Typography>
				<NumberField source="analysisConfig.scanMinRadius" label="Scan min radius" />
				<NumberField source="analysisConfig.scanMaxRadius" label="Scan max radius" />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Pipeline Runtime Configuration
				</Typography>
				<TextField source="pipelineRuntimeConfig.instanceMemory" label="Instance memory" />
				<Divider />
				<Typography variant="h5" component="div" className={classes.divider}>
					Status
				</Typography>
				<TextField source="status" />
				<ConditionalAnalysisStatusError />
			</SimpleShowLayout>
		</SRShow>
	)
}
