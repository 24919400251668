import React from 'react'
import {
	Edit,
	FormTab,
	maxLength,
	minLength,
	required,
	ShowButton,
	TabbedForm,
	TextInput,
	TopToolbar,
	useRedirect,
} from 'react-admin'
import {SREdit} from '../SRComponents/SREdit'

const validateName = [
	required('You must set a name for the analysis!'),
	minLength(1, 'Analysis name must be at least one character long'),
	maxLength(50, 'Analysis name must maximum 50 characters long'),
]

function AnalysisEditActions(props) {
	return !props.data ? null : (
		<TopToolbar key={props.data._id}>
			<ShowButton to={`${props.basePath}/${props.data._id}/show?projectId=${props.projectId}`} />
		</TopToolbar>
	)
}

export function AnalysisViewEdit(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = useRedirect()
	const onSuccess = () => redirect(false)
	return (
		<SREdit
			{...props}
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			listTabName="analyses"
			showBreadcrumb
			mutationMode="pessimistic"
			undoable={false}
			onSuccess={onSuccess}
			actions={<AnalysisEditActions projectId={projectId} />}
		>
			<TabbedForm>
				<FormTab label="main">
					<TextInput source="name" validate={validateName} multiline />
				</FormTab>
			</TabbedForm>
		</SREdit>
	)
}
