import React, {useEffect} from 'react'
import {Filter, SearchInput, useListContext} from 'react-admin'
import {masvController} from '../../data_provider/v3/controllers/masvs.controller'

export default function MasvFilters(props: any) {
	const {data, displayedFilters} = useListContext()
	const files = Object.keys(data || {})
	useEffect(() => {
		if (files && displayedFilters.projectId) {
			masvController.statusInProject(displayedFilters.projectId, files).then(r => {
				props.setMasvStatus(r)
			})
		}
	}, [])

	return (
		<Filter {...props}>
			<SearchInput source="q" alwaysOn />
		</Filter>
	)
}
