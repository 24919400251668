import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import App from './App'
import {config} from './config'

import {history} from './history'
import {CaptureConsole} from '@sentry/integrations'

if (config.sr.activate_sentry) {
	Sentry.init({
		dsn: 'https://69180fab5e76465c9bcabf84f7309385@o272185.ingest.sentry.io/6144095',
		integrations: [
			new CaptureConsole({levels: ['error']}),
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
				tracingOrigins: [config.sr.backendUrl],
			}),
		],
		tracesSampleRate: process.env.REACT_APP_STAGE === 'production' ? 0.3 : 1.0,
		environment: process.env.REACT_APP_STAGE,
	})
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
)
