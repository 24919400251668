import * as React from 'react'
import {FunctionComponent} from 'react'
import {Field, Form} from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useTranslate, useNotify, useSafeSetState} from 'ra-core'
import {useLocation, useHistory} from 'react-router-dom'

interface Props {
	onSubmit: (values: any, redirectTo?: string) => Promise<unknown | null>
	redirectTo?: string
}

interface FormData {
	password: string
	repeat_password: string
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			width: '100%',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
	}),
	{name: 'RaLoginForm'},
)

const Input = ({meta: {touched, error}, input: inputProps, ...props}: any) => (
	<TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
)

const ChangePasswordForm: FunctionComponent<Props> = props => {
	const {redirectTo, onSubmit} = props
	const [loading, setLoading]: [boolean, any] = useSafeSetState(false)
	const location = useLocation()
	const locationState = location.state as any
	const history = useHistory()
	const nextPathName = locationState && locationState.nextPathname
	const translate = useTranslate()
	const notify = useNotify()
	const classes = useStyles(props)
	const validate = (values: FormData) => {
		const errors: any = {password: undefined, repeat_password: undefined}

		if (!(values.password === values.repeat_password)) {
			errors.repeat_password = translate('Passwords must match')
		}
		return errors
	}

	const submit = async (values: any) => {
		setLoading(true)
		try {
			const response = await onSubmit(values)
			setLoading(false)
			if (response) history.push(nextPathName || redirectTo || '/')
		} catch (error: any) {
			setLoading(false)
			notify(
				typeof error === 'string'
					? error
					: typeof error === 'undefined' || !error.message
					? 'ra.auth.sign_in_error'
					: error.message,
				'warning',
			)
		}
	}

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<div className={classes.input}>
							<Field
								autoFocus
								id="password"
								name="password"
								component={Input}
								label={translate('ra.auth.password')}
								type="password"
								disabled={loading}
							/>
						</div>
						<div className={classes.input}>
							<Field
								id="repeat_password"
								name="repeat_password"
								component={Input}
								label={translate('ra.action.confirm') + ' ' + translate('ra.auth.password')}
								type="password"
								disabled={loading}
							/>
						</div>
					</div>
					<CardActions>
						<Button variant="contained" type="submit" color="primary" disabled={loading} className={classes.button}>
							{loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
							{translate('ra.action.save') + ' ' + translate('ra.auth.password')}
						</Button>
					</CardActions>
				</form>
			)}
		/>
	)
}

export default ChangePasswordForm
