import React from 'react'

import {useNotify, useRedirect, useRefresh, useMutation, Button} from 'react-admin'

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'

export function StartModelUploadJobButton(props: any) {
	const notify = useNotify()
	const redirect = useRedirect()
	const refresh = useRefresh()

	const to_be_run = props.record?.status === 'not_started' || props.record?.status === 'translated'
	const payload = {
		id: props.record?.id,
		data: {
			type: 'start',
			projectId: props.projectId,
		},
	}

	const [action, {loading}] = useMutation(
		{
			type: 'update',
			resource: 'model-upload-jobs',
			payload,
		},
		{
			undoable: false,
			onSuccess: () => {
				notify('ra.notification.updated', 'info', {smart_count: 1}, false)
				redirect(false)
				refresh()
			},
			onFailure: (error: any) => {
				notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
			},
		},
	)
	return (
		<Button {...props} onClick={action} label="Start" redirect={false} disabled={loading || !to_be_run}>
			<DirectionsRunIcon />
		</Button>
	)
}
