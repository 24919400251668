import React from 'react'

import {
	ArrayInput,
	AutocompleteInput,
	BooleanInput,
	FormDataConsumer,
	FormTab,
	maxValue,
	minValue,
	NumberInput,
	ReferenceField,
	ReferenceInput,
	required,
	SelectArrayInput,
	SelectInput,
	SimpleFormIterator,
	TabbedForm,
	TextField,
	TextInput,
	Toolbar,
	useGetOne,
} from 'react-admin'
import {getFullName} from '../../utils/getters.utils'
import {useElementTypesForSelector} from '../../data_provider/hooks/useElementTypesForSelector'
import {SRCreate} from '../SRComponents/SRCreate'

const BYPASS_TYPES = [
	{id: 'csv', name: 'CSV'},
	{id: 'filter', name: 'Filters'},
]

type Version = {id: string; model: string}
type BypassFormProps = {getSource: (source: string) => string; version: Version | undefined}

const CSVBypassForm = ({getSource}: BypassFormProps) => {
	return (
		<ReferenceInput
			label="Global Ids CSV File"
			source={getSource('externalIdsProjectFileId')}
			reference="project-files"
			filterToQuery={q => ({q, fileEnd: '.csv', status: 'uploaded'})}
			validate={[required()]}
		>
			<AutocompleteInput optionText="filename" multiline />
		</ReferenceInput>
	)
}

const FiltersBypassForm = ({getSource, version}: BypassFormProps) => {
	const elementTypes = useElementTypesForSelector(version?.model, 1)
	return (
		<>
			<SelectArrayInput
				style={{width: '250px'}}
				label={'ML Classification Labels'}
				source={getSource('mlClassificationLabels')}
				choices={[
					{id: 'verified', name: 'Verified'},
					{id: 'deviated', name: 'Deviated'},
					{id: 'missing', name: 'Missing'},
					{id: 'no_data', name: 'No data'},
				]}
			/>
			<br />
			{!elementTypes ? (
				'Loading element types...'
			) : (
				<SelectArrayInput
					style={{minWidth: '250px'}}
					label={'Element Types'}
					source={getSource('elementTypes')}
					choices={elementTypes}
				/>
			)}
			<br />
			<NumberInput
				style={{width: '250px'}}
				label={'Confidence From (in %)'}
				source={getSource('confidenceThreshold')}
				min={1}
				max={100}
				helperText={
					'If set, will bypass to final review classifications with confidence above the given % number. Enter a percent value between 1 and 100.'
				}
				validate={[minValue(1), maxValue(100)]}
			/>
		</>
	)
}

export function CreateReclassification(props: any) {
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	const redirect = () => `/projects/${projectId}/show/reclassifications`
	const sourceData = JSON.parse(decodeURI(searchParams.get('source')!))
	const {versionId} = sourceData
	const versionQuery = useGetOne<Version>('versions', versionId, {enabled: versionId})
	return (
		<SRCreate {...props} projectId={projectId} showBreadcrumb>
			<TabbedForm validate={() => ({})} redirect={redirect} toolbar={<Toolbar alwaysEnableSaveButton />}>
				<FormTab label="settings">
					<ReferenceField label={'Analysis Name'} link={false} source="versionId" reference="versions">
						<ReferenceField link={false} reference={'analyses'} source={'analysis'}>
							<TextField source="name" />
						</ReferenceField>
					</ReferenceField>
					<ReferenceField link={false} reference={'versions'} source={'versionId'}>
						<TextField source="versionNumber" />
					</ReferenceField>
					<TextInput label={'Name'} name={'name'} source={'name'} />
					<ReferenceInput
						label="Template"
						name="stagesTemplateId"
						source="stagesTemplateId"
						reference="reclassification-stages-templates"
						validate={[required()]}
						sort={{field: 'name', order: 'ASC'}}
					>
						<AutocompleteInput optionText={record => `${record.name}`} multiline />
					</ReferenceInput>
					<ReferenceInput
						label="Reclassification Manager"
						name="reclassificationCompanyManager"
						source="reclassificationCompanyManager"
						reference="users"
						validate={[required()]}
						sort={{field: 'email', order: 'ASC'}}
					>
						<AutocompleteInput optionText={getFullName} multiline />
					</ReferenceInput>
					<ReferenceInput
						label="Review Manager"
						name="reviewCompanyManager"
						source="reviewCompanyManager"
						reference="users"
						validate={[required()]}
						sort={{field: 'email', order: 'ASC'}}
					>
						<AutocompleteInput optionText={getFullName} multiline />
					</ReferenceInput>
					<ReferenceInput
						label="Reclassification Company"
						name="reclassificationCompany"
						source="reclassificationCompany"
						reference="companies"
						validate={[required()]}
						sort={{field: 'name', order: 'ASC'}}
					>
						<AutocompleteInput optionText={record => `${record.name}`} multiline />
					</ReferenceInput>
					<ReferenceInput
						label="Review Company"
						name="reviewCompany"
						source="reviewCompany"
						reference="companies"
						validate={[required()]}
						sort={{field: 'name', order: 'ASC'}}
					>
						<AutocompleteInput optionText={record => `${record.name}`} multiline />
					</ReferenceInput>
					<NumberInput
						label={'Confidence Threshold (in %)'}
						source={'confidenceThreshold'}
						min={1}
						max={100}
						helperText={
							'If set, the work packages will only contain classifications with confidence below the threshold. Enter a percent value between 1 and 100.'
						}
						validate={[minValue(1), maxValue(100)]}
					/>
					<NumberInput label={'Work Package Size'} source={'packageSize'} min={5} validate={[minValue(5)]} />
					<BooleanInput
						label="Use model assemblies if available"
						source="useAssemblies"
						defaultValue={true}
						style={{width: '350px'}}
					/>
				</FormTab>
				<FormTab label="options">
					<h3>Bypass to Final Review</h3>
					<text>
						The elements matching this conditions will bypass reclassification and go straight into final review.
					</text>
					<ArrayInput source="bypassSpecifications" label={'Bypass Selection'}>
						<SimpleFormIterator>
							<SelectInput label={'Bypass Type'} source={'bypassType'} choices={BYPASS_TYPES} validate={[required()]} />
							<FormDataConsumer>
								{({getSource, scopedFormData}) => {
									return getSource && scopedFormData ? (
										scopedFormData.bypassType === 'csv' ? (
											<CSVBypassForm {...{getSource, version: versionQuery.data}} />
										) : (
											<FiltersBypassForm {...{getSource, version: versionQuery.data}} />
										)
									) : null
								}}
							</FormDataConsumer>
						</SimpleFormIterator>
					</ArrayInput>
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
