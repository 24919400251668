export default {
	tenantId: '',
	users: [],
	name: '',
	company: '',
	documentUrn: 'urn:adsk.objects:os.object:',
	staticAssetsUrl: '',
	forgeCredentials: {
		client_id: '',
		client_secret: '',
	},
	viewerState: {},
	viewerFeatureFlags: {
		csvExportFormat: 'standard',
		forceNearRadius: null,
		maxElementTypeTreeDepth: null,
	},
	floorMapping: {Undefined: 'Undefined'},
	elementTypeMapping: {
		All: 'All',
		IFCANNOTATION: 'IfcAnnotation',
		IFCBEAM: 'IfcBeam',
		IFCBUILDING: 'IfcBuilding',
		IFCBUILDINGELEMENTPART: 'IfcBuildingElementPart',
		IFCBUILDINGELEMENTPROXY: 'IfcBuildingElementProxy',
		IFCBUILDINGSTOREY: 'IfcBuildingStorey',
		IFCCOLUMN: 'IfcColumn',
		IFCCOVERING: 'IfcCovering',
		IFCCURTAINWALL: 'IfcCurtainWall',
		IFCDISCRETEACCESSORY: 'IfcDiscreteAccessory',
		IFCDISTRIBUTIONCHAMBERELEMENT: 'IfcDistributionChamberElement',
		IFCDISTRIBUTIONCONTROLELEMENT: 'IfcDistributionControlElement',
		IFCDOOR: 'IfcDoor',
		IFCELECTRICDISTRIBUTIONPOINT: 'IfcElectricDistributionPoint',
		IFCELEMENTASSEMBLY: 'IfcElementAssembly',
		IFCENERGYCONVERSIONDEVICE: 'IfcEnergyConversionDevices',
		IFCFACETEDBREP: 'IfcFacetedBrep',
		IFCFASTENER: 'IfcFastener',
		IFCFLOWCONTROLLER: 'IfcFlowController',
		IFCFLOWFITTING: 'IfcFlow Fitting',
		IFCFLOWMOVINGDEVICE: 'IfcFlowMovingDevice',
		IFCFLOWSEGMENT: 'IfcFlowSegment',
		IFCFLOWTERMINAL: 'IfcFlowTerminal',
		IFCFLOWTREATMENTDEVICE: 'IfcFlowTreatmentDevice',
		IFCFOOTING: 'IfcFooting',
		IFCFURNISHINGELEMENT: 'IfcFurnishingElement',
		IFCGRID: 'IfcGrid',
		IFCMAPPEDITEM: 'IfcMappedItem',
		IFCMECHANICALFASTENER: 'IfcMechanicalFastener',
		IFCMEMBER: 'IfcMember',
		IFCPLATE: 'IfcPlate',
		IFCPROJECT: 'IfcProject',
		IFCPROXY: 'IfcProxy',
		IFCRAILING: 'IfcRailing',
		IFCSHAPEREPRESENTATION: 'IfcShapeRepresentation',
		IFCSITE: 'IfcSite',
		IFCSLAB: 'IfcSlab',
		IFCSPACE: 'IfcSpace',
		IFCSTAIR: 'IfcStair',
		IFCSTAIRFLIGHT: 'IfcStairFlight',
		IFCWALL: 'IfcWall',
		IFCWALLSTANDARDCASE: 'IfcWallStandardCase',
		IFCWINDOW: 'IfcWindow',
		'Revit Air Terminals': 'Air Terminals',
		'Revit Cable Tray Fittings': 'Cable Tray Fittings',
		'Revit Cable Trays': 'Cable Trays',
		'Revit Ceilings': 'Ceiling',
		'Revit Columns': 'Columns',
		'Revit Conduit Fittings': 'Conduit Fittings',
		'Revit Conduits': 'Conduits',
		'Revit Curtain Panels': 'Curtain Panels',
		'Revit Curtain Wall Mullions': 'Curtain Wall Mullions',
		'Revit Duct Fittings': 'Duct Fittings',
		'Revit Ducts': 'Ducts',
		'Revit Electrical Equipment': 'Electrical Equipment',
		'Revit Electrical Fixtures': 'Electrical Fixtures',
		'Revit Fire Alarm Devices': 'Fire Alarm Devices',
		'Revit Flex Ducts': 'Flex Ducts',
		'Revit Flex Pipes': 'Flex Pipes',
		'Revit Floors': 'Floors',
		'Revit Generic Models': 'Generic Models',
		'Revit Handrails': 'Handrails',
		'Revit Landings': 'Landings',
		'Revit Lighting Devices': 'Lighting Devices',
		'Revit Lighting Fixtures': 'Lighting Fixtures',
		'Revit Mechanical Equipment': 'Mechanical Equipment',
		'Revit Pipe Accessories': 'Pipe Accessories',
		'Revit Pipe Fittings': 'Pipe Fittings',
		'Revit Pipes': 'Pipes',
		'Revit Plumbing Fixtures': 'Plumbing Fixtures',
		'Revit Railings': 'Railings',
		'Revit Roofs': 'Roofs',
		'Revit Runs': 'Runs',
		'Revit Security Devices': 'Security Devices',
		'Revit Signage': 'Signage',
		'Revit Specialty Equipment': 'Specialty Equipment',
		'Revit Sprinklers': 'Sprinklers',
		'Revit Stairs': 'Stairs',
		'Revit Structural Columns': 'Structural Columns',
		'Revit Structural Connections': 'Structural Connections',
		'Revit Structural Framing': 'Structural Framing',
		'Revit Supports': 'Supports',
		'Revit Telephone Devices': 'Telephone Devices',
		'Revit Wall Sweeps': 'Wall Sweeps',
		'Revit Walls': 'Walls',
	},
	elementViewerTypesToHide: [],
	elementsToHide: [],
	env: {
		prod: {
			forgeBackEndUrl: `https://sr-web-back-end-v21-prod.scaledrobotics.com/api/v2/forge/token/`,
			srBackEndUrl: '',
		},
		stag: {
			forgeBackEndUrl: `https://sr-web-back-end-v2-staging.scaledrobotics.com/api/v2/forge/token/`,
			srBackEndUrl: '',
		},
		dev: {
			forgeBackEndUrl: `https://sr-web-back-end-v2-dev.scaledrobotics.com/api/v2/forge/token/`,
			srBackEndUrl: '',
		},
		localdev: {
			forgeBackEndUrl: `http://localhost:3002/api/v2/forge/token/`,
			srBackEndUrl: '',
		},
	},
}
