import React from 'react'
import {
	BulkDeleteButton,
	Datagrid,
	DateField,
	DeleteButton,
	EditButton,
	ReferenceField,
	ShowButton,
	TextField,
} from 'react-admin'

import CompaniesFilters from './CompaniesFilters'
import {SRList} from '../SRComponents/SRList'

export function CompaniesList(props: any) {
	return (
		<SRList
			{...props}
			filters={<CompaniesFilters />}
			sort={{field: 'name', order: 'ASC'}}
			bulkActionButtons={<BulkDeleteButton undoable={false} />}
		>
			<Datagrid>
				<TextField source="name" />
				<TextField source="description" />
				<ReferenceField source="owner" reference="users">
					<TextField source="email" />
				</ReferenceField>
				<DateField source="createdDate" />
				<ShowButton />
				<EditButton />
				<DeleteButton undoable={false} />
			</Datagrid>
		</SRList>
	)
}
