import {makeStyles, Theme} from '@material-ui/core/styles'
import {Button, required, SelectInput, SimpleForm, useNotify, useQuery, useRefresh} from 'react-admin'
import React, {useCallback, useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ImportExport from '@material-ui/icons/ImportExport'
import CircularProgress from '@material-ui/core/CircularProgress'
import {analysisViewService} from '../../../data_provider/v3/services/analysis-view.service'
import {AnalysisViewAnalysisAndVersionSelector} from '../shared/AnalysisViewAnalysisAndVersionSelector'

export const modalCustomStyles = makeStyles(
	(theme: Theme) => ({
		description: {color: '#565656', fontSize: '10pt'},
		section: {fontSize: '12pt', padding: '0 0 0 20px', width: '100%'},
		actions: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '16px',
		},
		confirmButton: {
			width: '200px',
			height: '36px',
			padding: '0 12px',
			borderRadius: '4px',
			backgroundColor: '#ffb800',
			color: '#000',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#c99301',
				color: '#000',
			},
		},
	}),
	{name: 'ConfirmActivatingModal'},
)

export const AnalysisViewLabelTransferModal = ({showDialog, onClose, record, projectId}) => {
	const notify = useNotify()
	const [fromConfig, setFromConfig] = useState<{
		project?: any
		analysisView?: any
		versionMap?: {
			[k in string]: string
		}
	}>({})
	const [targetConfig, setTargetConfig] = useState<{
		versionMap?: {
			[k in string]: string
		}
	}>({})
	const refresh = useRefresh()
	const classes = modalCustomStyles()
	const [loadingState, setLoadingState] = useState<{loading: boolean; error: string | null}>({
		loading: false,
		error: null,
	})
	const onTransfer = async () => {
		setLoadingState({loading: true, error: null})
		try {
			await analysisViewService.transferLabels(
				projectId,
				record._id,
				fromConfig.project._id,
				fromConfig.analysisView._id,
				fromConfig.versionMap,
				targetConfig.versionMap,
			)
			notify(
				'The labels were successfully transferred, checkout out the new analysis versions',
				'info',
				{smart_count: 1},
				false,
			)
			refresh()
		} catch (e) {
			setLoadingState({loading: false, error: e.message})
		}
	}
	const onCloseWrapper = useCallback(() => !loadingState.loading && onClose(), [onClose, loadingState])
	const {data: projects} = useQuery({
		type: 'getList',
		resource: 'projects',
		payload: {pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0}, sort: {field: '_id', order: ''}, filter: {}},
	})
	const {data: analysisViews} = useQuery({
		type: 'getList',
		resource: 'analysis-view',
		payload: {
			pagination: {perPage: Number.MAX_SAFE_INTEGER, page: 0},
			sort: {field: '_id', order: ''},
			filter: {},
			enabled: !!fromConfig.project?.id,
			projectId: fromConfig.project?.id,
		},
	})
	const setFromProject = useCallback(
		projectId =>
			setFromConfig({
				project: projects?.find(p => p.id === projectId),
			}),
		[projects],
	)
	const setFromAnalysisView = useCallback(
		analysisViewId =>
			setFromConfig({
				project: fromConfig.project,
				analysisView: analysisViews?.find(av => av.id === analysisViewId),
			}),
		[analysisViews, fromConfig.project],
	)
	const setFromVersionMap = useCallback(
		(versionMap: {[k in string]: string}) => setFromConfig({...fromConfig, versionMap}),
		[fromConfig],
	)
	const addToFromVersionMap = useCallback(
		(analysisId: string, version: string) => setFromVersionMap({...fromConfig.versionMap, [analysisId]: version}),
		[setFromVersionMap, fromConfig],
	)
	const setTargetVersionMap = useCallback(
		(versionMap: {[k in string]: string}) => setTargetConfig({...targetConfig, versionMap}),
		[targetConfig],
	)
	const addToTargetVersionMap = useCallback(
		(analysisId: string, version: string) => setTargetVersionMap({...targetConfig.versionMap, [analysisId]: version}),
		[setTargetVersionMap, targetConfig],
	)
	const submitEnabled = useCallback(
		() =>
			fromConfig.analysisView &&
			fromConfig.analysisView.analyses?.every(a => !!fromConfig.versionMap?.[a._id]) &&
			record.analyses?.every(a => !!targetConfig.versionMap?.[a._id]),
		[fromConfig, targetConfig, record],
	)
	useEffect(() => {
		setFromProject(projectId)
	}, [projects, projectId])
	return (
		<Dialog fullWidth open={showDialog} onClose={onCloseWrapper}>
			<DialogTitle>Transfer Labels between Analyses</DialogTitle>
			<DialogContent>
				{loadingState.error ? (
					<div style={{wordBreak: 'break-word', display: 'block', overflowX: 'auto'}}>
						<h3>There was an error trying to perform this operation, please try again</h3>
						<code>{loadingState.error}</code>
					</div>
				) : (
					<SimpleForm toolbar={<></>}>
						<span className={classes.description}>
							This operation will produce a new version on the selected analysis <b>{record.name}</b>. This new version
							will have merged classification labels. Please configure From which analysis and versions we should
							transfer the labels and which versions of the target analysis should be used as fallback when an element
							does not have a classification matching.
							<br />
							<b>Important:</b>
							<ul>
								<li>
									All elements on the source versions had to be contained in <b>{record.name}</b>
								</li>
								<li>
									Classifications in <b>{record.name}</b> versions for elements not present in the source will remain
									the same
								</li>
								<li>
									This functionality is intended to be used between analyses of the same or derived model (newer
									versions or sub set of it)
								</li>
							</ul>
						</span>
						<h3>1. Transfer labels From</h3>
						<div className={classes.section}>
							<div style={{display: 'flex', gap: '50px'}}>
								<SelectInput
									label={'Project'}
									choices={projects}
									source={'projectId'}
									validate={[required()]}
									onChange={ev => setFromProject(ev.target.value)}
									defaultValue={projects ? projectId : undefined}
									disabled={!projects}
								/>
								<SelectInput
									label={'Analysis'}
									choices={analysisViews}
									source={'analysisView'}
									validate={[required()]}
									onChange={ev => setFromAnalysisView(ev.target.value)}
									disabled={!fromConfig.project?.id}
									helperText={!fromConfig.project?.id ? 'Please select a project first' : undefined}
								/>
							</div>
							<AnalysisViewAnalysisAndVersionSelector
								keyPrefix={'from'}
								analysisView={fromConfig.analysisView}
								onVersionSelected={addToFromVersionMap}
								versionMap={fromConfig.versionMap}
								onVersionInitialValues={setFromVersionMap}
							/>
						</div>
						<h3 style={{width: '100%'}}>2. Use this versions from {record.name} as fallback</h3>
						<div className={classes.section}>
							<AnalysisViewAnalysisAndVersionSelector
								keyPrefix={'target'}
								analysisView={record}
								onVersionSelected={addToTargetVersionMap}
								versionMap={targetConfig.versionMap}
								onVersionInitialValues={setTargetVersionMap}
							/>
						</div>
					</SimpleForm>
				)}
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button onClick={onCloseWrapper} disabled={loadingState.loading} label="Close" title="Close" />
				{!loadingState.error && (
					<Button
						className={classes.confirmButton}
						onClick={onTransfer}
						label="Transfer labels"
						title="Transfer labels"
						disabled={loadingState.loading || !submitEnabled()}
					>
						{loadingState.loading ? <CircularProgress size={'16px'} /> : <ImportExport />}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)
}
