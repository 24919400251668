import {BaseControllerByProject} from './base.controller.by-project'
import {roiService, ROIService} from '../services/roi.service'
import {QueryParams} from '../utils/base.query-params'

function createParamsToDto(params: {
	name: string
	elementSetSpecifications: {type: string; tolerance?: number; externalIdsProjectFileId: string}[]
	ignoreMissingExternalIds: boolean
	model: string
}) {
	return {
		name: params.name,
		model: params.model,
		elementSetSpecifications: params.elementSetSpecifications.map(el => ({
			...el,
			tolerance: el.type === 'alignmentSupport' ? undefined : el.tolerance,
		})),
		ignoreMissingExternalIds: params.ignoreMissingExternalIds,
	}
}

class RoiQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, model, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`name=~"${q}"=si`)
		if (model) filterQuery.push(`model=="${model}"`)
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `(${filterQuery.join(';')})` : null
	}
}

class ROIController extends BaseControllerByProject<ROIService> {
	service = roiService
	query = new RoiQueryParams()

	async create(params: any): Promise<{data: any}> {
		const {projectId, ...data} = params.data
		if (!projectId) throw new Error('projectId not informed')
		const createDto = createParamsToDto(data)
		return this.service.create(projectId, createDto)
	}
}

export const roiController = new ROIController()
