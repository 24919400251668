import React, {useEffect, useState} from 'react'
import {
	Show,
	TabbedShowLayout,
	EmailField,
	TextField,
	Tab,
	Filter,
	SearchInput,
	SimpleShowLayout,
	Datagrid,
	EditButton,
	DeleteButton,
	useNotify,
} from 'react-admin'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import {userService} from '../../data_provider/v3/services/user.service'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'

export function UserShow(props: any) {
	const notify = useNotify()
	const [cognitoUserStatus, setCognitoUserStatus] = useState<any>(null)

	useEffect(() => {
		if (props.id) {
			userService.getCognitoUser(props.id).then(setCognitoUserStatus)
		}
	}, [props.id])

	const [openResetDialog, setOpenResetDialog] = React.useState(false)

	const handleClose = () => {
		setOpenResetDialog(false)
	}

	const handleAgree = async () => {
		setOpenResetDialog(false)
		await userService.resetPassword(props.id)
		notify('Password reset for ' + props.id)
	}

	return (
		<Show {...props}>
			<TabbedShowLayout>
				<Tab label="main">
					<EmailField source="email" />
					<TextField source="username" />
					<TextField source="firstName" />
					<TextField source="lastName" />
				</Tab>

				<Tab label="security">
					<SRReferenceManyField source="_id" reference="auths" target="user" addLabel={false} fullWidth>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="n" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="entityName" label={'Name'} />
								<TextField source="entityType" label={'Type'} />
								<TextField source="role" />
								<EditButton />
								<DeleteButton redirect={false} undoable={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>

				<Tab label={'danger'}>
					<Box m={3}>Current user cognito status: {cognitoUserStatus?.status}</Box>
					<Button variant="outlined" color="secondary" onClick={() => setOpenResetDialog(true)}>
						Reset Password
					</Button>
					<Dialog
						open={openResetDialog}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">Password reset</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Reset user password to 'SRScaled'.
								<br />
								User is not notified automatically.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Disagree
							</Button>
							<Button onClick={handleAgree} color="primary" autoFocus>
								Agree
							</Button>
							<br />
						</DialogActions>
					</Dialog>
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
}
