import {
	BooleanField,
	DateField,
	EditButton,
	FunctionField,
	Labeled,
	ReferenceField,
	Show,
	SimpleShowLayout,
	TextField,
	TopToolbar,
	useQuery,
	useRecordContext,
} from 'react-admin'

import React from 'react'
import {Typography} from '@material-ui/core'
import {getFullName} from '../../utils/getters.utils'
import {makeStyles} from '@material-ui/core/styles'
import {StatusChipOneStepModelSetupInfo} from './StatusChipOneStepModelSetupInfo'
import {SRShow} from '../SRComponents/SRShow'

const customStyles = makeStyles(theme => ({
	field: {
		display: 'flex',
		width: theme.spacing(32),
	},
	divider: {
		margin: '10px 0',
	},
}))

function ShowActions({basePath, data, projectId}: any) {
	return !data ? null : (
		<TopToolbar>
			<EditButton to={`${basePath}/${data._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

function ConditionalModelStatusError(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.status === 'error' ? (
		<>
			<Labeled label="Last status before the error" className={classes.field}>
				<TextField {...props} label="Last status before the error" source="lastError.lastStatus" />
			</Labeled>
			<Labeled label="Error message" className={classes.field}>
				<TextField {...props} label="Error message" source="lastError.message" />
			</Labeled>
			<Labeled label="Error callstack" className={classes.field}>
				<TextField {...props} label="Error callstack" source="lastError.callStack" />
			</Labeled>
		</>
	) : null
}

function ConditionalModelExecutionLog(props: {record?: any}) {
	const {record} = useRecordContext(props)
	return record?.executionLog ? (
		<>
			<Typography variant="h5" component="div">
				Execution Log
			</Typography>
			<div style={{overflowY: 'auto', maxHeight: '250px', whiteSpace: 'pre'}}>
				<code>{record.executionLog}</code>
			</div>
		</>
	) : null
}

function ConditionalModelTypeRevit(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record?.config?.modelType === 'Revit' ? (
		<>
			<Labeled label="Material mode">
				<TextField className={classes.field} {...props} source="config.materialMode" />
			</Labeled>
			<Labeled label="Generate master views">
				<BooleanField className={classes.field} {...props} source="config.generateMasterViews" />
			</Labeled>
		</>
	) : null
}

function ConditionalModelTypeIFC(props: {record?: any}) {
	const {record} = useRecordContext(props)
	return record?.config?.modelType === 'IFC' ? (
		<Labeled label="Forge engine">
			<TextField {...props} source="config.forgeEngine" />
		</Labeled>
	) : null
}

function ConditionalModelTypeNavisworks(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record.config.modelType === 'Navisworks' ? (
		<>
			<Labeled label="Parse NWD metadata">
				<BooleanField className={classes.field} {...props} source="config.parseNwdMetadata" />
			</Labeled>
			<Labeled label="Hidden objects">
				<BooleanField className={classes.field} {...props} source="config.hiddenObjects" />
			</Labeled>
			<Labeled label="Basic material properties">
				<BooleanField className={classes.field} {...props} source="config.basicMaterialProperties" />
			</Labeled>
			<Labeled label="Autodesk material properties">
				<BooleanField className={classes.field} {...props} source="config.autodeskMaterialProperties" />
			</Labeled>
			<Labeled label="Timeliner properties">
				<BooleanField className={classes.field} {...props} source="config.timelinerProperties" />
			</Labeled>
		</>
	) : null
}

export function ModelSetupJobShow(props) {
	const classes = customStyles()
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	const {data} = useQuery({
		type: 'getOne',
		resource: props.resource,
		payload: {id: props.id},
	})
	const config = data?.config
	return (
		<SRShow
			{...props}
			entityNameProp="modelName"
			projectId={projectId}
			fileId={props.id}
			listTabName="models"
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<SimpleShowLayout>
				<Typography variant="h5" component="div">
					Configuration
				</Typography>
				<TextField source="modelName" label="Model name" />
				<TextField source="config.outputFormat" label="Output Format" />
				<TextField source="config.modelType" label="Model type" />
				<BooleanField
					source="config.generateElementTypeTreeFromName"
					label="Generate element type tree from element name"
				/>
				<BooleanField source="config.internalSvfParser" label="Internal Svf Parser" />
				<BooleanField source="config.mergeAssemblies" label="Merge assemblies" />
				<BooleanField source="config.removeInvalidMeshes" label="Remove invalid meshes" />
				<ConditionalModelTypeRevit />
				<ConditionalModelTypeIFC />
				<ConditionalModelTypeNavisworks />
				<TextField style={{wordBreak: 'break-all'}} source="modelPath" />
				<TextField style={{wordBreak: 'break-all'}} source="metadataProjectFileS3Key" />
				<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
					<FunctionField render={getFullName} />
				</ReferenceField>
				<DateField source="createdDate" />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Translation coordinates (in mm)
				</Typography>
				<TextField source="coordinatesOffset.x" label="X:" />
				<TextField source="coordinatesOffset.y" label="Y:" />
				<TextField source="coordinatesOffset.z" label="Z:" />
				<Typography variant="h5" component="div" className={classes.divider}>
					Status
				</Typography>
				<StatusChipOneStepModelSetupInfo label={'Status'} />
				<TextField style={{wordBreak: 'break-all'}} source="modelUrn" />
				<ConditionalModelStatusError />
				<ConditionalModelExecutionLog />
			</SimpleShowLayout>
		</SRShow>
	)
}
