import {ModelUploadJobCreateFromModel} from './ModelUploadJobCreateFromModel'
import {ModelUploadJobCreate} from './ModelUploadJobCreate'
import React from 'react'

export function ModelUploadJobCreateWrapper(props) {
	const searchParams = new URLSearchParams(props.location.search)
	const context = searchParams.get('context')
	if (context === 'fromModel') {
		return <ModelUploadJobCreateFromModel {...props} />
	} else {
		return <ModelUploadJobCreate {...props} />
	}
}
