import {BaseRepositoryByProject} from '../repositories/base.repositories.by-project'
import {EncodeMixin} from './base.service'

export abstract class BaseServiceByProject<R extends BaseRepositoryByProject> extends EncodeMixin {
	abstract readonly repository: R

	get name() {
		return this.repository.name
	}

	async getList(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number | null,
		limit: number | null,
	): Promise<{data: unknown[]; total: number}> {
		return this.encodeResults(await this.repository.getList(projectId, filter, sort, skip, limit))
	}

	async getOne(projectId: string, id: string): Promise<{data: unknown}> {
		return this.encodeResult(await this.repository.getOne(projectId, id))
	}

	async getManyReference(
		target: string,
		id: string,
		filter: string | null,
		sort: string | null,
		skip: any,
		limit: any,
	) {
		return this.encodeResults(await this.repository.getManyReference(target, id, filter, sort, skip, limit))
	}

	async getMany(projectId: string, ids: string[]): Promise<{data: unknown[]}> {
		return this.encodeResults(await this.repository.getMany(projectId, ids))
	}

	async create(projectId: string, data: any): Promise<{data: unknown}> {
		data = this.encodeOutput(data)
		return this.encodeResult(await this.repository.create(projectId, data))
	}

	async update(projectId: string, id: string, data: any): Promise<{data: unknown}> {
		data = this.encodeOutput(data)
		return this.encodeResult(await this.repository.update(projectId, id, data))
	}

	async updateMany(projectId: string, ids: string[], data: any): Promise<{data: unknown[]}> {
		data = this.encodeOutput(data)
		return this.encodeResults(await this.repository.updateMany(projectId, ids, data))
	}

	async delete(projectId: string, id: string): Promise<{data: unknown}> {
		return await this.repository.delete(projectId, id)
	}

	async deleteMany(projectId: string, ids: string[]): Promise<{data: unknown[]}> {
		return await this.repository.deleteMany(projectId, ids)
	}
}
