import React from 'react'

import {Create, FormTab, required, TabbedForm, TextInput} from 'react-admin'
import {SRCreate} from '../SRComponents/SRCreate'

export function ModelUploadJobCreateFromModel(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => `/projects/${projectId}/show/models`
	const transform = data => ({
		...data,
		projectId,
	})
	return (
		<SRCreate {...props} projectId={projectId} listTabName="models" showBreadcrumb transform={transform}>
			<TabbedForm redirect={redirect}>
				<FormTab label="main">
					<TextInput
						helperText="If specified, it will be the name of the created model. If not, it will use the name of the model that is cloned."
						source="modelName"
						label="Model Name"
					/>
					<TextInput
						helperText="You can retrieve the ID using the quick-copy interaction next to its name in the models list or from the model 'show' tab."
						source="modelId"
						label="Reference Model ID"
						validate={[required()]}
					/>
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
