import {
	BooleanField,
	DateField,
	EditButton,
	FunctionField,
	Labeled,
	ReferenceField,
	SimpleShowLayout,
	TextField,
	TopToolbar,
	useQuery,
	useRecordContext,
} from 'react-admin'

import React from 'react'
import {Typography} from '@material-ui/core'
import {getFullName} from '../../utils/getters.utils'
import {makeStyles} from '@material-ui/core/styles'
import {SRShow} from '../SRComponents/SRShow'

const customStyles = makeStyles(theme => ({
	field: {
		display: 'flex',
		width: theme.spacing(32),
	},
	divider: {
		margin: '10px 0',
	},
}))

function ShowActions({basePath, data, projectId}: any) {
	return !data ? null : (
		<TopToolbar>
			<EditButton to={`${basePath}/${data._id}?projectId=${projectId}`} />
		</TopToolbar>
	)
}

function ConditionalModelStatusError(props: {record?: any}) {
	const classes = customStyles()
	const {record} = useRecordContext(props)
	return record && record.status === 'error' ? (
		<>
			<Labeled label="Last status before the error" className={classes.field}>
				<TextField {...props} label="Last status before the error" source="lastError.lastStatus" />
			</Labeled>
			<Labeled label="Error message" className={classes.field}>
				<TextField {...props} label="Error message" source="lastError.message" />
			</Labeled>
			<Labeled label="Error callstack" className={classes.field}>
				<TextField {...props} label="Error callstack" source="lastError.callStack" />
			</Labeled>
		</>
	) : null
}

export function ModelUploadJobShow(props) {
	const classes = customStyles()
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	const {data} = useQuery({
		type: 'getOne',
		resource: props.resource,
		payload: {id: props.id},
	})
	const config = data?.config
	return (
		<SRShow
			{...props}
			entityNameProp="modelName"
			projectId={projectId}
			fileId={props.id}
			listTabName="models"
			showBreadcrumb
			actions={<ShowActions projectId={projectId} />}
		>
			<SimpleShowLayout>
				<Typography variant="h5" component="div">
					Configuration
				</Typography>
				<TextField source="modelName" label="Model name" />
				<TextField source="config.outputFormat" label="Output Format" />
				<TextField source="config.modelType" label="Model type" />
				<BooleanField
					source="config.generateElementTypeTreeFromName"
					label="Generate element type tree from element name"
				/>
				{config?.forgeEngine && <TextField source="config.forgeEngine" label="Forge engine" />}
				{config?.generateMasterViews && (
					<BooleanField source="config.generateMasterViews" label="Generate master views" />
				)}
				{config?.materialMode && <TextField source="config.materialMode" label="Material mode" />}
				{config?.hiddenObjects && <BooleanField source="config.hiddenObjects" label="Hidden objects" />}
				{config?.basicMaterialProperties && (
					<BooleanField source="config.basicMaterialProperties" label="Basic material properties" />
				)}
				{config?.autodeskMaterialProperties && (
					<BooleanField source="config.autodeskMaterialProperties" label="Autodesk material properties" />
				)}
				{config?.timelinerProperties && (
					<BooleanField source="config.timelinerProperties" label="Timeliner properties" />
				)}
				<TextField style={{wordBreak: 'break-all'}} source="modelPath" />
				<TextField style={{wordBreak: 'break-all'}} source="metadataProjectFileS3Key" />
				<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
					<FunctionField render={getFullName} />
				</ReferenceField>
				<DateField source="createdDate" />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Translation coordinates (in mm)
				</Typography>
				<TextField source="coordinatesOffset.x" label="X:" />
				<TextField source="coordinatesOffset.y" label="Y:" />
				<TextField source="coordinatesOffset.z" label="Z:" />
				<Typography variant="h5" component="div" className={classes.divider}>
					Status
				</Typography>
				<TextField source="status" />
				<TextField style={{wordBreak: 'break-all'}} source="modelUrn" />
				<ConditionalModelStatusError />
			</SimpleShowLayout>
		</SRShow>
	)
}
