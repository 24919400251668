import {BaseRepository} from './base.repository'
import {throwError} from '../../../utils/errors.utils'
import {ResourceName} from '../../../config/resources.config'
import {config} from '../../../config'
import {getIdToken} from '../../../config/conn.config'

export class ProjectRepository extends BaseRepository {
	name: ResourceName = 'projects'

	async hasPermission(id: string, permission: string): Promise<boolean> {
		try {
			const response = await this.conn.get(`${this.api.buildBaseUrl(id)}has-permission/${permission}`)
			return response.data
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.hasPermission: ${e.message}`)
		}
	}

	async hardDelete(id: string): Promise<Response> {
		try {
			const url = `${config.sr.backendUrl}${this.api.buildBaseUrl(id)}hard`
			const accesstoken = await getIdToken()
			const headers = new Headers({accesstoken, 'transfer-encoding': 'chunked'})
			return fetch(url, {headers, method: 'DELETE'})
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.hardDelete: ${e.message}`)
		}
	}

	async getManyReference(target: string, id: string, query): Promise<{data: any[]; total: number}> {
		try {
			const response = await this.conn.get(this.api.buildBaseUrl(), {
				params: {...query, ancestor: id, parentOnly: true},
			})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getByCompanyIdWithQuery(
		companyId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		try {
			const response = await this.conn.get(this.api.buildCompanyUrl(companyId), {params: {filter, sort, skip, limit}})
			return {
				data: response.data.result,
				total: response.data.count,
			}
		} catch (e: any) {
			throwError(`Error while calling ${this.name}.getList: ${e.message}`)
		}
	}

	async getByProjectIdWithQuery(
		projectId: string,
		filter: string | null,
		sort: string | null,
		skip: number,
		limit: any,
	) {
		return Promise.resolve(undefined)
	}
}

export const projectRepository = new ProjectRepository()
