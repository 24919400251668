import {BaseController} from './base.controller'
import {AuthService, authService} from '../services/auth.service'
import {QueryParams} from '../utils/base.query-params'

class AuthQueryParams extends QueryParams {
	filter(filter: any) {
		let {q, ...restFilter} = filter
		const filterQuery: string[] = []
		if (q) filterQuery.push(`email=~"${q}"=si,memberName=~"${q}"=si`)
		const restFilterQuery = super.filter(restFilter)
		if (restFilterQuery) filterQuery.push(...restFilterQuery)
		return filterQuery.length ? `(${filterQuery.join(',')})` : null
	}
}

class AuthController extends BaseController<AuthService> {
	service = authService
	query = new AuthQueryParams()
}

export const authController = new AuthController()
