import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import {required, SelectInput, TextField} from 'react-admin'
import React, {useEffect} from 'react'
import _ from 'lodash'

export const AnalysisViewAnalysisAndVersionSelector = ({
	analysisView,
	onVersionSelected,
	keyPrefix,
	versionMap,
	onVersionInitialValues,
}: {
	keyPrefix: string
	analysisView
	onVersionSelected: (analysisId: string, version: string) => void
	versionMap?: {[k in string]: string}
	onVersionInitialValues: (versionMap: {[k in string]: string}) => void
}) => {
	useEffect(() => {
		onVersionInitialValues(
			analysisView?.analyses?.reduce((map, analysis) => {
				const version = _.last(
					_.sortBy(
						analysis?.versions?.filter(v => v.status === 'stable'),
						'versionNumber',
					),
				)?._id
				return version ? {...map, [analysis._id]: version} : map
			}, {}),
		)
	}, [analysisView])
	return !analysisView?.analyses?.filter(a => a).length ? null : (
		<div style={{display: 'block', width: '100%'}}>
			<Table width={'100%'} size={'small'}>
				<TableHead>
					<TableCell>Sub analysis</TableCell>
					<TableCell>Version</TableCell>
				</TableHead>
				<TableBody>
					{analysisView.analyses?.map((analysis, i) => (
						<TableRow>
							<TableCell>
								<TextField record={analysis} source={`name`} label="Sub Analysis" sortable={false} />
							</TableCell>
							<TableCell>
								<SelectInput
									label={'Version'}
									choices={analysis.versions.map(v => ({
										id: v._id,
										name: `${v.versionNumber} (${v.status})`,
									}))}
									source={`${keyPrefix}_analysis_${analysis.id}_version_${i}`}
									validate={[required()]}
									defaultValue={versionMap?.[analysis._id]}
									onChange={ev => onVersionSelected(analysis._id, ev.target.value)}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	)
}
