import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import React from 'react'

export function CreateReclassificationsForTrainingButton(props: {projectId: string}) {
	return (
		<Button
			component={Link}
			to={`/reclassifications/create?context=createForTraining&&projectId=${props.projectId}`}
			label="New Reclassification Training"
			title="New Reclassification Training"
		/>
	)
}
