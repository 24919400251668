import {Cognito as auth} from '../../auth_provider'

export function handleCompleteNewPasswordFlow([authState, setAuthState]: any) {
	if (!authState.cognitoUser) return void 0
	const {cognitoUser: user} = authState
	let requiredAttributes: any[] = []
	//@ts-ignore
	if (user.challengeParams) {
		//@ts-ignore
		requiredAttributes = user.challengeParams.requiredAttributes
	}

	return async function ({password, repeat_password}: {password: string; repeat_password: string}) {
		const response = await auth.completeNewPassword({
			user,
			password,
			requiredAttributes,
		})
		console.log('Complete PWD Change Response:', response)
		return response
	}
}
