import {Datagrid, DeleteButton, Filter, SearchInput, SimpleShowLayout, Tab, TextField} from 'react-admin'
import {DownloadFileButton} from '../buttons/DownloadFileButton'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import React from 'react'
import AddProjectMasvButton from '../projects/AddProjectMasvButton'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import AddProjectFileButton from '../projects/AddProjectFileButton'

export const FilesProjectTab = props => (
	<Tab {...props}>
		<span>
			<AddProjectFileButton {...props} />
			&nbsp;
			<AddProjectMasvButton {...props} />
		</span>
		<SRReferenceManyField
			source="_id"
			reference="project-files"
			target="projects"
			sort={{field: 'filename', order: 'ASC'}}
			addLabel={false}
			fullWidth
		>
			<SimpleShowLayout>
				<Filter>
					<SearchInput source="q" alwaysOn />
				</Filter>
				<Datagrid>
					<TextField source="filename" />
					<TextField source="status" />
					<TextField source="contentType" />
					<TextField source="size" />
					<TextField source="createdDate" />
					<ProjectShowButton projectId={props.id} />
					<DownloadFileButton projectId={props.id} />
					<DeleteButton redirect={false} undoable={false} />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
