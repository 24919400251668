import React, {useEffect, useState} from 'react'
import {projectController} from '../../data_provider/v3/controllers/project.controller'
import {useNotify} from 'react-admin'

export const ShowIfProjectPermission = props => {
	const {children, projectId, permission} = props
	const [show, setShow] = useState<boolean>(false)
	const notify = useNotify()
	useEffect(() => {
		if (projectId && permission) {
			projectController
				.hasPermission(projectId, permission)
				.then(setShow)
				.catch(e => notify(e.message))
		}
	}, [projectId, permission])
	return show && children
}
