import React from 'react'
import {Link} from 'react-router-dom'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {Button, useMutation, useRedirect} from 'react-admin'

const styles = {
	button: {
		marginTop: '1em',
	},
}

const AddNewAnalysisVersionButton = ({classes, ...props}) => {
	const redirect = useRedirect()
	const payload = {
		projectId: props.projectId,
		data: {
			type: 'createNewAnalysisVersion',
			id: props.record?.id,
		},
	}
	const [action, {loading}] = useMutation(
		{
			type: 'create',
			resource: 'reclassifications',
			payload,
		},
		{
			onSuccess: () => {
				redirect(`/projects/${props.projectId}/show/analyses`)
			},
		},
	)

	return (
		<Button
			disabled={props.record?.status !== 'finished' || loading || !!props.record?.versionCreated}
			className={classes.button}
			variant="outlined"
			component={Link}
			onClick={action}
			label="Create new analysis version"
			title="Create new analysis version"
		>
			<AddCircleOutlineIcon />
		</Button>
	)
}

export default withStyles(styles)(AddNewAnalysisVersionButton)
