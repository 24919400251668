import {BaseController} from './base.controller'
import {projectService, ProjectService} from '../services/project.service'
import {ByFreeTextKeyQueryParams} from '../utils/base.query-params'

class ProjectController extends BaseController<ProjectService> {
	service = projectService
	query = new ByFreeTextKeyQueryParams()

	async create(params: any) {
		const {data} = params
		return await this.service.create(data)
	}

	async update(params: any) {
		const {id, data} = params
		return await this.service.update(id, {
			...data,
			viewerFeatureFlags: Object.fromEntries(
				Object.entries(data.viewerFeatureFlags).filter(([key, value]) => value !== null),
			),
		})
	}

	async getList(params: any): Promise<{data: any[]; total: number}> {
		if (params.filter.company) {
			const {company, ...newFilter} = params.filter
			const query = this.query.build({...params, filter: newFilter})
			return this.service.getManyReference('companies', params.filter.company, query)
		}

		return super.getList(params)
	}

	async hasPermission(id: string, permission: string): Promise<boolean> {
		return this.service.hasPermission(id, permission)
	}

	async hardDelete(id: string): Promise<Response> {
		return this.service.hardDelete(id)
	}
}

export const projectController = new ProjectController()
