import {encodeId} from './base.service'
import {analysisViewRepository, AnalysisViewRepository} from '../repositories/analysis-view.repository'
import {BaseServiceByProject} from './base.service.by-project'

// this is necessary because Analysis has a field id but encodeId sets id = _id
// because react-admin relies on data entities to have the id field as identifier
// so we set Analysis.id to Analysis.analysisId so we can use it in the views
function encodeWithAnalysisId(e: any) {
	return {...encodeId(e), analysisId: e.id}
}

export class AnalysisViewService extends BaseServiceByProject<AnalysisViewRepository> {
	repository = analysisViewRepository

	async createFakeAnalysis(dto: any) {
		return this.encodeResult(await this.repository.createFakeAnalysis(dto))
	}

	encodeResult({data, ...rest}: any): any {
		return {data: encodeWithAnalysisId(data), ...rest}
	}

	encodeResults({data, ...rest}: any): any {
		return {data: data.map(e => encodeWithAnalysisId(e)), ...rest}
	}

	// this is necessary because Analysis has a field id but encodeId sets id = _id
	// because react-admin relies on data entities to have the id field as identifier
	// so we remove Analysis.analysisId before we send it in create / update calls
	encodeOutput(data) {
		const {analysisId, ...rest} = data
		return rest
	}

	transferLabels = async (
		projectId: string,
		analysisViewId: string,
		sourceProjectId: string,
		sourceAnalysisViewId: string,
		sourceVersionMap: any,
		targetVersionMap: any,
	) => {
		return this.repository.transferLabels(
			projectId,
			analysisViewId,
			sourceProjectId,
			sourceAnalysisViewId,
			sourceVersionMap,
			targetVersionMap,
		)
	}

	importAnalysis = async (
		projectId: string,
		sourceProjectId: string,
		sourceAnalysisViewId: string,
		sourceVersionMap: any,
		targetModel: string,
		elementIdsMappingFileId?: string,
	) => {
		return this.repository.importAnalysis(
			projectId,
			sourceProjectId,
			sourceAnalysisViewId,
			sourceVersionMap,
			targetModel,
			elementIdsMappingFileId,
		)
	}
}

export const analysisViewService = new AnalysisViewService()
