export const INPUT_CHECK_META = {
	geometry_consistent_normals_check: {
		title: 'Geometry consistent normals check.',
		description: `Geometry based check.
        Checks whether model geometries contain inconsistent normals: for all mesh adjacent faces, normals have to be equally oriented. Failing that causes pipeline algorithmic assumptions to fail: incorrect downsampling, incorrect occlusion detection, incorrect deviation magnitudes.
        A failing check requires model to be reviewed and fixed by a BIM manager.`,
	},
	floating_point_model_check: {
		title: 'Floating point model check.',
		description: `Geometry based check.
		Checks whether a model is in such location that yields large numerical coordinates, potentially causing numerical overflows. 
		A failing check requires demeaning of the model by a BIM manager.`,
	},
	duplicate_model_guids_check: {
		title: 'Duplicate model GUIDS check.',
		description: `Metadata based check.
		Checks whether a model metadata contains more than one element geometry per Global ID. Failing this assumption can cause issues like not being able to correctly find a unique element via filters, or clicking on an element in the viewer and not retrieving the right assets on top of the right geometry (partial heatmaps, heatmaps overlapped on a different surface other than the selected one)
		A failing check requires manipulation of the model by a BIM manager, to unique-ify the global IDs. The output of the check is a JSON file containing the duplicate  guids (if any)`,
	},
	invalid_geometries_check: {
		title: 'Invalid geometries check.',
		description: `Geometry based check.
		Identifies all elements in model that have invalid geometries (less than 3 vertices, 0 faces, etc). Typically these elements are modelling artifacts, and do not represent actual objects.
		The check is only a warning: if the model is accepted after review by AEC, invalid elements will be ignored in analyses.`,
	},
	forge_loading_time_check: {
		title: 'Forge viewer loading check.',
		description: `Checks how long the Forge viewer takes to load the model, on a machine with limited resources. 
		The check is only a warning, but indicates that this model may cause issues for clients or reclassification.`,
	},
	model_categories_check: {
		title: 'Element categories check.',
		description: `Generates .xls report with element counts for all categories in model.`,
	},
	e57_scan_names_check: {
		title: 'e57 scan names check.',
		description: `Checks the validity scan names in the survey E57s. A warning will be issued if the scan names are empty or duplicated within the same E57 or between 2 separate E57s. 
		A failing check does not prevent the pipeline from running, since scan names are not used directly in the pipeline (we generate names ourselves based on the scan indices).`,
	},
	e57_scan_size_check: {
		title: 'e57 scan size check.',
		description: `Checks for scans in E57s which are outliers in terms of number of points, as they may indicate the presence of preview or ill-formed scans. 
		This check is informational only, and a failing check does not prevent the pipeline from running.`,
	},
	e57_nan_points_check: {
		title: 'e57 nan points check.',
		description: `Checks for scans in E57s that contain non-finite coordinates. 
		This check is informational only and does not prevent the pipeline from running.`,
	},
	e57_images_check: {
		title: 'e57 images check.',
		description: `Checks for scans in E57s that either do not contain any images, or have an invalid number of images. 
		A failing check does not prevent the pipeline from running, but images will be missing or incomplete. Typically, we activate the synthetic images when this fails.`,
	},
	e57_images_source_check: {
		title: 'e57 images source check.',
		description: `Checks that the input data is consistent with the selected image source.
		- If the selected image source is “e57”, the survey must contain valid images
		- If the selected image source is “files”, image files must be available 
		A failing check means that the selected image source is invalid for the survey provided.`,
	},
	e57_scan_poses_check: {
		title: 'e57 scan poses check.',
		description: `Checks E57 scan poses for duplicities, existence of at least one of them, floating point errors and quaternion sanity.
		A failing check means that the survey data is invalid and must be updated.`,
	},
	e57_images_quality_check: {
		title: 'e57 images quality check.',
		description: `Manual check.
		Extract images from scans and returns them as assets, so that AEC can download them and assess images quality visually.`,
	},
	e57_scans_registration_check: {
		title: 'e57 scans registration check.',
		description: `Manual check.
		Generates registration sections assets, so that AEC can download them and visually check if registration is valid.`,
	},
	e57_scan_normals_check: {
		title: 'e57 scan normals check.',
		description: `Checks for scans in E57s that contain non-finite normals. 
		A failing check means that the scan data is invalid and must be updated. `,
	},
	e57_downsampled_scan_check: {
		title: 'e57 downsampled submaps check.',
		description: `Generates a downsampled version of the E57 scans for easy checking, and for manual alignment.`,
	},
} as const

export const getInputCheckTitle = (name: string) => INPUT_CHECK_META[name]?.title || name
export const getInputCheckDescription = (name: string) =>
	INPUT_CHECK_META[name]?.description || `Description for ${name} not found.`
