import React from 'react'

import {Create, FileInput, FileField, TabbedForm, FormTab, useRedirect} from 'react-admin'

import {useDispatch} from 'react-redux'
import {FILE_ADD, FILE_UPDATE} from '../../actions/file.actions'
import {projectFileRepository} from '../../data_provider/v3/repositories/files.repository'
import {config} from '../../config'
import {fileUploaderQueueService} from '../files/file-uploader.queue.service'
import {SRCreate} from '../SRComponents/SRCreate'

export function ProjectFileCreate(props: any) {
	const dispatch = useDispatch()
	const redirect = useRedirect()
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const transform = data => ({
		...data,
		projectId,
	})

	const onSuccess = files => {
		for (const {entity, rawFile} of files.data.results) {
			const payload = {...entity, status: 'uploading', progress: 0}
			dispatch({type: FILE_ADD, payload})
			const $source = fileUploaderQueueService
				.addFileToUploadQueue({
					fileId: entity._id,
					numberOfParts: entity.numberOfParts,
					nextUrl:
						config.sr.backendUrl +
						projectFileRepository.api.buildProjectUrl(projectId!, entity._id, 'next-pending-part'),
					file: rawFile,
				})
				.subscribe({
					next: (value: any) => {
						dispatch({type: FILE_UPDATE, payload: {...payload, progress: value}})
					},
					error: e => {
						dispatch({type: FILE_UPDATE, payload: {...payload, status: 'error'}})
						console.error(`Error uploading file ${JSON.stringify(entity)}`, e)
						$source.unsubscribe()
					},
					complete: () => {
						dispatch({type: FILE_UPDATE, payload: {...payload, status: 'uploaded'}})
						$source.unsubscribe()
					},
				})
		}
		redirect(`/projects/${projectId}/show/files`)
	}
	return (
		<SRCreate
			{...props}
			projectId={projectId}
			listTabName="files"
			showBreadcrumb
			transform={transform}
			onSuccess={onSuccess}
		>
			<TabbedForm>
				<FormTab label="main">
					<FileInput source="files" multiple={true} label="Project files">
						<FileField source="src" title="title" />
					</FileInput>
				</FormTab>
			</TabbedForm>
		</SRCreate>
	)
}
