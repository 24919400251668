import React from 'react'
import {
	AutocompleteInput,
	Edit,
	FormTab,
	maxLength,
	minLength,
	NullableBooleanInput,
	NumberInput,
	ReferenceField,
	ReferenceInput,
	required,
	SelectInput,
	TabbedForm,
	TextField,
	TextInput,
	useGetOne,
	useQuery,
} from 'react-admin'

import JEditor from './JEditor'
import {modelTypeChoices} from './choices.projects'
import {srFeatures} from 'sr-feature-flags'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import {SREdit} from '../SRComponents/SREdit'

const validateName = [
	required('You must set a name for the project!'),
	minLength(1, 'Project name must be at least one character long'),
	maxLength(50, 'Project name must maximum 50 characters long'),
]

function parseJson(data: any): any {
	return typeof data === 'string' ? JSON.parse(data) : data
}

const validJSON = (value: string) => {
	try {
		parseJson(value)
	} catch (error) {
		return error.message
	}
}

const transform = data => {
	return {
		...data,
		viewerState: parseJson(data.viewerState),
		floorMapping: parseJson(data.floorMapping),
		elementTypeMapping: parseJson(data.elementTypeMapping),
		elementsToHide: parseJson(data.elementsToHide),
		elementViewerTypesToHide: parseJson(data.elementViewerTypesToHide),
	}
}

const customStyles = makeStyles(() => ({
	input: {
		width: '400px',
	},
}))

export function ProjectEdit(props: any) {
	const {data, loading} = useGetOne('projects', props.id)
	const featureFlagDefaultValuesQuery = useQuery({
		resource: 'features',
		type: 'custom',
		payload: {actionName: 'getGlobal'},
	})
	const classes = customStyles()
	if (loading || featureFlagDefaultValuesQuery.loading) return null
	return (
		<SREdit
			{...props}
			projectId={props.id}
			showBreadcrumb
			mutationMode="pessimistic"
			undoable={false}
			transform={transform}
		>
			<TabbedForm>
				<FormTab label="main">
					<ReferenceField source="company" reference="companies">
						<TextField source="name" />
					</ReferenceField>
					<ReferenceInput
						name="parent"
						filter={{ancestor: data?.company}}
						source="workspace"
						reference="workspaces"
						label="Workspace"
						allowEmpty
					>
						<AutocompleteInput optionText="name" multiline />
					</ReferenceInput>
					<TextField label="Tenant ID" source="tenantId" />

					<TextInput source="name" validate={validateName} multiline />
					<SelectInput alwaysOn label="Model Type" source="modelType" choices={modelTypeChoices} />
				</FormTab>

				<FormTab label="forge">
					<TextInput source="forgeCredentials.client_id" label="Client ID" multiline />
					<TextInput source="forgeCredentials.client_secret" label="Client Secret" multiline />
				</FormTab>

				<FormTab label="viewer">
					<JEditor
						rows={10}
						validate={[validJSON]}
						fullWidth={true}
						source="viewerState"
						name="Viewer State"
						label="Viewer State"
					/>

					<JEditor
						rows={10}
						validate={[validJSON]}
						source="floorMapping"
						fullWidth={true}
						name="Floor Mapping"
						label="Floor Mapping"
					/>

					<JEditor
						rows={10}
						validate={[validJSON]}
						source="elementTypeMapping"
						fullWidth={true}
						name="Element Type Mapping"
						label="Element Type Mapping"
					/>

					<JEditor
						rows={10}
						validate={[validJSON]}
						source="elementsToHide"
						fullWidth={true}
						name="Elements to hide"
						label="Elements to hide"
					/>

					<JEditor
						validate={[validJSON]}
						rows={10}
						fullWidth={true}
						source="elementViewerTypesToHide"
						name="Element Viewer Types to hide"
						label="Element Viewer Types to hide"
					/>
				</FormTab>

				<FormTab label="viewer features">
					<Typography variant="h6" component="div" gutterBottom>
						Feature Flags
					</Typography>
					{srFeatures.featureFlags.map(flag => {
						const featureFlagDefault = featureFlagDefaultValuesQuery.data?.find(f => f.id === flag.id)
						return (
							<NullableBooleanInput
								className={classes.input}
								sx={{width: 400}}
								label={flag.id}
								source={`viewerFeatureFlags.${flag.id}`}
								helperText={`${flag.description} (global: ${featureFlagDefault?.value}, source: ${featureFlagDefault?.layer})`}
								nullLabel="Inherit"
							/>
						)
					})}
					<Typography variant="h6" component="div" gutterBottom>
						Config
					</Typography>
					<SelectInput
						alwaysOn
						label="CSV Export Format"
						source="viewerFeatureFlags.csvExportFormat"
						choices={[
							{id: 'standard', name: 'standard'},
							{id: 'extended', name: 'extended'},
						]}
					/>
					<NumberInput
						label="Force near radius"
						source={'viewerFeatureFlags.forceNearRadius'}
						helperText={
							"If the viewer has clipping issues in the reclassification viewer, this can be used to set the 'nearRadius' which is passed to the viewer loading options for very large objects. To fix the clipping issues, there are two alternatives: Setting it to 0 will force the viewer to use the near / far plane calculations used for normal sized models, while setting it to a value above 0 will most likely set the near and far values to fix values where far = near*1e4. Which alternative is more reliable usually depends on the model."
						}
					/>
					<NumberInput
						label="Element type tree max depth"
						source={'viewerFeatureFlags.maxElementTypeTreeDepth'}
						helperText={
							"Use this setting to declare how many depth levels the element type tree should have. Leve it empty if you don't want to restrict it and use the full tree instead. Specially useful for huge models where performance gets really degraded rendering the element tree selector in the viewer."
						}
					/>
				</FormTab>
			</TabbedForm>
		</SREdit>
	)
}
