import React from 'react'
import {DeleteButton, Filter, SearchInput, ReferenceField} from 'react-admin'
import {TabbedShowLayout, Tab, TextField, SimpleShowLayout, Datagrid, ShowButton, EditButton} from 'react-admin'
import AddProjectToCompanyFileButton from './AddProjectToCompanyFileButton'
import AddTeamToCompanyButton from './AddTeamToCompanyButton'
import {TeamShowButton} from '../buttons/TeamShowButton'
import {TeamEditButton} from '../buttons/TeamEditButton'
import {WorkspaceShowButton} from '../buttons/WorkspaceShowButton'
import {WorkspaceEditButton} from '../buttons/WorkspaceEditButton'
import AddWorkspaceToCompanyButton from '../buttons/AddWorkspaceToCompanyButton'
import AddAuthButton from '../buttons/AddAuthButton'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {SRShow} from '../SRComponents/SRShow'

export function CompanyShow(props: any) {
	const redirectDeleteTeam = () => {
		return props.history.goBack()
	}
	return (
		<SRShow {...props}>
			<TabbedShowLayout>
				<Tab label="main">
					<TextField source="name" />
					<TextField source="description" />
					<TextField source="ossBucketKey" label="Autodesk OSS Bucket" />
					<ReferenceField source="owner" reference="users" link="show">
						<TextField source="email" />
					</ReferenceField>
				</Tab>
				<Tab label="projects">
					<AddProjectToCompanyFileButton {...props} />
					<SRReferenceManyField
						source={'_id'}
						reference="projects"
						target={'companies'}
						sort={{field: 'name', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="name" />
								<ShowButton />
								<EditButton />
								<DeleteButton undoable={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
				<Tab label="teams">
					<AddTeamToCompanyButton {...props} />
					<SRReferenceManyField
						source={'_id'}
						reference="teams"
						target={'companies'}
						sort={{field: 'name', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="name" />
								<TeamShowButton companyId={props.id} />
								<TeamEditButton companyId={props.id} />
								<DeleteButton undoable={false} redirect={redirectDeleteTeam} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
				<Tab label="workspaces">
					<AddWorkspaceToCompanyButton {...props} />
					<SRReferenceManyField
						source={'_id'}
						reference="workspaces"
						target={'companies'}
						sort={{field: 'name', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="name" />
								<WorkspaceShowButton parentId={props.id} />
								<WorkspaceEditButton parentId={props.id} />
								<DeleteButton undoable={false} redirect={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
				<Tab label="security">
					<AddAuthButton {...props} entityType={'company'} />
					<SRReferenceManyField
						source="_id"
						reference="auths"
						target="entity"
						sort={{field: 'memberName', order: 'ASC'}}
						addLabel={false}
						fullWidth
					>
						<SimpleShowLayout>
							<Filter>
								<SearchInput source="q" alwaysOn />
							</Filter>
							<Datagrid>
								<TextField source="memberName" />
								<TextField source="email" />
								<TextField source="memberType" label={'Member type'} />
								<TextField source="role" />
								<EditButton />
								<DeleteButton redirect={false} undoable={false} />
							</Datagrid>
						</SimpleShowLayout>
					</SRReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</SRShow>
	)
}
