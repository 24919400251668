import React from 'react'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import {modelService} from '../../data_provider/v3/services/model.service'
import {scansService} from '../../data_provider/v3/services/scans.service'

export const DownloadAssetsButton = props => {
	const type = props.data.basePath
	const service = type === '/models' ? modelService : scansService
	return (
		<Button
			color="primary"
			aria-label="Download File"
			onClick={async () => {
				const response = await service.getInputCheckAssetSignedUrls(
					props.data.projectId,
					props.data.data.id,
					props.data.record.name,
				)
				response.forEach(signedUrlDTO => {
					window.open(signedUrlDTO.signedUrl)
				})
			}}
			title="Download File"
			startIcon={<GetAppIcon />}
		>
			Download
		</Button>
	)
}
