import {
	AutocompleteInput,
	Edit,
	NumberInput,
	ReferenceInput,
	required,
	ShowButton,
	SimpleForm,
	TextField,
	TextInput,
	TopToolbar,
} from 'react-admin'
import React from 'react'
import {Typography} from '@material-ui/core'
import {SREdit} from '../SRComponents/SREdit'

function ModelSetupJobEditActions(props) {
	return !props.data ? null : (
		<TopToolbar key={props.data._id}>
			<ShowButton to={`${props.basePath}/${props.data._id}/show/?projectId=${props.projectId}`} />
		</TopToolbar>
	)
}

export function ModelSetupJobEdit(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => `/projects/${projectId}/show/models`
	const transform = data => {
		const {modelName, coordinatesOffset} = data
		return {
			type: 'update',
			projectId,
			coordinatesOffset,
			modelName,
		}
	}
	const initialValues = {
		coordinatesOffset: {x: 0, y: 0, z: 0},
	}
	return (
		<SREdit
			undoable={false}
			{...props}
			entityNameProp="modelName"
			projectId={projectId}
			fileId={props.id}
			listTabName="models"
			showBreadcrumb
			transform={transform}
			actions={<ModelSetupJobEditActions projectId={projectId} />}
		>
			<SimpleForm redirect={redirect} initialValues={initialValues}>
				<TextInput source="modelName" label="Model Name" validate={[required()]} />
				<TextField source="metadataProjectFileS3Key" label={'Current Metadata Path'} />
				<Typography variant="subtitle1" component="div" gutterBottom>
					Translation coordinates (in mm)
				</Typography>
				<NumberInput source="coordinatesOffset.x" label="X" validate={[required()]} />
				<NumberInput source="coordinatesOffset.y" label="Y" validate={[required()]} />
				<NumberInput source="coordinatesOffset.z" label="Z" validate={[required()]} />
			</SimpleForm>
		</SREdit>
	)
}
