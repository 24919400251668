import React from 'react'
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	Create,
	FormDataConsumer,
	maxValue,
	minValue,
	NumberInput,
	ReferenceArrayInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
} from 'react-admin'
import {getFullName} from '../../utils/getters.utils'
import {Divider} from '@material-ui/core'
import {SRCreate} from '../SRComponents/SRCreate'

export function CreateReclassificationsForTraining(props: any) {
	const searchParams = new URLSearchParams(props.location.search)
	const projectId = searchParams.get('projectId')
	const redirect = () => `/projects/${projectId}/show/reclassifications`
	return (
		<SRCreate
			{...props}
			projectId={projectId}
			showBreadcrumb
			title={'Start reclassification training'}
			transform={data => ({projectId, type: 'training', ...data})}
		>
			<SimpleForm redirect={redirect}>
				<ReferenceInput
					label="Reclassification Manager"
					name="manager"
					source="manager"
					reference="users"
					sort={{field: 'email', order: 'ASC'}}
				>
					<AutocompleteInput
						helperText={'Can be left empty, it will default to the current user then.'}
						optionText={getFullName}
					/>
				</ReferenceInput>
				<ReferenceInput
					label="Template"
					name="template"
					source="template"
					reference="reclassification-stages-templates"
					sort={{field: 'name', order: 'ASC'}}
				>
					<AutocompleteInput
						optionText={record => `${record.name}`}
						helperText={'Can be left empty, it will default to Reclassification only template then.'}
					/>
				</ReferenceInput>
				<ReferenceInput
					label="Analysis"
					name="analysisId"
					source="analysisId"
					reference="analysis-view"
					validate={[required()]}
				>
					<AutocompleteInput optionText="name" />
				</ReferenceInput>
				<FormDataConsumer>
					{({formData}) =>
						formData.analysisId ? (
							<ReferenceInput
								label="Version"
								name="version"
								source="version"
								reference="versions"
								validate={[required()]}
								filter={{analysisView: formData.analysisId, status: ['dev', 'stable']}}
							>
								<SelectInput optionText="versionNumber" />
							</ReferenceInput>
						) : null
					}
				</FormDataConsumer>
				<ReferenceInput
					label={'Reclassification Company'}
					reference={'companies'}
					source={'company'}
					validate={[required()]}
				>
					<AutocompleteInput
						optionText={'name'}
						helperText={'All users or all members in the team need to be in this company.'}
					/>
				</ReferenceInput>
				<ReferenceArrayInput label="Users" reference={'users'} source={'users'}>
					<AutocompleteArrayInput
						helperText={
							'You can either select multiple users or a team for reclassification training. Selecting both will create only reclassifications for the users and ignore the team.'
						}
						optionText={getFullName}
					/>
				</ReferenceArrayInput>
				<Divider />
				<ReferenceInput reference={'teams'} source={'team'}>
					<AutocompleteInput
						helperText={
							'You can either select multiple users or a team for reclassification training. Selecting both will create only reclassifications for the users and ignore the team.'
						}
						optionText={'name'}
					/>
				</ReferenceInput>
				<NumberInput
					label={'Confidence Threshold (in %)'}
					source={'confidenceThreshold'}
					min={1}
					max={100}
					helperText={
						'If set, the work packages will only contain classifications with confidence below the threshold. Enter a percent value between 1 and 100.'
					}
					validate={[minValue(1), maxValue(100)]}
				/>
			</SimpleForm>
		</SRCreate>
	)
}
