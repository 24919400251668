import React from 'react'
import {CreateFakeAnalysis} from './CreateFakeAnalysis'

export function CreateAnalysis(props) {
	const searchParams = new URLSearchParams(props.location.search)
	const context = searchParams.get('context')
	const modelId = searchParams.get('modelId')
	const tenantId = searchParams.get('tenantId')
	const projectId = searchParams.get('projectId')
	if (context === 'createFakeAnalysis') {
		return <CreateFakeAnalysis {...props} modelId={modelId} tenantId={tenantId} projectId={projectId} />
	} else {
		throw new Error('Default analysis creation route has not been implemented yet.')
	}
}
