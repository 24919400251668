import {Edit, required, ShowButton, TextInput, TopToolbar} from 'react-admin'
import React from 'react'
import _ from 'lodash'
import {SRSimpleForm} from '../ra-extensions/forms/SRSimpleForm'
import {SREdit} from '../SRComponents/SREdit'

function ROIEditActions(props) {
	return !props.data ? null : (
		<TopToolbar key={props.data._id}>
			<ShowButton to={`${props.basePath}/${props.data._id}/show?projectId=${props.projectId}`} />
		</TopToolbar>
	)
}

export function ROIEdit(props: any) {
	const projectId = new URLSearchParams(props.location.search).get('projectId')
	const redirect = () => `/projects/${projectId}/show/rois`
	const transform = data => _.pick(data, 'name')
	return (
		<SREdit
			entityNameProp="name"
			projectId={projectId}
			fileId={props.id}
			showBreadcrumb
			undoable={false}
			{...props}
			transform={transform}
			actions={<ROIEditActions projectId={projectId} />}
		>
			<SRSimpleForm redirect={redirect}>
				<TextInput source="name" validate={[required()]} />
			</SRSimpleForm>
		</SREdit>
	)
}
