import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import {getInputCheckDescription, getInputCheckTitle} from './InputChecksMeta'
import {Typography} from '@material-ui/core'

export const customStyles = makeStyles(
	() => ({
		title: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			fontWeight: 'bold',
		},
		modalContent: {
			fontSize: '16px',
			lineHeight: '1.5',
			letterSpacing: '0.1px',
			paddingBottom: '16px',
		},
		modalContentTitle: {
			fontWeight: 'bold',
			paddingBottom: '8px',
		},
	}),
	{name: 'CheckInfoModal'},
)

const CheckInfoModal = ({showDialog, onClose, inputCheck}) => {
	const classes = customStyles()
	return (
		<Dialog fullWidth open={showDialog} onClose={onClose}>
			{inputCheck && (
				<>
					<DialogTitle>
						<div className={classes.title}>
							Check Information
							<IconButton onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</div>
					</DialogTitle>
					<DialogContent className={classes.modalContent}>
						<Typography className={classes.modalContentTitle} variant="subtitle2">
							{getInputCheckTitle(inputCheck.record.name)}
						</Typography>
						<Typography variant="body2">{getInputCheckDescription(inputCheck.record.name)}</Typography>
					</DialogContent>
				</>
			)}
		</Dialog>
	)
}
export default CheckInfoModal
