import React from 'react'
import {Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput} from 'react-admin'
import {validateCompanyCreate} from './CompanyFormValidation'

import {required, maxLength} from 'react-admin'

const validateName = [required('You must input a name for the Company')]
const validateDescription = [maxLength(500, 'Description must be less than 500 characters')]

export function CompanyCreate(props: any) {
	return (
		<Create {...props} validate={[validateCompanyCreate]}>
			<SimpleForm redirect="list">
				<TextInput source="name" validate={validateName} />
				<TextInput source="description" validate={validateDescription} />
				<ReferenceInput name="owner" source="owner" reference="users" label="Owner">
					<AutocompleteInput optionText="email" multiline />
				</ReferenceInput>
			</SimpleForm>
		</Create>
	)
}
