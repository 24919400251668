import {BaseRepositoryByProject} from './base.repositories.by-project'
import {ResourceName} from '../../../config/resources.config'
import {BaseRepository} from './base.repository'

export class ProjectFileRepository extends BaseRepositoryByProject {
	name: ResourceName = 'project-files'

	async signedUploadPart(projectId: string, id: string) {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, id, 'signed-upload-part'))
		return response.data
	}

	async completePart(projectId: string, id: string, dto: {partNumber: number; etag: string}) {
		const response = await this.conn.post(this.api.buildProjectUrl(projectId, id, 'uploaded'), dto)
		return response.data
	}

	async getSignedUrl(projectId: string, id: string) {
		const response = await this.conn.get(this.api.buildProjectUrl(projectId, id, 'signed'))
		return response.data
	}
}

export class RootFileRepository extends BaseRepository {
	name: ResourceName = 'files'

	async signedUploadPart(id: string) {
		const response = await this.conn.post(this.api.buildBaseUrl(id, 'signed-upload-part'))
		return response.data
	}

	async completePart(id: string, dto: {partNumber: number; etag: string}) {
		const response = await this.conn.post(this.api.buildBaseUrl(id, 'uploaded'), dto)
		return response.data
	}

	async getSignedUrl(id: string) {
		const response = await this.conn.get(this.api.buildBaseUrl(id, 'signed'))
		return response.data
	}
}

export const rootFileRepository = new RootFileRepository()
export const projectFileRepository = new ProjectFileRepository()
