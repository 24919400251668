import React from 'react'
import {Button} from 'react-admin'
import {getForgeAccessToken} from '../../../config/conn.config'

export function toUrlSafeUrn(urn: string) {
	return Buffer.from(urn).toString('base64').replace(/=/g, '')
}
export const OpenStandAloneForgeViewer = props => {
	const onClick = async () => {
		const {access_token} = await getForgeAccessToken()
		const safeURN = toUrlSafeUrn(props.record.modelUrn)
		const url = `/view-forge-stand-alone?accessToken=${access_token}&urn=${encodeURIComponent(safeURN)}`
		window.open(url, '_blank')
	}

	return (
		<Button
			style={{backgroundColor: '#FCD116', margin: '10px 0', paddingLeft: '0'}}
			onClick={onClick}
			label="test model"
		></Button>
	)
}
