import {Datagrid, DateField, FunctionField, ReferenceField, SimpleShowLayout, Tab, TextField} from 'react-admin'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import React from 'react'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {Divider, Typography} from '@material-ui/core'
import AddScansButton from '../projects/AddScansButton'
import {StatusChipInputCheckInfo} from '../input-check/StatusChipInputCheckInfo'
import {getFullName} from '../../utils/getters.utils'
import {ProjectEditButton} from '../buttons/ProjectEditButton'
import {DeleteScansButton} from './DeleteScansButton'

export const ScansProjectTab = props => (
	<Tab {...props}>
		<Typography variant="h6" component="div" gutterBottom>
			Scans
		</Typography>
		<Divider />
		<AddScansButton {...props} />
		<SRReferenceManyField
			source="_id"
			reference="scans"
			target="projects"
			sort={{field: 'name', order: 'ASC'}}
			addLabel={false}
			fullWidth
		>
			<SimpleShowLayout>
				<Datagrid>
					<StatusChipInputCheckInfo label={'Status'} />
					<TextField source="name" />
					<DateField source="scanDate" showTime />
					<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
						<FunctionField render={getFullName} />
					</ReferenceField>
					<TextField source="config.surveyMode" label="Survey mode" />
					<ProjectShowButton projectId={props.id} />
					<ProjectEditButton projectId={props.id} />
					<DeleteScansButton redirect={false} undoable={false} />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
