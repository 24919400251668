import {BaseService} from './base.service'
import {MasvRepository, masvRepository} from '../repositories/masvs.repository'

export class MasvService extends BaseService<MasvRepository> {
	repository = masvRepository

	async copyToProject(project: string, files: string[]) {
		return this.repository.copyToProject(project, files)
	}

	async statusInProject(project: string, files: string[]) {
		return this.repository.statusInProject(project, files)
	}
}

export const masvService = new MasvService()
