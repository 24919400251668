import React, {useState} from 'react'
import {
	Create,
	SimpleForm,
	SelectInput,
	ReferenceInput,
	AutocompleteInput,
	ReferenceField,
	TextField,
} from 'react-admin'
import {useGetRoles} from './roles'

export function AuthCreate(props: any) {
	const params = new URLSearchParams(props.location.search)
	const entityId = params.get('entityId')
	const entityType = params.get('entityType')

	const roles = useGetRoles()

	const redirect = (basePath, id, data) => {
		switch (entityType) {
			case 'company':
				return `/companies/${entityId}/show/scans`
			case 'project':
				return `/projects/${entityId}/show/security`
			case 'workspace':
				return `/workspaces/${entityId}/show/rois`
			default:
				return ''
		}
	}
	const transform = data => {
		return {
			role: data.role,
			entity: entityId,
			entityType,
			member: data.user || data.team,
			memberType: data.user ? 'user' : 'team',
		}
	}

	const [showUser, setShowUser] = useState(true)
	const [showTeam, setShowTeam] = useState(true)

	return (
		<Create {...props} undoable={false} transform={transform} initialValues={{entity: entityType}}>
			<SimpleForm redirect={redirect}>
				{entityType === 'company' && (
					<ReferenceField source="entity" reference="companies" label={'Company'}>
						<TextField source="name" />
					</ReferenceField>
				)}
				{entityType === 'project' && (
					<ReferenceField source="entity" reference="projects" label={'Project'}>
						<TextField source="name" />
					</ReferenceField>
				)}
				{entityType === 'workspace' && (
					<ReferenceField source="entity" reference="workspaces" label={'Workspace'}>
						<TextField source="name" />
					</ReferenceField>
				)}
				{showUser && (
					<ReferenceInput
						label="Select user"
						name="user"
						source="users"
						reference="users"
						sort={{field: 'email', order: 'ASC'}}
						onChange={() => setShowTeam(false)}
					>
						<AutocompleteInput optionText={record => `${record.email}`} multiline />
					</ReferenceInput>
				)}
				{showTeam && (
					<ReferenceInput
						label="or select team"
						name="team"
						source="team"
						reference="teams"
						sort={{field: 'name', order: 'ASC'}}
						onChange={() => setShowUser(false)}
					>
						<AutocompleteInput optionText={record => `${record.name}`} multiline />
					</ReferenceInput>
				)}
				<SelectInput source="role" choices={roles} initialValue={'REGISTERED_USER'} />
			</SimpleForm>
		</Create>
	)
}
