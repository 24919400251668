import {Datagrid, DeleteButton, FunctionField, ReferenceField, SimpleShowLayout, Tab, TextField} from 'react-admin'
import {ProjectShowButton} from '../buttons/ProjectShowButton'
import React from 'react'
import {SRReferenceManyField} from '../pagination/SRReferenceManyField'
import {Divider, Typography} from '@material-ui/core'
import {ProjectEditButton} from '../buttons/ProjectEditButton'
import AddROIButton from '../projects/AddROIButton'
import {getFullName} from '../../utils/getters.utils'

export const RoisProjectTab = props => (
	<Tab {...props}>
		<Typography variant="h6" component="div" gutterBottom>
			Regions of Interest
		</Typography>
		<Divider />
		<AddROIButton {...props} />
		<SRReferenceManyField
			source="_id"
			reference="rois"
			target="projects"
			sort={{field: 'name', order: 'ASC'}}
			addLabel={false}
			fullWidth
		>
			<SimpleShowLayout>
				<Datagrid>
					<TextField label="Name" source="name" />
					<ReferenceField label="Created by" source="createdBy" reference="users" link="show">
						<FunctionField render={getFullName} />
					</ReferenceField>
					<ProjectShowButton projectId={props.id} />
					<ProjectEditButton projectId={props.id} />
					<DeleteButton redirect={false} undoable={false} />
				</Datagrid>
			</SimpleShowLayout>
		</SRReferenceManyField>
	</Tab>
)
