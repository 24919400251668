import React from 'react'
import {Link} from 'react-router-dom'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {Button} from 'react-admin'

const styles = {
	button: {
		marginTop: '1em',
	},
}

const AddModelUploadJobFromModelButton = ({classes, record}) => (
	<Button
		className={classes.button}
		variant="outlined"
		component={Link}
		to={`/model-upload-jobs/create?tenantId=${record?.tenantId}&projectId=${record?.id}&context=fromModel`}
		label="Create from existing model"
		title="Create from existing model"
	>
		<AddCircleOutlineIcon />
	</Button>
)

export default withStyles(styles)(AddModelUploadJobFromModelButton)
